export default {
    confirmChangesBeforeNext(isDirty, next) {
      const me = this;
      if (!isDirty) {
        //View uses prop for IsDirty-Check
        next();
        return;
      }
  
      me.$confirm(me.$t("SaveChanges_Text"), {
        title: me.$t("SaveChanges_Title"),
        buttonTrueText: me.$t("Yes"),
        buttonFalseText: me.$t("No"),
      }).then((res) => {
        if (res === true) {
          me.save(next);
        } else {
          next();
        }
      });
    },
  };