import api from "@/api.js";

export default {
  async initialize() {
    const me = this;

    await me.loadUserSettings();
    await me.initDataGrid();

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.gridOptions.undoRedoCellEditing = true;
    }
    this.noRowsTemplate = this.$t("NoRowsFound");
    this.loadingTemplate = this.$t("ProcessingData");
    this.updateColumnsBySettings();
    this.refresh();
  },

  async refresh() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) return;
    //this.updateColumns();
    const cell = dataGrid.gridOptions.api.getFocusedCell();
    await me.loadData();
    dataGrid.gridOptions.api.setRowData(this.data);
    if (cell) {
      dataGrid.gridOptions.api.setFocusedCell(cell.rowIndex, cell.column);
    }
  },

  insertNewRow() {},
  delete() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      let cell = dataGrid.gridOptions.api.getFocusedCell();
      let rowData = this.data[cell.rowIndex];
      if (!cell || !rowData) {
        this.showAlert(this.$t("Msg_NoRowSelectedForDeletion"), 3000);
        return;
      }
      const nodes = dataGrid.gridOptions.api.getRenderedNodes();
      if (rowData.ProjectUID == null) {
        this.deleteRow(dataGrid, rowData, cell, nodes);
        return;
      }
      rowData.HasError = true;
      dataGrid.gridOptions.api.redrawRows();
      me.$confirm(me.$t("Msg_Confirm_DeleteRow_Text"), {
        title: this.$t("Msg_Confirm_DeleteRow_Title"),
        buttonTrueText: me.$t("Yes"),
        buttonFalseText: me.$t("No"),
      }).then((res) => {
        if (res == true) {
          this.deleteRow(dataGrid, rowData, cell, nodes);
          rowData.HasError = false;
          dataGrid.gridOptions.api.redrawRows();
        } else {
          rowData.HasError = false;
          dataGrid.gridOptions.api.redrawRows();
        }
      });
    }
  },

  deleteRow(dataGrid, rowData, cell, nodes) {
    //TODO: Serverside delete call
    //Quick solution. rowIndex cannot be used if data is sorted (TODO: use find-key instead)
    this.data.splice(cell.rowIndex, 1);
    nodes[cell.rowIndex].setSelected(true);
    dataGrid.gridOptions.api.setFocusedCell(cell.rowIndex, cell.column.colId);
    rowData.HasError = false;
    dataGrid.gridOptions.api.redrawRows();
  },

  openColumnSelectionDialog() {
    const dataGrid = this.$refs.dataGrid;
    this.$refs.columnSelectionDialog.show(dataGrid, false);
  },

  async loadUserSettings() {
    const me = this;
    const response = await api.get(
      `UserSetting?settingName=${me.userSettingsKey}`
    );
    me.settings = response.data;
    if (me.settings.SettingValue) {
      me.columnSettings = JSON.parse(me.settings.SettingValue);
    }
  },

  async saveUserSettings() {
    const me = this;
    if (!me.settings.SettingName) return;
    me.updateSettingsByColumns();
    const json = JSON.stringify(me.columnSettings);
    me.settings.SettingValue = json;
    api.post("UserSetting", me.settings);
  },

  updateSettingsByColumns() {
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) this.return;

    if (!this.columnSettings) {
      this.columnSettings = {
        columnInfos: [],
      };
    }
    const columnDefs = dataGrid.gridOptions.api.getColumnDefs();

    columnDefs.forEach((columnDef) => {
      const column = dataGrid.gridOptions.columnApi.getColumn(columnDef.colId);
      let columnInfo = this.columnSettings.columnInfos.find(
        (c) => c.name == columnDef.columnName
      );
      if (columnInfo == null) {
        columnInfo = {
          name: columnDef.columnName,
          width: column.actualWidth,
          visible: column.visible,
        };
        this.columnSettings.columnInfos.push(columnInfo);
      } else {
        columnInfo.width = column.actualWidth;
        columnInfo.visible = column.visible;
      }
    });
  },

  setGridTheme(darkMode) {
    this.gridThemeName =
      darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
  },

  setGridLanguage() {
    this.updateColumns();
  },

  async sizeColumnsToFit() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    dataGrid.gridOptions.api.sizeColumnsToFit();
    me.saveUserSettings();
  },
};
