import api from '@/api.js';
import Vue from 'vue';
export default {

    async onMounted() {
        const me = this;
        me.caption = `${this.$t(this.params.column.colDef.columnName)} ${this.$t('Selection')}`;
        me.keyField = this.params.keyField;
        me.parentKeyField = this.params.parentKeyField;
        me.displayField = this.params.displayField;
        me.displayText = this.params.data[this.displayField];
        me.value = {
            keyValue: this.params.data[this.keyField],
            displayValue: me.displayText
        };

        me.itemsFlat = [];
        me.items = [];
        me.loadUrl = me.params.loadUrl;
        me.queryStringField = me.params.queryStringField;
        me.queryStringParam = me.params.queryStringParam;
        await me.loadTreeData();

        me.treeValues = [];
        me.treeValues.push(this.params.data[this.keyField]);

        Vue.nextTick(() => {
            const textField = this.$refs.textField;
            textField.focus();
        });


    },

    async loadTreeData() {
        const me = this;
        const apiUrl = `${me.loadUrl}?${me.queryStringParam}=${me.params.data[this.queryStringField]}`;
        const x = await api.get(apiUrl);
        me.itemsFlat = x.data;
        me.items = me.transformItemsToHierarchy();
        me.openAll = true;
    },

    showDialog() {
        const me = this;
        me.dialog = true;
        Vue.nextTick(() => {
            setTimeout(() => {
                me.$refs.searchTextField.focus();
                const treeView = me.$refs.vtreeView;
                const activeElList = treeView.$el.querySelectorAll('div.v-treeview-node--active');
                if (activeElList != null && activeElList.length > 0) {
                    const el = activeElList[0];
                    el.scrollIntoView();
                }
            }, 100);
        });
    },

    onTextfieldKeyDown(ea) {
        if (ea.ctrlKey == true && ea.key == "Enter") {
            ea.stopPropagation();
            this.append();
        }
    },

    onClick() {},

    onClear() {
        this.value = JSON.stringify({
            keyValue: null,
            displayValue: ""
        });
        this.isValid = true;
        this.params.stopEditing();
    },

    onSearchClear() {
        this.loadTreeData();
    },

    onOK() {
        const me = this;
        const activeVal = this.treeValues != null && this.treeValues.length > 0 ? this.treeValues[0] : null;
        this.dialog = false;
        this.value = JSON.stringify({
            keyValue: activeVal,
            displayValue: this.itemsFlat.filter(x => x[me.keyField] == activeVal)[0][me.displayField]
        });
        this.isValid = true;
        this.params.stopEditing();
    },
    onCancel() {
        this.dialog = false;
        this.isValid = false;
        setTimeout(() => {
            this.params.stopEditing(true);
        }, 100)
    },


    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
        return this.value;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
        if (!this.isValid) return true;
        return false;
        // our editor will reject any value greater than 1000
    },



    transformItemsToHierarchy() {
        const me = this;
        if (me.itemsFlat == null) return null;
        let rootItems = me.itemsFlat.filter(x => x[me.parentKeyField] == null)
            .sort((a, b) => (a[me.displayField] > b[me.displayField]) ? 1 : -1);
        let nodes = [];
        rootItems.forEach(rootItem => {
            let newNode = {};
            Object.assign(newNode, rootItem);
            nodes.push(newNode);
            me.setItemChilds(newNode, rootItem);
        });
        return nodes;
    },

    setItemChilds(node, item) {
        const me = this;
        let childItems = this.itemsFlat.filter(x => x[me.parentKeyField] == item[me.keyField]);
        if (!childItems) return;
        node.children = []
        childItems.forEach(childItem => {
            let newNode = {};
            node.children.push(Object.assign(newNode, childItem))
            this.setItemChilds(newNode, childItem);
        });
    }

}