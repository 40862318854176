<template>
  <v-row justify="center">
    <v-dialog overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="360px"
    >
      <v-card>
        <v-card-title>
          <span>{{title}}</span>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="dialog = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>          
        <v-card-text style="height: 340px;">
          <v-container>
            <v-date-picker color="success" v-model="dateValue" :no-title="true" :show-week="true"></v-date-picker>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="standard"
            @click="dialog = false"
          >
            {{$t('Cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="onOK()"
            width="100"
          >
            {{$t('OK')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>

<script>
import methods from './methods.js';
export default {
  name: 'day-or-week-selection-dialog',
  created() {
  },
  mounted() {
    this.dialog = false;
  },
  methods: {
    ...methods
  },
  data: () => ({
    title: null,
    selectDay: false,
    dateValue : null,
    dialog: false,
  }),
};
</script>
