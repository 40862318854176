<template>
  <v-row justify="center">
    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="450px"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('ShowOrHideColumns') }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 400px;">
          <v-container>
            <v-list
              nav
              v-for="c in data.columnInfos"
              :key="c.name"
              class="pa-0"
            >
              <v-checkbox v-if="c.hideFromSelection == false"
                dense
                class="mt-2 ml-4 pa-0"
                :true-value="true"
                :false-value="false"
                v-model="c.visible"
                @change="onChange($event, c)"
                :label="$t(c.name)"
              ></v-checkbox>
            </v-list>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t('OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
tr:hover {
  background-color: transparent !important;
}
</style>

<script>
import methods from './methods.js'
export default {
  name: 'column-selection-dialog',
  created() {},
  mounted() {
    this.dialog = false
  },
  emits: {},
  computed: {},
  methods: { ...methods },
  data: () => ({
    data: {
      firstField: '',
      headerColumnCount: 0,
      columnInfos: []
    },    
    originalData: null,
    dialog: false,
  }),
}
</script>
