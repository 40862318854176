<template>
  <v-container fluid>
    <span class="ml-2">{{title}}</span>
      <p :style="getStyle">
        <ag-grid-vue style="width: 100%;height:100%" 
            :class="gridThemeName"                    
            ref="dataGrid"          
            :defaultColumnDefs="defaultColumnDefs"
            rowSelection="single"
            :columnDefs="columnDefs"
            :rowData="data"
            :overlayNoRowsTemplate="noRowsTemplate"
            :overlayLoadingTemplate="loadingTemplate"
            @rowClicked="onRowClicked($event)"
        >
        </ag-grid-vue>
      </p>    
      <column-selection-dialog ref="columnSelectionDialog" 
        @columnSelectionChanged="onColumnSelectionChanged($event)"
        @columnSelectionOK="onColumnSelectionOK($event)"
        >
      </column-selection-dialog>
  </v-container>
</template>
<style lang="scss">
   @import "~ag-grid-community/dist/styles/ag-grid.css";
   @import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
   @import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

</style>
<script>
import ColumnSelectionDialog from '@/components/dialogs/ColumnSelectionDialog/_Index.vue';
import ReadOnlyRenderer from '@/renderers/readOnlyRenderer.js';
import BoldRenderer from '@/renderers/boldRenderer.js';
import FormattedBoldRenderer from '@/renderers/formattedBoldRenderer.js';
import actions from './actions.js';
import { AgGridVue } from "ag-grid-vue";  
import dataGridInit from './dataGridInit.js';
import loadData from './loadData.js';
import dataGridUpdate from './dataGridUpdate.js';
import eventHandlers from './eventHandlers.js';

  export default {
    
    name: 'employee-list',

    props: {
      title: String,
      userSettingsKey: String,
    },    
    
    data: () => ({
      currentUser: null,
      showDividers: true,
      gridThemeName: 'ag-theme-alpine-dark',
      defaultColumnDefs: null,
      columnDefs: null,
      columnSettings: null,
      data: [],
      themeName: '',
      rowClassRules: null,
      noRowsTemplate: null,
      loadingTemplate: null,
      undoStack: [],
      settings: {},
      periodText: ''
    }),

    methods: {
      ...actions,
      ...dataGridInit,
      ...dataGridUpdate,
      ...loadData,
      ...eventHandlers,
    },

    async created() {
      this.setGridTheme(this.$gus.data.darkMode);
    },

    async beforeMount() {
      this.initialize();
    },

    computed:
    {
      getStyle() {
        //magic number 200 is the height of the toolbar + footer
        //TODO: Calculate size dynamically instead of using a fixed magic number
        let subtractHeight = this.displayAlert ? 265 : 190;
        return "width:100%;margin-top:10px;height:" + (parseInt(this.$vssHeight) - subtractHeight) + "px";
      },
    },
    components: {
      AgGridVue,
      // eslint-disable-next-line vue/no-unused-components
      readOnlyRenderer: ReadOnlyRenderer,
      // eslint-disable-next-line vue/no-unused-components
      boldRenderer: BoldRenderer,
      // eslint-disable-next-line vue/no-unused-components
      formattedBoldRenderer: FormattedBoldRenderer,

      // eslint-disable-next-line no-undef
      ColumnSelectionDialog,
    },
  }
</script>
