<template>
    <v-text-field dense class="px-2"
        :value="val"        
        @change="onChange($event)"
        @keypress="onKeyPress($event)"
        @keydown="onKeyDown($event)"
        @input="onInput($event)"
        ref="input"
    ></v-text-field>
 </template>
 <style lang="css">
 </style> 
<script>
import methods from './methods.js';
import helpers from '@/common/helpers.js';
  
export default {
  name: 'workOrExpenseEditor',
    data() {
        return {
            val: null,
            isValid: true,
            suffix: '', //Note: has to be assigned to prefix because the textfiels was reversed
        };
    },
    methods: {
        ...helpers,
        ...methods
    },
    mounted() {        
        this.onMounted();
    }
};
</script>
