<template>
  <time-recording-correction-list ref="timeRecordingCorrectionList" 
    :currentDate="mondayDate"
    @modeChanged="onModeChanged($event)"
    >
  </time-recording-correction-list>
</template>
<style lang="scss">
</style>
<script>
import TimeRecordingCorrectionList from '@/components/forms/TimeRecordingCorrectionList/_Index.vue'
import moment from 'moment';

export default {
    name: 'timerecording-correction',

    created() {
      this.mondayDate = moment(this.dateValue).startOf('isoweek');
    },

    mounted() {
      this.$refs.timeRecordingCorrectionList.refresh();
    },

    data: () => ({
      mondayDate: null
    }),

    methods: {

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevWeek");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextWeek");
      },

      getNavigateToText() {
        return this.$t("NavigateToWeek");
      },

      async navigateDateBackward() {
        this.$refs.timeRecordingCorrectionList.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.timeRecordingCorrectionList.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.timeRecordingCorrectionList.navigateToDate();
      },

      async insertNewRow() {
        this.$refs.timeRecordingCorrectionList.insertNewRow();
      },

      refresh() {
        this.$refs.timeRecordingCorrectionList.refresh();
      },

      async setGridTheme(darkMode) {
        this.$refs.timeRecordingCorrectionList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.timeRecordingCorrectionList.setGridLanguage();
      },

    },
    components: {
      TimeRecordingCorrectionList
    }

}

</script>
