import apiExcel from "@/apiExcel.js";
import fileSaver from "file-saver";
import moment from "moment";

export default {
  async initialize() {
    const me = this;

    await me.initDataGrid();

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.gridOptions.undoRedoCellEditing = true;
    }
    this.noRowsTemplate = this.$t("NoRowsFound");
    this.loadingTemplate = this.$t("ProcessingData");
    this.refresh();
  },

  async refresh() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) return;
    const cell = dataGrid.gridOptions.api.getFocusedCell();
    await me.loadData();
    dataGrid.gridOptions.api.setRowData(this.data);
    if (cell) {
      dataGrid.gridOptions.api.setFocusedCell(cell.rowIndex, cell.column);
    }
  },

  async releaseForBooking(selectedUserUID) {
    const me = this;
    me.$confirm(this.$t("ReleaseWeekForBookingText"), {
      title: this.$t("ReleaseWeekForBookingTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.releaseForBookingExec(selectedUserUID);
      }
    });
  },

  async releaseForBookingExec(selectedUserUID) {
    const me = this;
    const dayDate = moment(this.$dates.mondayDate).add(6, "d");
    await this.$api.post("User/Booking", {
      UserUID: selectedUserUID,
      DayDate: dayDate.format("YYYY-MM-DD"),
    });
    me.refresh();
  },

  async returnForProcessing(selectedUserUID) {
    const me = this;
    me.$confirm(this.$t("ReturnWeekText"), {
      title: this.$t("ReturnTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.returnForProcessingExec(selectedUserUID);
      }
    });
  },

  async returnForProcessingExec(selectedUserUID) {
    const me = this;
    const dayDate = moment(this.$dates.mondayDate).subtract(1, "d");
    await this.$api.post("User/FinalizeReset", {
      UserUID: selectedUserUID,
      DayDate: dayDate.format("YYYY-MM-DD"),
    });
    me.refresh();
  },

  openColumnSelectionDialog() {
    const dataGrid = this.$refs.dataGrid;
    this.$refs.columnSelectionDialog.show(dataGrid, false);
  },

  async loadUserSettings() {},

  async saveUserSettings() {},

  setGridTheme(darkMode) {
    this.gridThemeName =
      darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
  },

  setGridLanguage() {
    this.updateColumns();
    this.updatePeriodText();
  },

  async sizeColumnsToFit() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    dataGrid.gridOptions.api.sizeColumnsToFit();
    me.saveUserSettings();
  },

  navigateDateBackward() {
    if (this.showOnlyOneDay === true) {
      this.$dates.currentDate = this.$dates.currentDate.subtract(1, "d");
      this.$dates.mondayDate = this.$dates.currentDate
        .clone()
        .startOf("isoWeek");
    } else {
      this.$dates.mondayDate = this.$dates.mondayDate.subtract(1, "w");
      this.$dates.currentDate = this.$dates.mondayDate.clone();
    }
    this.refresh();
  },

  navigateDateForward() {
    if (this.showOnlyOneDay === true) {
      this.$dates.currentDate = this.$dates.currentDate.add(1, "d");
      this.$dates.mondayDate = this.$dates.currentDate
        .clone()
        .startOf("isoWeek");
    } else {
      this.$dates.mondayDate = this.$dates.mondayDate.add(1, "w");
      this.$dates.currentDate = this.$dates.mondayDate.clone();
    }
    this.refresh();
  },

  navigateToDate() {
    this.$refs.dayOrWeekSelectionDialog.show(
      moment(this.$dates.currentDate).format("YYYY-MM-DD"),
      this.showOnlyOneDay
    );
  },

  onWeekdaySelectionChanged(dateValue) {
    this.$dates.currentDate = moment(dateValue);
    this.$dates.mondayDate = this.$dates.currentDate.clone().startOf("isoWeek");
    this.refresh();
  },

  async exportToExcel() {
    const me = this;
    let language = this.$gus.data.languageCode;
    if (!language || language === "en") language = "de"; //Excel not available in English
    const response = await apiExcel.get(
      `excel/week?MondayDate=${moment(this.$dates.mondayDate).format(
        "YYYY-MM-DD"
      )}&Language=${language}&sheetPerUser=true`
    );
    const fileName = me.getFilenameFromHeader(
      response.headers,
      `${me.title}.xlsx`
    );
    fileSaver.saveAs(response.data, fileName);
  },

  getFilenameFromHeader(responseHeaders, defaultName) {
    let fileName = defaultName;
    let content_disposition = responseHeaders["content-disposition"];
    if (content_disposition && content_disposition.indexOf(";") > 0) {
      let tokens = content_disposition.split(";");
      if (tokens && tokens.length > 1) {
        let temp = tokens[1].trim();
        tokens = temp.split("=");
        if (tokens && tokens.length > 1) {
          fileName = tokens[1].trim();
        }
      }
    }
    return fileName;
  },
};
