<template>
  <v-container fluid>  
    <v-expand-transition><h3>{{$t('Welcome')}} {{$user.UserName}}</h3></v-expand-transition>
  </v-container>
</template>
<script>
  export default {
    name: 'Home'
  }
</script>
