export default {

    onClearClick() {
        this.reset();
        this.$emit("changed", this.selectedItem);
    },

    reset() {
        this.searchText = "";
        this.selectedItem = null;
    },

    onMounted() {
        const me = this;
        me.caption = "Test";

        me.items = me.transformItemsToHierarchy();

        // me.items = this.params.items;
        // me.displayText = this.params.data[this.displayField];
        // me.value = this.params.data[this.keyField];
        // me.treeValues = [];
        // me.treeValues.push(this.value);
        // me.dialog = true;
        //   setTimeout(() => {
        //     me.$refs.searchTextField.focus();
        //     const treeView = me.$refs.vtreeView;
        //     const activeElList = treeView.$el.querySelectorAll('div.v-treeview-node--active');
        //     if (activeElList != null && activeElList.length > 0) {          
        //       const el = activeElList[0];
        //       el.scrollIntoView();
        //     }
        //     //v-treeview-node--active
        //   }, 200)
        // },

    },

    async openSearchDialog() {
        const me = this;
        me.dialog = true;
    },

    onOK() {
        this.setSelected();
        this.dialog = false;
    },

    setValue(structureUID) {
        const me = this;
        me.treeValues = [];
        me.treeValues.push(structureUID);
        me.setSelected();
    },

    getValue() {
        const activeVal = this.treeValues != null && this.treeValues.length > 0 ? this.treeValues[0] : null;
        return activeVal;
    },

    setSelected() {
        const me = this;
        const activeVal = this.treeValues != null && this.treeValues.length > 0 ? this.treeValues[0] : null;
        if (!activeVal) return;
        this.selectedItem = me.itemsFlat.filter(x => x.StructureUID == activeVal)[0];
        this.searchText = this.selectedItem.StructureName;
        this.structureName = this.selectedItem.StructureName;
        this.$emit("changed", this.selectedItem);
    },

    transformItemsToHierarchy() {
        const me = this;
        if (me.itemsFlat == null) return null;
        let rootItems = me.itemsFlat.filter(x => x.ParentStructureUID == null)
            .sort((a, b) => (a.StructureName > b.StructureName) ? 1 : -1);
        let nodes = [];
        rootItems.forEach(rootItem => {
            let newNode = {};
            Object.assign(newNode, rootItem);
            nodes.push(newNode);
            me.setItemChilds(newNode, rootItem);
        });
        return nodes;
    },

    setItemChilds(node, item) {
        const me = this;
        let childItems = me.itemsFlat.filter(x => x.ParentStructureUID == item.StructureUID);
        if (!childItems) return;
        node.children = []
        childItems.forEach(childItem => {
            let newNode = {};
            node.children.push(Object.assign(newNode, childItem))
            this.setItemChilds(newNode, childItem);
        });
    },


    onCancel() {
        this.dialog = false;
    },

}