import api from "@/api.js";
export default {
  onMounted() {
    const me = this;
    this.headers = this.createHeaders();
    me.keyField = this.params.keyField;
    me.displayField = this.params.displayField;
    me.searchText = this.params.data[this.displayField];
    me.dialogSearchText = this.params.data[this.displayField];
    setTimeout(() => {
      if (me.$refs.textField) me.$refs.textField.focus();
    }, 800);
  },

  onKeyDown(e) {
    const me = this;
    if (e.key == "Tab" || e.key == "Enter") {
      e.cancelBubble = true;
      this.searchInField(me.searchText);
    }
  },

  async searchInField(searchText) {
    const me = this;
    const url =
      !searchText || searchText == ""
        ? `Projects/Search?key=''`
        : `Projects/Search?searchString=${searchText}`;
    const res = await api.get(url);
    const data = res.data;
    if (data && data.length > 0) {
      me.items = data;
      if (data.length == 1) {
        this.setSelected(data[0]);
      } else if (data.length > 1) {
        me.dialogSearchText = me.searchText;
        me.openSearchDialog();
      }
    }
  },

  async searchInDialog(searchText) {
    const me = this;
    const url =
      !searchText || searchText == ""
        ? `Projects/Search`
        : `Projects/Search?searchString=${searchText}`;
    const res = await api.get(url);
    const data = res.data;
    if (data && data.length > 0) {
      me.items = data;
    }
  },

  async openSearchDialog() {
    const me = this;
    this.searchInDialog(this.dialogSearchText);
    me.dialog = true;
  },

  onSearchDialogKeyDown() {
    this.searchInDialog(this.dialogSearchText);
  },

  onRowClick(item, row) {
    row.select(true);
    this.selectedDialogItem = item;
  },

  rowClicked(item) {
    this.toggleSelection(item);
  },
  toggleSelection(item) {
    if (this.selectedDialogItem == item) {
      this.selectedDialogItem = null;
    } else {
      this.selectedDialogItem = item;
    }
  },

  onKeyPress() {},

  onOK() {
    if (this.selectedDialogItem == null) {
      this.isValid = false;
      return;
    }
    this.setSelected(this.selectedDialogItem);
    this.dialog = false;
  },

  onClear() {
    this.dialogSearchText = "";
    this.searchInDialog(this.dialogSearchText);
  },

  setSelected(item) {
    this.selectedItem = item;
    this.selectedDialogItem = item;
    this.searchText = `${this.selectedItem.ProjectKey} - ${this.selectedItem.ProjectName}`;
    this.projectName = this.selectedItem.ProjectName;
    this.cancelEdit = false;
    this.isValid = true;
    this.value = JSON.stringify({
      ProjectUID: this.selectedItem["ProjectUID"],
      ProjectKey: this.selectedItem["ProjectKey"],
      ProjectName: this.selectedItem["ProjectName"],
    });
    //this.value = this.selectedItem[this.keyField] + "," + this.selectedItem["ProjectName"] + "," + this.selectedItem["ProjectKey"];
    this.params.stopEditing();
  },

  onCancel() {
    this.dialog = false;
  },

  createHeaders() {
    const headers = [
      {
        text: this.$t("ProjectKey"),
        align: "start",
        sortable: true,
        value: "ProjectKey",
      },
      {
        text: this.$t("ProjectName"),
        align: "start",
        sortable: true,
        value: "ProjectName",
      },
    ];
    return headers;
  },

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  },

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  },

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    if (this.value == null) return true;
    return false;
    // our editor will reject any value greater than 1000
  },
};
