<template>
  <v-container fluid id="Container">
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height: 50px;">
        <tr>
          <td class="pr-4">
            <span >{{ title }}</span>
          </td>
          <td class="px-4" >
            <span style="font-size: 13px;">{{ periodText }}</span>
          </td>
          <td class="ml-4 mr-2" >
            <span style="font-size: 13px;">
              {{ $t('Status') }}:
            </span>
            <span style="color: limegreen; font-size: 13px;">
              {{ statusText }}
            </span>
          </td>
          <td>
            <span class="ml-4 mr-2" style="font-size: 13px;">
              {{ $t('Project') }}:
            </span>
          </td>
          <td>
            <lookup-box  
              ref="projectLookupBox"
              :caption="$t('SelectProject')"
              apiUrl="Projects/Search?"              
              :searchText="projectSearchText"
              :columnDefs="projectLookupColumnDefs"
              keyField="ProjectUID"
              displayField="ProjectName"
              @changed="onProjectChanged($event)"
              width="200"
            ></lookup-box>
          </td>
          <td>
            <span
              class="ml-4 mr-2"
              style="font-size: 13px;"
              v-if="structuresFlat && structuresFlat.length > 0"
            >
              {{ $t('Structure') }}:
            </span>
          </td>
          <td>
            <structure-lookup-box
              ref="structureLookupBox"
              width="80"
              :itemsFlat="structuresFlat"
              v-if="structuresFlat && structuresFlat.length > 0"
              @changed="onStructureChanged($event)"
            ></structure-lookup-box>
          </td>
          <td v-if="canSelectEmployee">
            <v-select
              ref="selectUser"
              style="max-width: 140px; font-size: 12px;"
              class="pa-0 mt-4 ml-6 mr-0"
              dense
              item-text="UserName"
              item-value="UserUID"
              :items="users"
              @change="onSelectedUserChanged($event)"
              v-model="selectedUserUID"
            ></v-select>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        :class="gridThemeName"
        ref="dataGrid"
        :rowClassRules="rowClassRules"
        :defaultColumnDefs="defaultColumnDefs"
        rowSelection="single"
        :columnDefs="columnDefs"
        :rowData="data.Rows"
        :pinnedBottomRowData="totalsRowData"
        :overlayNoRowsTemplate="noRowsTemplate"
        :overlayLoadingTemplate="loadingTemplate"
        @keydown.native="onKeydown($event)"
        @cellFocused="onCellFocused($event)"
        @cellValueChanged="onGridCellValueChanged($event)"
      ></ag-grid-vue>
      <transition name="bounce">
        <v-alert
          type="orange darken-4"
          icon="mdi-exclamation"
          elevation="2"
          dismissible
          class="mt-4"
          transition="scale-transition"
          v-if="displayAlert"
        >
          {{ alertText }}
        </v-alert>
      </transition>
    </p>
    <column-selection-dialog
      ref="columnSelectionDialog"
      @columnSelectionChanged="onColumnSelectionChanged($event)"
      @columnSelectionOK="onColumnSelectionOK($event)"
    ></column-selection-dialog>
    <day-or-week-selection-dialog
      ref="dayOrWeekSelectionDialog"
      @onOK="onWeekdaySelectionChanged($event)"
    ></day-or-week-selection-dialog>
  </v-container>
</template>
<style lang="css">
  @import '../../../common/dataGridStyles.css';
</style>
<script>
import DayOrWeekSelectionDialog from '@/components/dialogs/DayOrWeekSelectionDialog/_Index.vue'
import ColumnSelectionDialog from '@/components/dialogs/ColumnSelectionDialog/_Index.vue'
import ComboBoxEditor from '@/components/editors/ComboBoxEditor/_Index.vue'
import TreeViewEditor from '@/components/editors/TreeViewEditor/_Index.vue'
import ProjectLookupBox from '@/components/editors/ProjectLookupBox/_Index.vue'
import LookupBox from '@/components/editors/LookupBox/_Index.vue'
import StructureLookupBox from '@/components/editors/StructureLookupBox/_Index.vue'
import ActiveTreeViewEditor from '@/components/editors/ActiveTreeViewEditor/_Index.vue'
import MultilineEditor from '@/components/editors/MultilineEditor/_Index.vue'
import LookupEditor from '@/components/editors/LookupEditor/_Index.vue';
import WorkOrExpenseEditor from '@/components/editors/WorkOrExpenseEditor/_Index.vue'
import ReadOnlyRenderer from '@/renderers/readOnlyRenderer.js'
import BoldRenderer from '@/renderers/boldRenderer.js'
import ValueRenderer from '@/renderers/valueRenderer.vue'
import FormattedBoldRenderer from '@/renderers/formattedBoldRenderer.js'
import actions from '../ConstructionSiteReport/actions.js'
import dataGridInit from '../ConstructionSiteReport/dataGridInit.js'
import dataGridUpdate from '../ConstructionSiteReport/dataGridUpdate.js'
import eventHandlers from '../ConstructionSiteReport/eventHandlers.js'
import helpers from '@/common/helpers.js'
import userSettings from '@/common/userSettings.js'
import loadData from '../ConstructionSiteReport/loadData.js'
import { AgGridVue } from 'ag-grid-vue'
import utils from '@/common/utils.js';
import viewHelper from '../../../common/viewHelpers';

export default {
  name: 'construction-site-report',

  props: {
    title: String,
    userSettingsKey: String,
    showOnlyOneDay: Boolean,
  },

  data: () => ({
    paramUserUID: null,
    showDividers: true,
    gridThemeName: 'ag-theme-alpine-dark',
    displayAlert: false,
    defaultColumnDefs: null,
    columnDefs: null,
    columnSettings: null,
    data: { Items: [], WeekState: { Status: null, StatusToDate: null } },
    project: null,
    projectLookupColumnDefs: [],
    structure: null,
    workTypes: [],
    workTypeLookup: {},
    costCenters: [],
    costCenterLookup: {},
    workCategories: [],
    workCategoryLookup: {},
    totalsRowData: [],
    totalWorkhoursCaption: null,
    totalExpensesCaption: null,
    themeName: '',
    rowClassRules: null,
    noRowsTemplate: null,
    loadingTemplate: null,
    weekStartDateFormatted: null,
    weekEndDateFormatted: null,
    undoStack: [],
    settings: {},
    periodText: '',
    users: [],
    selectedUserUID: null,
    canSelectEmployee: false,
    statusText: '',
    showRelease: false,
    structuresFlat: null,
    projectUserSettingsKey: "Baustellenrapport_ProjektUid",
    structureUserSettingsKey: "Baustellenrapport_StructureUid",
    projectSearchText: '',
    statusTextCode: '',
    projectUID: null,
  }),

  async created() {
    this.setGridTheme(this.$gus.data.darkMode)
  },


  beforeMount() {    
    this.initialize()
  },

  methods: {
    ...helpers,
    ...eventHandlers,
    ...loadData,
    ...dataGridInit,
    ...actions,
    ...dataGridUpdate,
    ...userSettings,
    ...utils,
    ...viewHelper
  },

  computed: {
        
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 265 : 195
      return (
        'width:100%;margin-top:10px;height:' +
        (parseInt(this.$vssHeight) - subtractHeight) +
        'px'
      )
    },
  },
  components: {
    AgGridVue,
    LookupBox,
    // eslint-disable-next-line vue/no-unused-components
    ProjectLookupBox,
    // eslint-disable-next-line vue/no-unused-components
    StructureLookupBox,
    // eslint-disable-next-line vue/no-unused-components
    readOnlyRenderer: ReadOnlyRenderer,
    // eslint-disable-next-line vue/no-unused-components
    valueRenderer: ValueRenderer,
    // eslint-disable-next-line vue/no-unused-components
    boldRenderer: BoldRenderer,
    // eslint-disable-next-line vue/no-unused-components
    formattedBoldRenderer: FormattedBoldRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ComboBoxEditor,
    // eslint-disable-next-line vue/no-unused-components
    TreeViewEditor,
    // eslint-disable-next-line vue/no-unused-components
    LookupEditor,
    // eslint-disable-next-line vue/no-unused-components
    ActiveTreeViewEditor,
    // eslint-disable-next-line vue/no-unused-components
    WorkOrExpenseEditor,
    // eslint-disable-next-line vue/no-unused-components
    MultilineEditor,
    ColumnSelectionDialog,
    // eslint-disable-next-line vue/no-unused-components
    DayOrWeekSelectionDialog,
  },
}
</script>
