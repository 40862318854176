<template>
    <v-row justify="center" class="pa-0 ma-0">
        <v-text-field
        dense
        class="px-4 mb-4"
        style="font-size:14px"
        ref="textField"
        :value="val"       
        @keyup="onTextfieldKeyUp($event)" 
        @keydown="onTextfieldKeyDown($event)" 
        @change="onChange($event)"
        @blur="onBlur($event)"
        clearable
        append-icon="mdi-text-box"
        @click:clear="onClear()"
        @click:append="showDialog($event)"
        />
        <v-dialog overlay-color="blue darken-3"
        v-model="dialog"
        scrollable
        max-width="700px"
        >
        <v-card>
            <v-card-title>
            <v-icon>mdi-text-box</v-icon>
            <span class="ml-2">{{caption}}</span>
            <v-spacer></v-spacer>
            <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="dialog = false"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </template>
            </v-menu>
            </v-card-title>          
            <v-card-text style="height: 390px;">
            <v-container>
                <v-textarea
                    ref="vtextarea"
                    outlined
                    rows="10"
                    @keydown="onTextareaKeyDown($event)" 
                    :counter="$gcs.data.maxBookingText"
                    v-model="valTextarea"
                    ></v-textarea>            
            </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="standard"
                @click="dialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                @click="onOK()"
                width="100"
            >
                {{$t('OK')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>    
 </template>
 <style lang="css">
 </style> 
<script>
import methods from './methods.js';
import helpers from '@/common/helpers.js';
  
export default {
  name: 'MultilineEditor',
    data() {
        return {
            val: null,
            valTextarea: null,
            isValid: true,
            dialog: false,
            caption: '',
            suffix: '', //Note: has to be assigned to prefix because the textfiels was reversed
        };
    },
    methods: {
        ...helpers,
        ...methods
    },
    mounted() {     
        this.onMounted();
    }
};
</script>
