<template>
  <v-app>
  <v-container>
    <v-row align="center" justify="center" style="height:50px;margin-top:50px">
      <p>{{$t("PasswordForgotten_NewPasswordWasSet")}}</p>
    </v-row>
    <v-row align="center" style="height:50px"
        justify="center">
      <v-btn to="/login">{{$t("PasswordForgotten_ToLogin")}}</v-btn>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'set-new-password-expired',

};
</script>