import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de.ts'
import fr from 'vuetify/src/locale/fr.ts'
import en from 'vuetify/src/locale/en.ts'

Vue.use(Vuetify);
export default new Vuetify({
    lang: {
        locales: { de, fr, en },
        current: 'de',
      },    
    theme: {
        options: {
            customProperties: true,
        },
    },
})


