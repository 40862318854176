import api from "@/api.js";
export default {
  onMounted() {
    this.headers = this.createHeaders();
  },

  onKeyDown(e) {
    const me = this;
    if (e.key == "Tab") {
      this.searchInField(me.searchText);
    }
    if (e.key == "Enter") {
      this.searchInField(me.searchText);
    }
  },

  setFocus() {
    setTimeout(() => {
      this.$refs.textField.focus();
    }, 200);
  },

  async searchInField(searchText) {
    const me = this;
    const url =
      !searchText || searchText == ""
        ? `Projects/ByKey?key=''`
        : `Projects/ByKey?key=${searchText}`;
    const res = await api.get(url);
    const data = res.data;
    if (data && data.length > 0) {
      me.items = data;
      if (data.length == 1) {
        this.setSelected(data[0]);
      } else if (data.length > 1) {
        //let i = 0;
        // data.forEach(element => {
        //     if (element.)
        // });

        me.dialogSearchText = me.searchText;
        me.openSearchDialog();
      }
    }
  },

  async searchInDialog(searchText) {
    const me = this;
    const url =
      !searchText || searchText == ""
        ? `Projects/Search`
        : `Projects/Search?searchString=${searchText}`;
    const res = await api.get(url);
    const data = res.data;
    if (data && data.length > 0) {
      me.items = data;
    }
  },

  async openSearchDialog() {
    const me = this;
    this.searchInDialog(this.dialogSearchText);
    me.dialog = true;
  },

  onSearchDialogKeyDown() {
    this.searchInDialog(this.dialogSearchText);
  },

  onRowClick(item, row) {
    row.select(true);
    this.selectedDialogItem = item;
  },

  onKeyPress() {},

  onOK() {
    this.setSelected(this.selectedDialogItem);
    this.$emit("changed", this.selectedItem);
    this.dialog = false;
  },

  setSelected(item) {
    this.selectedItem = item;
    this.searchText = `${this.selectedItem.ProjectKey} - ${this.selectedItem.ProjectName}`;
    this.projectName = this.selectedItem.ProjectName;
    this.$emit("changed", this.selectedItem);
  },

  onCancel() {
    this.dialog = false;
  },

  createHeaders() {
    const headers = [
      {
        text: this.$t("ProjectKey"),
        align: "start",
        sortable: true,
        value: "ProjectKey",
      },
      {
        text: this.$t("ProjectName"),
        align: "start",
        sortable: true,
        value: "ProjectName",
      },
    ];
    return headers;
  },
};
