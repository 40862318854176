import Vue from "vue";
export default {
  onMounted() {
    const me = this;
    this.isValid = true;
    me.val = me.params.value;
    me.caption = me.params.caption;
    //Take over value of keypress on datagrid to the editor value (Excel behaviour)
    let inputText = this.params.charPress;
    if (inputText != null) {
      me.val = inputText;
    }
    setTimeout(() => {
      if (me.$refs.textField) me.$refs.textField.focus();
    }, 50);
  },
  showDialog() {
    const me = this;
    this.valTextarea = this.val;
    me.dialog = true;
    Vue.nextTick(() => {
      setTimeout(() => {
        me.$refs.vtextarea.focus();
      }, 50);
    });
  },

  onClear() {
    this.val = null;
    this.isValid = true;
    this.params.stopEditing();
  },

  onChange(ea) {
    this.val = ea; //TODO: parse
    this.params.stopEditing();
  },
  onOK() {
    this.isValid = true;
    this.val = this.valTextarea;
    this.dialog = false;
  },
  onCancel() {
    this.isValid = false;
    this.dialog = false;
  },
  onBlur() {},

  onTextfieldKeyUp(ea) {
    if (
      ea.ctrlKey == true ||
      ea.key == "Enter" ||
      ea.key == "Escape" ||
      ea.key == "Tab"
    )
      return;
    this.val = ea.srcElement._value;
  },

  onTextfieldKeyDown(ea) {
    const me = this;
    if (ea.ctrlKey == true && ea.key == "Enter") {
      ea.stopPropagation();
      this.append();
    } else if (ea.key == "Escape") {
      me.isValid = false;
      me.params.stopEditing(true);
    } else if (ea.key == "Tab") {
      me.val = ea.srcElement._value;
      ea.stopPropagation();
      me.params.stopEditing();
      const api = me.params.api;
      setTimeout(() => {
        api.tabToNextCell();
      }, 50);
    } else if (ea.key == "Enter") {
      me.params.stopEditing();
    } else {
      if (
        ea.srcElement._value &&
        this.$gcs.data.maxBookingText > 0 &&
        ea.srcElement._value.length >= this.$gcs.data.maxBookingText
      ) {
        if (ea.keyCode >= 48 && ea.keyCode <= 90) {
          ea.preventDefault();
          return;
        }
      }
    }
  },

  onTextareaKeyDown(ea) {
    if (this.$gcs.data.maxBookingText > 0 && ea.srcElement._value.length >= this.$gcs.data.maxBookingText) {
      if (ea.keyCode >= 48 && ea.keyCode <= 90) {
        ea.preventDefault();
        return;
      }
    }
  },

  onInput(ea) {
    this.val = ea; //TODO: parse
  },

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.val;
  },

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  },

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return !this.isValid;
    //return false; //TODO: numeric validation
    // our editor will reject any value greater than 1000
  },
};
