<template>
  <v-container fluid>
    <span class="ml-2">{{ $t("GlobalWorkTypes") }}</span>
    <v-data-table
      class="elevation-1 mt-4"
      dense
      v-if="loaded"
      v-model="selectedWorkTypes"
      :headers="headers"
      :items="workTypes"
      item-key="WorkTypeUID"
      display-text="WorkType"
      show-select
      @item-selected="onItemSelected($event)"
      @click:row="onRowClick($event)"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
    </v-data-table>
    <v-sheet class="pa-3" v-else>
      <v-skeleton-loader class="mx-auto"> </v-skeleton-loader>
    </v-sheet>
  </v-container>
</template>
<style lang="scss">
</style>
<script>
import api from "@/api.js";

export default {
  name: "global-workTypes-list",

  data: () => ({
    loaded: true,
    itemsPerPage: -1,
    workTypes: [],
    workTypesClientSetting: null,
    selectedWorkTypes: [],
    originalWorkTypeUIDs: [],
    currentWorkTypeUIDs: [],
    headers: [],
  }),

  async created() {
    this.headers = this.createHeaders();
    this.refresh();
  },

  async beforeMount() {},

  mounted() {},

  methods: {
    addNewRecord_isInvisible() {
      return true;
    },

    async refresh() {
      const me = this;
      me.loaded = false;
      const res = await api.get("workTypes");

      //const locale = this.$i18n.locale;

      let data = res.data;
      data.forEach((row) => {
        const workCategory = 'WorkCategory_' + row.WorkCategory.replace(" ", "_");
        row.WorkCategory = this.$t(workCategory);
      });      

      this.workTypes = data;
      const response = await api.get(`clientSetting?SettingName=WorkTypes`);
      me.workTypesClientSetting = response.data;
      let selectedWorkTypesCsv = this.workTypesClientSetting.SettingValue;
      if (selectedWorkTypesCsv) {
        selectedWorkTypesCsv = selectedWorkTypesCsv.toLowerCase();
        const selectedWorkTypeUIDs = selectedWorkTypesCsv.split(",");
        me.selectedWorkTypes = me.workTypes.filter(
          (x) => selectedWorkTypeUIDs.indexOf(x.WorkTypeUID.toLowerCase()) >= 0
        );
        me.originalWorkTypeUIDs = me.selectedWorkTypes.map(
          (x) => x.WorkTypeUID
        );
      }
      me.loaded = true;
      this.$emit("IsDirtyChanged", false);
    },

    undo() {
      const me = this;
      me.selectedWorkTypes = me.workTypes.filter(
        (x) => me.originalWorkTypeUIDs.indexOf(x.WorkTypeUID.toLowerCase()) >= 0
      );
      this.$emit("IsDirtyChanged", false);
    },

    async save(nextPageCallBack) {
      const me = this;
      if (!me.workTypesClientSetting) return;

      const workTypeUIDs = me.selectedWorkTypes.map((x) => x.WorkTypeUID);
      me.workTypesClientSetting.SettingValue = workTypeUIDs.join(",");
      await api.post("ClientSetting", me.workTypesClientSetting);
      me.originalWorkTypeUIDs = workTypeUIDs;

      this.$emit("IsDirtyChanged", false);
      nextPageCallBack();
    },

    onRowClick(e) {
      const x = this.selectedWorkTypes.filter(
        (x) => x.WorkTypeUID === e.WorkTypeUID
      );
      let selected = false;
      if (x.length == 0) {
        this.selectedWorkTypes.push(e);
        selected = true;
      } else {
        this.selectedWorkTypes = this.selectedWorkTypes.filter(
          (x) => x.WorkTypeUID !== e.WorkTypeUID
        );
      }
      this.onSelectedChanged({ item: e, value: selected });
    },

    onItemSelected(e) {
      this.onSelectedChanged(e);
    },

    onSelectedChanged() {
      this.$emit("IsDirtyChanged", true);
    },

    setGridLanguage() {
      this.updateHeaders();
    },

    updateHeaders() {
      this.headers.forEach((header) => {
        header.text = this.$t(header.name);
      });
    },

    createHeaders() {
      const me = this;
      const headers = [
        {
          name: "Worktype_ID",
          text: me.$t("Column_WorkTypeID"),
          value: "WorkTypeID",
        },
        {
          name: "Worktype",
          text: me.$t("Worktype"),
          align: "start",
          sortable: false,
          value: "WorkType",
        },
        {
          name: "Worktype_Category",
          text: me.$t("Worktype_Category"),
          value: "WorkCategory",
        },
        {
          name: "Worktype_CostFactor",
          text: me.$t("Worktype_CostFactor"),
          value: "CostFactor",
        },
      ];
      return headers;
    },
  },
};
</script>
