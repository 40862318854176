//import utils from '@/common/utils.js';
export default {
  async initDataGrid() {
    const me = this;

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.gridOptions.undoRedoCellEditing = true;
    }

    let columnInfoMap = {};
    this.columnSettings.columnInfos.forEach((element) => {
      columnInfoMap[element.ColumnName] = element;
    });
    me.initCssClassRules();
    me.defaultColumnDefs = {
      editable: true,
      resizable: true,
      sortable: true,
    };
    me.columnDefs = me.createHeaderColumnDefs(columnInfoMap);
    me.columnDefs.push(...me.createValueColumnDefs(columnInfoMap));
    me.columnDefs.push(me.createTotalColumnDef(columnInfoMap));
  },

  getColumnInfoValue(map, columnName, propertyName, defaultValue) {
    let info = map[columnName];
    if (info == null || info[propertyName == null]) {
      return defaultValue;
    } else {
      return map[columnName][propertyName];
    }
  },

  createValueColumnDef(weekday, weekdayNo, columnInfoMap) {
    const me = this;
    const columnName = `Column_WorkHours${weekday}`;
    return {
      field: `${weekday}Value`,
      columnName: columnName,
      headerName: me.$t(columnName),
      isValueColumn: true,
      weekdayNo: weekdayNo,
      editable: (params) => me.isDayEditable(params, weekdayNo),
      minWidth: 60,
      maxWidth: 110,
      width: me.getColumnInfoValue(columnInfoMap, columnName, "width", 85),
      hide: !me.getColumnInfoValue(columnInfoMap, columnName, "visible", true),
      resizable: true,
      cellEditor: "WorkOrExpenseEditor",
      type: "rightAligned",
      cellClassRules: me.valueCellClassRules,
      cellRendererFramework: "valueRenderer",
      valueFormatter: (params) => {
        return this.formatWorkOrExpenses(params);
      },
    };
  },

  createValueColumnDefs(columnInfoMap) {
    const me = this;
    const valueColumnDefs = [
      me.createValueColumnDef("Monday", 0, columnInfoMap),
      me.createValueColumnDef("Tuesday", 1, columnInfoMap),
      me.createValueColumnDef("Wednesday", 2, columnInfoMap),
      me.createValueColumnDef("Thursday", 3, columnInfoMap),
      me.createValueColumnDef("Friday", 4, columnInfoMap),
      me.createValueColumnDef("Saturday", 5, columnInfoMap),
      me.createValueColumnDef("Sunday", 6, columnInfoMap),
    ];
    return valueColumnDefs;
  },

  isWeekOrDayEditable(params) {
    if (this.showOnlyOneDay === true) {
      let weekdayNo = this.$dates.currentDate.day() - 1;
      return this.isDayEditable(params, weekdayNo);
    }
    return this.isWeekEditable(params);
  },

  createHeaderColumnDefs(columnInfoMap) {
    const me = this;

    const headerColumnDefs = [
      {
        field: "ProjectUIDAndNameAndKey",
        columnName: "Column_ProjectKey",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        headerName: me.$t("Column_ProjectKey"),
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        resizable: true,
        minWidth: 100,
        maxWidth: 200,
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_ProjectKey",
          "visible",
          true
        ),
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_ProjectKey",
          "width",
          180
        ),
        cellEditor: "LookupEditor",
        cellEditorParams: {
          caption: me.$t("SelectProject"),
          apiUrl: "Projects",
          columnDefs: [
            {
              field: "ProjectKey",
              headerName: me.$t("ProjectKey"),
              editable: false,
              resizable: true,
              sortable: true,
              width: 150,
            },
            {
              field: "ProjectName",
              headerName: me.$t("ProjectName"),
              editable: false,
              sortable: true,
              resizable: true,
              width: 300,
            },
          ],
          keyField: "ProjectUID",
          displayField: "ProjectKey",
          writeBackDelegate: (value, selectedItem) => {
            value.ProjectName = selectedItem["ProjectName"];
          },
        },

        cellClassRules: me.cellClassRules,
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value;
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.ProjectKey;
          }
          return "";
        },
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "ProjectUIDAndNameAndKey",
        columnName: "Column_ProjectName",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        headerName: me.$t("Column_ProjectName"),
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        resizable: true,
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_ProjectName",
          "visible",
          true
        ),
        minWidth: 200,
        maxWidth: 500,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_ProjectName",
          "width",
          220
        ),
        cellEditor: "LookupEditor",
        cellEditorParams: {
          caption: me.$t("SelectProject"),
          apiUrl: "Projects",
          columnDefs: [
            {
              field: "ProjectKey",
              headerName: me.$t("ProjectKey"),
              editable: false,
              resizable: true,
              width: 150,
            },
            {
              field: "ProjectName",
              headerName: me.$t("ProjectName"),
              editable: false,
              resizable: true,
              width: 300,
            },
          ],
          keyField: "ProjectUID",
          displayField: "ProjectName",
          searchExactField: "ProjectKey",
          writeBackDelegate: (value, selectedItem) => {
            value.ProjectKey = selectedItem["ProjectKey"];
          },
        },
        cellClassRules: me.cellClassRules,
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value; //Possible total caption
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.ProjectName;
          }
          return "";
        },
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "WorkTypeUIDAndNameAndKey",
        columnName: "Column_WorkTypeID",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        headerName: me.$t("Column_WorkTypeID"),
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        resizable: true,
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkTypeID",
          "visible",
          true
        ),
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkTypeID",
          "width",
          160
        ),
        cellEditor: "LookupEditor",
        cellEditorParams: {
          caption: me.$t("SelectWorkType"),
          apiUrl: "WorkTypes",
          columnDefs: [
            {
              field: "WorkTypeID",
              headerName: me.$t("WorktypeId"),
              editable: false,
              resizable: true,
              width: 100,
            },
            {
              field: "WorkType",
              headerName: me.$t("Worktype"),
              editable: false,
              resizable: true,
              width: 180,
            },
            {
              field: "WorkCategory",
              headerName: me.$t("Worktype_Category"),
              editable: false,
              resizable: true,
              width: 180,
            },
          ],
          keyField: "WorkTypeUID",
          displayField: "WorkTypeAndId",
          searchExactField: "WorkTypeID",
          displayFormatDelegate: (data) => {
            return `${data["WorkTypeID"]} - ${data["WorkType"]}`;
          },
          writeBackDelegate: (value, selectedItem) => {
            value.WorkTypeID = selectedItem["WorkTypeID"];
            value.WorkType = selectedItem["WorkType"];
          },
          preFilterDelegate: (data, gridData) => {
            if (me.workTypeUIDs.includes(data.WorkTypeUID) == false)
              return false;
            if (gridData.ProjectUID == null && data.WorkCategoryID == 1)
              return false;
            if (gridData.ProjectUID != null && data.WorkCategoryID == 3)
              return false;
            return true;
          },
        },
        cellClassRules: me.cellClassRules,
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value; //Possible total caption
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.WorkTypeAndId;
          }
          return "";
        },
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "CostCenterJson",
        columnName: "Column_CostCenter",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        headerName: me.$t("Column_CostCenter"),
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        cellClassRules: me.cellClassRules,
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_CostCenter",
          "visible",
          false
        ),
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_CostCenter",
          "width",
          130
        ),
        cellEditor: "LookupEditor",
        cellEditorParams: {
          caption: me.$t("SelectCostCenter"),
          apiUrl: "CostCenters",
          columnDefs: [
            {
              field: "CostCenterID",
              headerName: me.$t("Number"),
              editable: false,
              resizable: true,
              width: 150,
            },
            {
              field: "CostCenterDescription",
              headerName: me.$t("Name"),
              editable: false,
              resizable: true,
              width: 300,
            },
          ],
          keyField: "CostCenterID",
          displayField: "CostCenterDisplayField",
          searchExactField: "CostCenterID",
          displayFormatDelegate: (data) => {
            return `${data["CostCenterID"]} - ${data["CostCenterDescription"]}`;
          },
          writeBackDelegate: (value, selectedItem) => {
            value.CostCenterID = selectedItem["CostCenterID"];
            value.CostCenterDescription = selectedItem["CostCenterDescription"];
          },
        },
        resizable: true,
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value; //Possible total caption
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.CostCenterDisplayField;
          }
          return "";
        },
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "WorkCategory",
        columnName: "Column_WorkCategory",
        headerName: me.$t("Column_WorkCategory"),
        isHeaderColumn: true,
        editable: false,
        cellClassRules: me.cellClassRules,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkCategory",
          "width",
          130
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkCategory",
          "visible",
          false
        ),
        resizable: true,
        cellRendererFramework: "readOnlyRenderer",
      },
      {
        field: "BookingText",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        columnName: "Column_BookingText",
        headerName: me.$t("Column_BookingText"),
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        cellClassRules: me.cellClassRules,
        minWidth: 180,
        maxWidth: 500,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_BookingText",
          "width",
          240
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_BookingText",
          "visible",
          true
        ),
        resizable: true,
        valueFormatter: (params) => {
          return params.value;
        },        
        cellRendererFramework: "bookingTextRenderer",
        cellEditor: "MultilineEditor",
        cellEditorParams: {
          caption: me.$t("Column_BookingText"),
        },
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "PhaseUIDAndName",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        columnName: "Column_PhaseName",
        headerName: me.$t("Column_PhaseName"),
        cellEditor: "ActiveTreeViewEditor",
        cellEditorParams: {
          loadUrl: "phases",
          keyField: "PhaseUID",
          parentKeyField: "ParentPhaseUID",
          displayField: "PhaseName",
        },
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value; //Possible total caption
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.displayValue;
          }
          return "";
        },
        cellClassRules: me.cellClassRules,
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_PhaseName",
          "width",
          150
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_PhaseName",
          "visible",
          false
        ),
        resizable: true,
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "StructureUIDAndName",
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        columnName: "Column_StructureName",
        headerName: me.$t("Column_StructureName"),
        cellClassRules: me.cellClassRules,
        cellEditor: "ActiveTreeViewEditor",
        cellEditorParams: {
          loadUrl: "structures",
          queryStringParam: "projectUID",
          queryStringField: "ProjectUID",
          keyField: "StructureUID",
          parentKeyField: "ParentStructureUID",
          displayField: "StructureName",
        },
        valueFormatter: (params) => {
          if (params.node.rowPinned) return params.value; //Possible total caption
          if (params.value) {
            const obj = JSON.parse(params.value);
            return obj.displayValue == null ? "" : obj.displayValue;
          }
          return "";
        },
        editable: (params) =>
          me.isHeaderColumnEditable(params, me.selectedUserUID) &&
          me.isWeekOrDayEditable(params),
        resizable: true,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_StructureName",
          "width",
          150
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_StructureName",
          "visible",
          false
        ),
        cellRendererSelector: me.getDefaultRenderer,
      },
    ];
    return headerColumnDefs;
  },

  createTotalColumnDef(columnInfoMap) {
    const me = this;
    const totalValue = {
      field: "TotalsValue",
      columnName: "Column_WorkHoursTotal",
      headerName: me.$t("Column_WorkHoursTotal"),
      editable: false,
      isTotalColumn: true,
      width: me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkHoursTotal",
        "width",
        100
      ),
      hide: !me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkHoursTotal",
        "visible",
        true
      ),
      resizable: true,
      type: "rightAligned",
      cellClassRules: me.cellClassRules,
      valueFormatter: (params) => {
        return this.formatWorkOrExpenses(params);
      },
      cellRendererFramework: "formattedBoldRenderer",
    };
    return totalValue;
  },
};
