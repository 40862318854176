<template>
    <v-row justify="center" class="pa-0 ma-0">
        <v-text-field
        dense
        class="px-4"
        ref="textField"
        :readonly="true"
        :value="displayText"       
        @keydown="onTextfieldKeyDown" 
        @change="onChange($event)"
        append-icon="mdi-file-tree"
        clearable
        @click:clear="onClear($event)"
        @click:append="onAppend($event)"
        />
        <v-dialog overlay-color="blue darken-3"
        v-model="dialog"
        scrollable
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <v-icon>mdi-file-tree</v-icon>
            <span class="ml-4">{{caption}}</span>
            <v-spacer></v-spacer>
              <v-menu
                  bottom
                  left
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="dialog = false"
                      v-bind="attrs"
                      v-on="on"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                  </template>
                </v-menu>
              </v-card-title>          
            <v-card-subtitle class="pb-0 mb-0">
                <v-text-field
                  class="mt-8"
                  ref="searchTextField"
                  v-model="search"
                  outlined
                  :label="$t('Search')"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
            </v-card-subtitle>
            <v-card-text style="height: 500px;" class="mt-0">
            <v-container>
                <v-treeview ref="vtreeView" open-all class="mt-0"
                    :item-key="keyField" 
                    :item-text="displayField" 
                    dense
                    activatable 
                    :search="search"
                    :filter="filter"
                    :active.sync="treeValues"
                    :items="items">
                </v-treeview>
            </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="standard"
                @click="onCancel()"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                @click="onOK()"
                width="100"
            >
                {{$t('OK')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>   
   
 </template>
<script>
import methods from './methods.js';

export default {
  name: 'treeViewEditor',
    data() {
        return {
            caption: 'Test',
            value: null,
            treeValues: [],
            search: null,
            displayValue: null,
            dialog: false,
            displayText: '',
            keyField: '',
            displayField: '',
            items: [],            
        };
    },
    methods: {
        ...methods
    },
    mounted() {        
        this.onMounted();
    },
    computed: {
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].includes(search)
          : undefined;
      },
  },    
};
</script>
