<template>
  <v-container fluid>
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height: 50px">
        <tr>
          <td>
            <span class="pr-4">{{ $t("TimeRecordingMonthView") }}</span>
          </td>
          <td>
            <span class="mx-4" style="font-size: 13px">{{ periodText }}</span>
          </td>
          <td>
            <v-select
              ref="selectUser"
              style="max-width: 140px; font-size: 12px"
              class="pa-0 mt-4 ml-6 mr-0"
              dense
              item-text="UserName"
              item-value="UserUID"
              :items="users"
              @change="onSelectedUserChanged($event)"
              v-model="selectedUserUID"
            ></v-select>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        :class="gridThemeName"
        ref="dataGrid"
        rowSelection="single"
        :overlayNoRowsTemplate="noRowsTemplate"
        :overlayLoadingTemplate="loadingTemplate"
        :columnDefs="columnDefs"
        :rowData="items"
        :pinnedBottomRowData="totalItems"
      ></ag-grid-vue>
    </p>
    <day-or-week-selection-dialog
      ref="dayOrWeekSelectionDialog"
      @onOK="onWeekdaySelectionChanged($event)"
    ></day-or-week-selection-dialog>
  </v-container>
</template>
<style lang="css">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
.highlight-for-deletion {
  background-color: #ff6f00 !important;
}
</style>
<script>
import { AgGridVue } from "ag-grid-vue";
import ReadOnlyRenderer from "@/renderers/readOnlyRenderer.js";
import TimeRenderer from "@/renderers/timeRenderer.js";
import WeekDayNameRenderer from "@/renderers/weekDayNameRenderer.js";
import DateRenderer from "@/renderers/dateRenderer.js";
import DayOrWeekSelectionDialog from "@/components/dialogs/DayOrWeekSelectionDialog/_Index.vue";

import moment from "moment";
import api from "@/api.js";
import apiExcel from "@/apiExcel.js";
import fileSaver from "file-saver";
import utils from "@/common/utils.js";

export default {
  name: "time-recording-month-list",

  props: {
    currentDate: Object, //Date but wrapped as as object of the moment lib
  },

  data: () => ({
    gridThemeName: "ag-theme-alpine-dark",
    items: [],
    totalItems: [],
    columnDefs: [],
    title: "",
    noRowsTemplate: null,
    loadingTemplate: null,
    periodText: "",
    users: [],
    selectedUserUID: null,
  }),

  methods: {
    initialize() {
      //Called from parent
      this.title = `${moment().format("DD.MM.YYYY")} (${this.$t("Today")})`;
      this.fillUsers();
      this.selectedUserUID = this.$user.UserUID;
      this.noRowsTemplate = this.$t("NoRowsFound");
      this.loadingTemplate = this.$t("ProcessingData");
      this.setGridTheme(this.$gus.data.darkMode);
      this.columnDefs = this.createColumnDefs();
      this.refresh();
    },

    getLocale() {
      return this.$gus.data.languageCode;
    },

    async fillUsers() {
      const me = this;
      if (utils.hasUserRole(me.$user, "Controller")) {
        me.users = this.$allUsers;
      } else if (utils.hasUserRole(me.$user, "Teamleader")) {
        me.users = this.$teamUsers;
      }
      me.users.push({ UserUID: me.$user.UserUID, UserName: me.$user.UserName });
    },

    async refresh() {
      const me = this;
      me.periodText = `${me.currentDate
        .locale(me.getLocale())
        .format("MMMM")} ${me.currentDate.format("YYYY")}`;

      let url = `TimeCard/Month?DayDate=${me.currentDate.format(
        "YYYY-MM-DD"
      )}&userUID=${this.selectedUserUID}`;
      let res = await api.get(url);
      res = await api.get(url);
      this.items = res.data.Records;
      this.totalItems = res.data.Totals;
      this.totalItems[0].DayDate = me.$t("Totals") + ":";
    },

    navigateDateBackward() {
      this.currentDate.subtract(1, "M").calendar();
      this.refresh();
    },

    navigateDateForward() {
      this.currentDate.add(1, "M").calendar();
      this.refresh();
    },

    navigateToDate() {
      this.$refs.dayOrWeekSelectionDialog.show(
        moment(this.currentDate).format("YYYY-MM-DD"),
        this.showOnlyOneDay
      );
    },

    onWeekdaySelectionChanged(dateValue) {
      this.currentDate = dateValue.set("date", 1);
      this.refresh();
    },

    async exportToExcel() {
      const me = this;
      const startOfMonth = moment(this.currentDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(this.currentDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      const response = await apiExcel.get(
        `excel/timecard?UserUIDs=${me.selectedUserUID}&PeriodStartDate=${startOfMonth}&PeriodLastDate=${endOfMonth}&Language=${this.$i18n.locale}`
      );
      const fileName = me.getFilenameFromHeader(
        response.headers,
        `Timerecording Month.xlsx`
      );
      fileSaver.saveAs(response.data, fileName);
    },

    //TODO: Move to utils
    getFilenameFromHeader(responseHeaders, defaultName) {
      let fileName = defaultName;
      let content_disposition = responseHeaders["content-disposition"];
      if (content_disposition && content_disposition.indexOf(";") > 0) {
        let tokens = content_disposition.split(";");
        if (tokens && tokens.length > 1) {
          let temp = tokens[1].trim();
          tokens = temp.split("=");
          if (tokens && tokens.length > 1) {
            fileName = tokens[1].trim();
          }
        }
      }
      return fileName;
    },

    setGridTheme(darkMode) {
      this.gridThemeName =
        darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
    },

    setGridLanguage() {
      this.updateColumns();
    },

    updateColumns() {
      const me = this;
      if (!me.columnDefs) return;
      me.columnDefs.forEach((columnDef) => {
        columnDef.headerName = this.$t(columnDef.columnName);
      });
      const dataGrid = me.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.gridOptions.api.setColumnDefs(this.columnDefs);
      }
    },

    createColumnDefs() {
      const me = this;
      let columnDefs = [];

      columnDefs.push(
        me.createColumnDef(
          "DayDate",
          "Date",
          me.$t("Date"),
          120,
          null,
          "dateRenderer"
        )
      );
      columnDefs.push(
        me.createColumnDef(
          "DayDate",
          "Weekday",
          me.$t("Weekday"),
          150,
          null,
          "weekDayNameRenderer",
          me.getLocale()
        )
      );

      columnDefs.push(
        me.createColumnDef(
          "FromTime",
          "From",
          me.$t("From"),
          80,
          null,
          "timeRenderer"
        )
      );
      columnDefs.push(
        me.createColumnDef(
          "ToTime",
          "To",
          me.$t("To"),
          80,
          null,
          "timeRenderer"
        )
      );

      columnDefs.push(
        me.createColumnDef(
          "Worktime_Duration",
          "Worktime",
          me.$t("Worktime"),
          120,
          "rightAligned"
        )
      );
      columnDefs.push(
        me.createColumnDef(
          "Breaktime_Duration",
          "Breaktime",
          me.$t("Breaktime"),
          120,
          "rightAligned"
        )
      );

      return columnDefs;
    },

    onSelectedUserChanged() {
      this.refresh();
    },

    createColumnDef(
      field,
      columnName,
      headerName,
      width,
      type,
      renderer,
      locale
    ) {
      const columnDef = {
        field: field,
        columnName: columnName,
        headerName: headerName,
        editable: false,
        width: width,
        resizable: true,
      };
      if (type) {
        columnDef.type = type;
      }
      if (renderer) {
        columnDef.cellRendererFramework = renderer;
      }
      if (locale) {
        columnDef.locale = locale;
      }
      return columnDef;
    },
  },

  computed: {
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 205 : 198;
      return (
        "width:100%;margin-top:10px;height:" +
        (parseInt(this.$vssHeight) - subtractHeight) +
        "px"
      );
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    weekDayNameRenderer: WeekDayNameRenderer,
    // eslint-disable-next-line vue/no-unused-components
    dateRenderer: DateRenderer,
    // eslint-disable-next-line vue/no-unused-components
    readOnlyRenderer: ReadOnlyRenderer,
    // eslint-disable-next-line vue/no-unused-components
    timeRenderer: TimeRenderer,
    // eslint-disable-next-line vue/no-unused-components
    DayOrWeekSelectionDialog,
  },
};
</script>