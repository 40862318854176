<template>
    <v-combobox dense
        class="px-4"
        :items="items"
        :item-value="keyField"
        :item-text="displayField"
        :value="value"
        @change="onChange($event)"
        @keydown="onKeyDown($event)"
        ref="combobox"
    >
    </v-combobox>
 </template>
<script>
import methods from './methods.js';

export default {
  name: 'comboBoxEditor',
    data() {
        return {
            keyField: null,
            displayField: null,
            items: [],
            value: null,
            tempKey: null,
            valuesMap: {},
            key: null
        };
    },
    methods: {
        ...methods
    },
    mounted() {        
        this.onMounted();
    }
};
</script>
