<template>
  <week-overview-list 
    ref="weekOverviewList"
    userSettingsKey="WeekOverview"
    :title="$t('WeekOverview')"
    >
  </week-overview-list>
</template>
<style lang="scss">
</style>
<script>
  import WeekOverviewList from '@/components/forms/WeekOverviewList/_Index.vue';

  export default {
    name: 'WeekOverview',

    data: () => ({
    }),


    methods: {

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevWeek");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextWeek");
      },

      getNavigateToText() {
        return this.$t("NavigateToWeek");
      },

      async navigateDateBackward() {
        this.$refs.weekOverviewList.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.weekOverviewList.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.weekOverviewList.navigateToDate();
      },
      async refresh() {
        this.$refs.weekOverviewList.refresh();
      },
      async loadUserSettings() {
        this.$refs.weekOverviewList.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.weekOverviewList.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.weekOverviewList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.weekOverviewList.setGridLanguage();
      },
      async exportToExcel() {
        this.$refs.weekOverviewList.exportToExcel();
      }
    },
    
    components: {
      WeekOverviewList
    },

  }
  
</script>
