var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"Container"}},[_c('v-row',[_c('table',{staticClass:"px-4 mt-0 pt-0",staticStyle:{"min-height":"50px"}},[_c('tr',[_c('td',[_c('span',{staticClass:"pr-4"},[_vm._v(_vm._s(_vm.title))])]),_c('td',[_c('span',{staticClass:"mx-4",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.periodText))])]),_c('td',[_c('span',{staticClass:"ml-4 mr-2",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t("Status"))+":")]),_c('span',{staticStyle:{"color":"limegreen","font-size":"13px"}},[_vm._v(_vm._s(_vm.statusText))])]),(_vm.hasRoles(['Teamleader', 'Controller']))?_c('td',[_c('v-select',{ref:"selectUser",staticClass:"pa-0 mt-4 ml-6 mr-0",staticStyle:{"max-width":"200px","font-size":"12px"},attrs:{"dense":"","item-text":"UserName","item-value":"UserUID","items":_vm.users},on:{"change":function($event){return _vm.onSelectedUserChanged($event)}},model:{value:(_vm.selectedUserUID),callback:function ($$v) {_vm.selectedUserUID=$$v},expression:"selectedUserUID"}})],1):_vm._e(),(_vm.canRelease())?_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6",style:(_vm.getButtonStyle()),attrs:{"elevation":"2","small":"","text":"","color":"primary"},on:{"click":_vm.onReleaseForControlClick}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-0 pl-0 pr-2",attrs:{"small":"","dark":""}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.$t("Release"))+" ")],1)]}}],null,false,3010820476)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getReleaseTooltipKey())))])])],1):_vm._e(),(_vm.canReturn())?_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-0",style:(_vm.getButtonStyle()),attrs:{"elevation":"2","small":"","text":"","color":"primary"},on:{"click":_vm.onReturnToProcessingClick}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-0 mr-0 pl-0 pr-2",attrs:{"small":"","dark":""}},[_vm._v("mdi-arrow-left-circle")]),_vm._v(" "+_vm._s(_vm.$t("Return"))+" ")],1)]}}],null,false,3223896317)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getReturnTooltipKey())))])])],1):_vm._e(),(_vm.canBook())?_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-4",style:(_vm.getButtonStyle()),attrs:{"elevation":"2","small":"","text":"","color":"primary"},on:{"click":_vm.onReleaseForBookingClick}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-0 pl-0 pr-2",attrs:{"small":"","dark":""}},[_vm._v("mdi-check-underline-circle")]),_vm._v(" "+_vm._s(_vm.$t("Book"))+" ")],1)]}}],null,false,1580273723)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getBookingTooltipKey())))])])],1):_vm._e()])])]),_c('p',{style:(_vm.getStyle)},[_c('ag-grid-vue',{ref:"dataGrid",class:_vm.gridThemeName,staticStyle:{"width":"100%","height":"100%"},attrs:{"rowClassRules":_vm.rowClassRules,"defaultColumnDefs":_vm.defaultColumnDefs,"rowSelection":"single","columnDefs":_vm.columnDefs,"rowData":_vm.data.Rows,"pinnedBottomRowData":_vm.totalsRowData,"overlayNoRowsTemplate":_vm.noRowsTemplate,"overlayLoadingTemplate":_vm.loadingTemplate},on:{"cellFocused":function($event){return _vm.onCellFocused($event)},"cellValueChanged":function($event){return _vm.onGridCellValueChanged($event)}},nativeOn:{"keydown":function($event){return _vm.onKeydown($event)}}}),_c('transition',{attrs:{"name":"bounce"}},[(_vm.displayAlert)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"orange darken-4","icon":"mdi-exclamation","elevation":"2","dismissible":"","transition":"scale-transition"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]):_vm._e()],1)],1),_c('column-selection-dialog',{ref:"columnSelectionDialog",on:{"columnSelectionChanged":function($event){return _vm.onColumnSelectionChanged($event)},"columnSelectionOK":function($event){return _vm.onColumnSelectionOK($event)}}}),_c('day-or-week-selection-dialog',{ref:"dayOrWeekSelectionDialog",on:{"onOK":function($event){return _vm.onWeekdaySelectionChanged($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }