<template>
  <v-list nav dense class="px-5">
    <v-list-item-group>
      <template v-if="canInputForms">
        <v-subheader>{{ $t("InputForms") }}</v-subheader>
        <v-list-item link to="/app/employeeDailyReport" v-if="canInputForms">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-view-day</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("EmployeeDailyReport") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/employeeWeeklyReport">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-view-week</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("EmployeeWeeklyReport")
          }}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="hasRoles('Controller,Teamleader')"></v-divider>
        <v-list-item
          v-if="hasRoles('Typist,Controller,Teamleader')"
          link
          to="/app/projectDailyReport"
        >
          <v-list-item-icon class="me-3">
            <v-icon>mdi-view-day</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("ProjectDailyReport") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasRoles('Typist,Controller,Teamleader')"
          link
          to="/app/projectWeeklyReport"
        >
          <v-list-item-icon class="me-3">
            <v-icon>mdi-view-week</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("ProjectWeeklyReport") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/constructionSiteReport">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-view-week</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("ConstructionSiteReport")
          }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-if="canTimeEntries">
        <v-subheader>{{ $t("TimeRecording") }}</v-subheader>
        <template v-if="canOwnTimeEntries">
          <v-list-item link to="/app/timeRecording">
            <v-list-item-icon class="me-3">
              <v-icon>mdi-account-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("MyTimeRecording") }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item link to="/app/timeRecordingCorrection">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-playlist-minus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("TimeRecordingCorrection")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/timeRecordingMonthView">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-file-table-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("TimeRecordingMonthView")
          }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-if="canStatusControl">
        <v-subheader>{{ $t("StatusControl") }}</v-subheader>
        <v-list-item link to="/app/weekOverview">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("WeekOverview") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/monthOverview">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("MonthOverview") }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-if="hasRoles('Administrator')">
        <v-subheader>{{ $t("Administration") }}</v-subheader>
        <v-list-item link to="/app/manageEmployees">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("ManageEmployees") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/holidays">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-calendar-multiselect</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("PublicHolidays") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/globalWorkTypes">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("GlobalWorkTypes") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/app/clientSettings">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("GlobalSettings") }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<style scoped>
tr:hover {
  background-color: transparent !important;
}
</style>

<script>
import utils from "@/common/utils.js";
export default {
  name: "sideMenu",
  methods: {
    hasRoles(roleNamesCsv) {
      return utils.hasUserRolesCsv(this.$user, roleNamesCsv);
    },
    hasWebDevice() {
      return this.$user.WebDeviceID != null && this.$user.WebDeviceID != "";
    },
  },
  mounted() {},
  computed: {
    canInputForms() {
      return this.hasRoles("Typist,Controller,Teamleader");
    },
    canOwnTimeEntries() {
      return (
        this.hasRoles("Typist,Controller,Teamleader") && this.hasWebDevice()
      );
    },
    canTimeEntries() {
      return (
        this.hasRoles("Controller,Teamleader") || (this.hasRoles("Typist") && this.hasWebDevice())
      );
    },
    canStatusControl() {
      return this.hasRoles("Controller,Teamleader");
    },
  },
};
</script>
