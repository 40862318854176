import api from '@/api.js';
import utils from '@/common/utils.js';
export default {

    async loadData() {
        const me = this;

        me.loadingTemplate = this.$t('ProcessingData');
        me.$refs.dataGrid.gridOptions.api.showLoadingOverlay();

        let res;
        let url = `constructionsite?userUID=${me.selectedUserUID}&mondayDate=${me.$dates.mondayDate.format('YYYY-MM-DD')}`;
        if (me.project != null) url += `&projectUID=${me.project.ProjectUID}`;
        if (me.structure != null) url += `&structureUID=${me.structure.StructureUID}`;
        res = await api.get(url);
        me.statusTextCode = `Status${res.data.WeekState.Status}`;
        me.updateHeaderTexts();

        me.$refs.dataGrid.gridOptions.api.hideOverlay();
        // me.data.WeekState = res.data.WeekState;
        me.data = res.data;

        if (me.data == null) {
            me.data = {
                MondayDate: me.$dates.mondayDate.format('YYYY-MM-DD'),
                UserUID: me.selectedUserUID,
                Rows: [],
                WeekState: {},
                WorkHourSuffix: '',
                ExpenseSuffix: '',
            }
        }
        if (me.data.Rows == null) {
            me.data.Rows = [];
        }

        me.totalsRowData.forEach(totalRow => {
            totalRow.WorkHourSuffix = me.data.WorkHourSuffix;
            totalRow.ExpenseSuffix = me.data.ExpenseSuffix;
        });           

        let rowID = 1;
        me.data.Rows.forEach(row => {
            row.RowID = rowID++;

            if (row.WorkTypeUID) {
                row.WorkTypeDisplayField = `${row.WorkTypeID} - ${row.WorkType}`;
            }

            row.MondayValue = this.getWeekdayValue(row, 'Monday');
            row.TuesdayValue = this.getWeekdayValue(row, 'Tuesday');
            row.WednesdayValue = this.getWeekdayValue(row, 'Wednesday');
            row.ThursdayValue = this.getWeekdayValue(row, 'Thursday');
            row.FridayValue = this.getWeekdayValue(row, 'Friday');
            row.SaturdayValue = this.getWeekdayValue(row, 'Saturday');
            row.SundayValue = this.getWeekdayValue(row, 'Sunday');

            row.MondayValue_Old = this.getWeekdayOldValue(row, 'Monday');
            row.TuesdayValue_Old = this.getWeekdayOldValue(row, 'Tuesday');
            row.WednesdayValue_Old = this.getWeekdayOldValue(row, 'Wednesday');
            row.ThursdayValue_Old = this.getWeekdayOldValue(row, 'Thursday');
            row.FridayValue_Old = this.getWeekdayOldValue(row, 'Friday');
            row.SaturdayValue_Old = this.getWeekdayOldValue(row, 'Saturday');
            row.SundayValue_Old = this.getWeekdayOldValue(row, 'Sunday');

            row.OldKeyValues = {};
            row.OldKeyValues.WorkTypeUID = row.WorkTypeUID;
            row.OldKeyValues.ProjectUID = row.ProjectUID;
            row.OldKeyValues.PhaseUID = row.PhaseUID;
            row.OldKeyValues.StructureUID = row.StructureUID;
            row.OldKeyValues.CostCenterID = row.CostCenterID;
            row.OldKeyValues.BookingText = row.BookingText;
            row.OldKeyValues.WorkAttributes = row.WorkAttributes;

            row.WorkHourSuffix = me.data.WorkHourSuffix;
            row.ExpenseSuffix = me.data.ExpenseSuffix;

            if (row.CostCenterID)
                row["CostCenterIDAndDescription"] = JSON.stringify({
                    CostCenterID: row.CostCenterID,
                    CostCenterDescription: row.CostCenterDescription,
                    CostCenter: `${row.CostCenterID} - ${row.CostCenterDescription}`
                })

        });

    },

    addEmptyRows(rows) {
        const me = this;
        const targetRowCount = 14;

        for (let i = rows.length; i < targetRowCount; i++) {
            me.data.Rows.splice(i, 0, me.createNewRow());
        }

    },

    async loadHeaderData() {
        const me = this;
        let projectUID = me.projectUID;
        
        if (!projectUID)
        {
            const res = await me.getUserSetting(me.selectedUserUID, me.projectUserSettingsKey);
            projectUID = res.data.SettingValue;
        }
        if (projectUID) {
            const res = await api.get(`Project?projectUID=${projectUID}`);
            me.project = res.data;
            me.projectSearchText = me.project.ProjectName;
            const res2 = await api.get(`structures?projectUID=${projectUID}`);
            me.structuresFlat = res2.data;
            const res3 = await me.getUserSetting(me.selectedUserUID, me.structureUserSettingsKey);
            const structureUID = res3.data.SettingValue;
            if (structureUID && (me.$refs.structureLookupBox)) {
                me.$refs.structureLookupBox.reset();    
                if (structureUID && structureUID != "")
                    me.$refs.structureLookupBox.setValue(structureUID);
            }
        }
    },


    getWeekdayValue(row, weekday) {
        if (!row) return null;
        if (!row.Values) return null;
        const x = row.Values.find(o => o.DayOfWeek === weekday);
        if (!x) return null;
        return x.Value;
    },

    getWeekdayOldValue(row, weekday) {
        if (!row) return null;
        if (!row.Values) return null;
        const x = row.Values.find(o => o.DayOfWeek === weekday);
        if (!x) return null;
        return x.oldValue;
    },

    async loadReferenceData() {
        const me = this;
        me.totalsRowData = [{
                ProjectUID: null,
                WorkTypeUID: '',
                WorkCategoryID: null,
                WorkCategory: '',
                PhaseUID: null,
                StructureUID: null,
                CostCenterID: null,
                BookingText: '',
                MondayValue: 0,
                TuesdayValue: 0,
                WednesdayValue: 0,
                ThursdayValue: 0,
                FridayValue: 0,
                SaturdayValue: 0,
                SundayValue: 0,
                TotalsValue: 0,
                WorkHourSuffix: "",
                ExpenseSuffix: "",                
            },
            {
                ProjectUID: null,
                WorkTypeUID: '',
                WorkCategoryID: null,
                WorkCategory: '',
                PhaseUID: null,
                StructureUID: null,
                CostCenterID: null,
                BookingText: '',
                MondayValue: 0,
                TuesdayValue: 0,
                WednesdayValue: 0,
                ThursdayValue: 0,
                FridayValue: 0,
                SaturdayValue: 0,
                SundayValue: 0,
                TotalsValue: 0,
                WorkHourSuffix: "",
                ExpenseSuffix: "",                
            }
        ];

        if (utils.hasUserRole(me.$user, "Controller")) {
            me.users = this.$allUsers;
        } else if (utils.hasUserRole(me.$user, "Teamleader")) {
            me.users = this.$teamUsers;
        }
        me.users.push({ UserUID: me.$user.UserUID, UserName: me.$user.UserName });
        
        //TODO: Get previously SelectedUser from User-Settings
        if (!this.selectedUserUID)
            this.selectedUserUID = me.$user.UserUID;

        let res = null;
        if (me.projectUID) {
            res = await api.get(`Project?projectUID=${me.projectUID}`);
            // me.project = res.data;
        }

        res = await api.get("workTypes");
        me.workTypes = res.data;
        me.workTypes.forEach(workType => {
            me.workTypeLookup[workType.WorkTypeUID] = workType;
        });

        res = await api.get('workCategories');
        me.workCategories = res.data;
        me.workCategories.forEach(workCategory => {
            me.workCategoryLookup[workCategory.WorkCategoryID] = workCategory;
        });

        res = await api.get("costCenters");
        me.costCenters = res.data;
        me.costCenters.forEach(costCenter => {
            me.costCenterLookup[costCenter.CostCenterID] = costCenter;
        });
    },


}