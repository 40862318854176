<template>
  <v-container fluid>
    <v-data-table
      dense
      v-model="selectedRoles"
      :headers="headers"
      :items="roles"
      item-key="RoleID"
      display-text="RoleName"
      show-select
      @item-selected="onItemSelected($event)"
      @click:row="onRowClick($event)"
      class="elevation-1"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
    </v-data-table>
  </v-container>
</template>
<style scoped>
.v-data-table__selected {
  background-color: transparent !important;
}
tr:hover {
  background-color: transparent !important;
}
</style>
<script>
import api from "@/api.js";
import utils from "@/common/utils.js";

export default {
  name: "employee-workTypes",

  props: {
    userUID: String,
  },

  data: () => ({
    itemsPerPage: -1,
    roles: [],
    originalSelectedRoles: [],
    selectedRoles: [],
    headers: [],
  }),

  methods: {
    addNewRecord_isInvisible() {
      return true;
    },

    async refresh() {
      const me = this;
      const response = await api.get(`userRoles?UserUID=${me.userUID}`);
      if (response.data && response.data.Roles) {
        me.selectedRoles = response.data.Roles;
      } else {
        me.selectedRoles = [];
      }
    },

    async save() {
      const me = this;
      if (!me.selectedRoles) return;

      await api.post("UserRoles", {
        Roles: me.selectedRoles,
        UserUID: me.userUID,
      });
      me.originalSelectedRoles = utils.deepClone(me.selectedRoles);

      this.$emit("IsDirtyChanged", false);
    },

    onRowClick(e) {
      const x = this.selectedRoles.filter((x) => x.RoleID === e.RoleID);
      if (x.length == 0) this.selectedRoles.push(e);
      else
        this.selectedRoles = this.selectedRoles.filter(
          (x) => x.RoleID !== e.RoleID
        );
      this.onSelectedChanged({ item: e, value: e });
    },

    onItemSelected(e) {
      this.onSelectedChanged(e);
    },

    onSelectedChanged() {
      this.$emit("IsDirtyChanged", true);
    },

    createHeaders() {
      const headers = [
        {
          name: "Role",
          text: this.$t("Role"),
          align: "start",
          sortable: false,
          value: "RoleName",
        },
      ];
      return headers;
    },

    setGridLanguage() {
      this.headers.forEach((header) => {
        header.text = this.$t(header.name);
      });
    },

    createRoles() {
      const me = this;
      const roles = [
        {
          RoleID: 1,
          RoleName: me.$t("Administrator"),
        },
        {
          RoleID: 2,
          RoleName: me.$t("Controller"),
        },
        {
          RoleID: 3,
          RoleName: me.$t("Teamleader"),
        },
        {
          RoleID: 4,
          RoleName: me.$t("Typist"),
        },
      ];
      return roles;
    },
  },
  async created() {
    const me = this;
    me.headers = me.createHeaders();
    me.roles = me.createRoles();

    this.refresh();
  },

  updated() {
    this.$emit("SubComponentChanged", this);
  },
};
</script>
