<template>
  <v-form ref="form" lazy-validation v-if="loaded">
    <v-row>
      <v-col>
        <v-card elevation="2" class="px-8 py-0 mt-4">
          <table class="pa-0 ma-0">
            <tr>
              <td class="pr-4">{{ $t('ShowWorkHourUnits') }}:</td>
              <td>
                <v-checkbox
                  v-model="user.ShowWorkHourUnits"
                  @keydown="onKeydown($event)"
                  @change="onChange"
                ></v-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pr-4">{{ $t('MaxDayWorkHours') }}:</td>
              <td class="pt-2">
                <v-text-field
                  v-model="user.MaxDayWorkHours"
                  type="number"
                  dense
                  required
                  @keydown="onKeydown($event)"
                  style="max-width: 80px;"
                ></v-text-field>
              </td>
            </tr>
          </table>
        </v-card>
        <v-card elevation="2" class="px-8 py-0 mt-4">
          <table class="pa-0 ma-0">
            <tr>
              <td class="pr-4">{{ $t('TargetHours') }}:</td>
              <td class="pt-2">
                <v-text-field
                  v-model="user.TargetHours"
                  dense
                  required
                  @keydown="onKeydown($event)"
                  style="max-width: 80px;"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="pr-4">{{ $t('UseOneReportSheet') }}:</td>
              <td>
                <v-checkbox
                  v-model="user.UseOneReportSheet"
                  @keydown="onKeydown($event)"
                  @change="onChange"
                ></v-checkbox>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-8 py-0 mt-4">
          <table>
            <tr>
              <td class="pr-4">{{ $t('ShowExpenseUnits') }}:</td>
              <td>
                <v-checkbox
                  v-model="user.ShowExpenseUnits"
                  @keydown="onKeydown($event)"
                  @change="onChange"
                ></v-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pr-4">{{ $t('MaxDayExpenses') }}:</td>
              <td class="pt-2">
                <v-text-field
                  v-model="user.MaxDayExpenses"
                  dense
                  required
                  @keydown="onKeydown($event)"
                  style="max-width: 150px;"
                ></v-text-field>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <v-sheet class="pa-3" v-else>
    <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
  </v-sheet>
</template>
<style lang="scss"></style>
<script>
import api from '@/api.js'
import utils from '@/common/utils.js'

export default {
  name: 'employee-settings',

  props: {
    userUID: String,
  },

  data: () => ({
    loaded: false,
    user: {},
    originalUser: null,
  }),

  async created() {
    this.refresh()
  },

  updated() {
    this.$emit('SubComponentChanged', this)
  },

  methods: {
    addNewRecord_isInvisible() {
      return true
    },

    async refresh() {
      const me = this
      me.loaded = false
      const res = await api.get(`user?userUID=${me.userUID}`)
      me.user = res.data
      me.originalUser = utils.deepClone(me.user)
      me.loaded = true
      setTimeout(() => {}, 100)
    },

    onChange() {
      this.$emit('IsDirtyChanged', true)
    },
    onKeydown(ea) {
      const me = this
      if (ea.ctrlKey == true && ea.code == 'KeyS') {
        ea.stopPropagation()
        ea.preventDefault()
        ea.cancelBubble = true
        me.save()
        return false
      }
      if (ea.ctrlKey == true && ea.key == 'z') {
        ea.stopPropagation()
        ea.preventDefault()
        ea.cancelBubble = true
        me.undo()
        return false
      }
      this.$emit('IsDirtyChanged', true)
    },

    undo() {
      const me = this
      me.user = this.originalUser
      me.originalUser = utils.deepClone(me.user)

      me.$emit('IsDirtyChanged', false)
    },

    async save() {
      const me = this
      if (!me.user) return

      await api.post('user', me.user)
      me.originalUser = utils.deepClone(me.user)

      this.$emit('IsDirtyChanged', false)
    },
  },
}
</script>
