<template>
    <v-container fluid>
      <v-data-table dense
        v-model="teamMembers"
        :headers="headers"
        :items="users"
        item-key="UserUID"
        display-text="UserName"
        show-select
        @item-selected="onItemSelected($event)"
        @click:row="onRowClick($event)"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        hide-default-footer        
      >
      </v-data-table>
    </v-container>
</template>
<style lang="scss">
</style>
<script>
import api from '@/api.js';
import utils from '@/common/utils.js';

  export default {
    
    name: 'employee-team',

    props: {
      userUID: String,
    },    
    
    data: () => ({
      itemsPerPage: -1,
      users: [],
      teamMembers: [],
      originalTeamMembers: [],
      headers: []
    }),

    methods: {

      undo() {
        const me = this;
        me.teamMembers = me.originalTeamMembers;
        me.originalTeamMembers = utils.deepClone(me.teamMembers);
        this.$emit("IsDirtyChanged", false);
      },

      addNewRecord_isInvisible() {
        return true;
      },

      async refresh() {
        const me = this;
        const res = await api.get('users');
        me.users = res.data.filter(x => x.UserUID.toLowerCase() !== me.userUID.toLowerCase());        

        const response = await api.get(`userTeamMember?UserUID=${me.userUID}`);      
        me.teamMembers = response.data.map(u => ({ UserUID: u.MemberUserUID.toLowerCase(), UserName: u.UserName }));
        me.originalTeamMembers = utils.deepClone(me.teamMembers);
      },

      async save() {
        const me = this;
        if (!me.teamMembers) return;

        const o = {
          LeaderUserUID: me.userUID,
          Members: me.teamMembers.map(x => x.UserUID)
        };
        await api.post('UserTeamMember', o);
        me.originalTeamMembers = utils.deepClone(me.teamMembers);

        this.$emit("IsDirtyChanged", false);
      },


      onRowClick(e) {
        const x = this.teamMembers.filter(x => x.UserUID.toLowerCase() === e.UserUID.toLowerCase());
        if (x.length == 0)
          this.teamMembers.push(e);
        else
          this.teamMembers = this.teamMembers.filter(x => x.UserUID.toLowerCase() !== e.UserUID.toLowerCase());
        this.onSelectedChanged({item: e, value: e});
      },

      onItemSelected(e) {
        this.onSelectedChanged(e);
      },

      onSelectedChanged() {
        this.$emit("IsDirtyChanged", true);
      },

      setGridLanguage() {
        this.headers.forEach(header => {
          header.text = this.$t(header.name);
        });
      },          

      createHeaders() {
        const headers = [
          {
            name: "UserName",
            text: this.$t("UserName"),
            align: 'start',
            sortable: true,
            value: 'UserName',
          },
          {
            name: "Roles",
            text: this.$t('Roles'),
            align: 'start',
            sortable: true,
            value: 'Roles',
          },
          {
            name: "EmployeeType",
            text: this.$t('EmployeeType'),
            align: 'start',
            sortable: true,
            value: 'EmployeeType',
          }
        ];
        return headers;
      },
      
    },
    async created() {
      const me = this;
      me.headers = me.createHeaders();

      me.refresh();
    },

    async beforeMount() {
    },

    mounted()
    {
      
    },

    updated() {
      this.$emit("SubComponentChanged", this);
    }

  }
</script>
