<template>
  <v-container fluid id="Container">
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height: 50px">
        <tr>
          <td>
            <span class="pr-4">{{ title }}</span>
          </td>
          <td>
            <span class="mx-4" style="font-size: 13px">{{ periodText }}</span>
          </td>
          <td>
            <span class="ml-4 mr-2" style="font-size: 13px"
              >{{ $t("Status") }}:</span
            ><span style="color: limegreen; font-size: 13px">{{
              statusText
            }}</span>
          </td>
          <td v-if="hasRoles(['Teamleader', 'Controller'])">
            <v-select
              ref="selectUser"
              style="max-width: 200px; font-size: 12px"
              class="pa-0 mt-4 ml-6 mr-0"
              dense
              item-text="UserName"
              item-value="UserUID"
              :items="users"
              @change="onSelectedUserChanged($event)"
              v-model="selectedUserUID"
            >
            </v-select>
          </td>
          <td v-if="canRelease()">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-6"
                  :style="getButtonStyle()"
                  elevation="2"
                  @click="onReleaseForControlClick"
                  small
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="ml-0 pl-0 pr-2" small dark
                    >mdi-check-circle</v-icon
                  >
                  {{ $t("Release") }}
                </v-btn>
              </template>
              <span>{{ $t(getReleaseTooltipKey()) }}</span>
            </v-tooltip>
          </td>
          <td v-if="canReturn()">
            <v-tooltip bottom color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mr-0"
                  :style="getButtonStyle()"
                  elevation="2"
                  @click="onReturnToProcessingClick"
                  small
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="ml-0 mr-0 pl-0 pr-2" small dark
                    >mdi-arrow-left-circle</v-icon
                  >
                  {{ $t("Return") }}
                </v-btn>
              </template>
              <span>{{ $t(getReturnTooltipKey()) }}</span>
            </v-tooltip>
          </td>
          <td v-if="canBook()">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mr-4"
                  :style="getButtonStyle()"
                  elevation="2"
                  @click="onReleaseForBookingClick"
                  small
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="ml-0 pl-0 pr-2" small dark
                    >mdi-check-underline-circle</v-icon
                  >
                  {{ $t("Book") }}
                </v-btn>
              </template>
              <span>{{ $t(getBookingTooltipKey()) }}</span>
            </v-tooltip>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        :class="gridThemeName"
        ref="dataGrid"
        :rowClassRules="rowClassRules"
        :defaultColumnDefs="defaultColumnDefs"
        rowSelection="single"
        :columnDefs="columnDefs"
        :rowData="data.Rows"
        :pinnedBottomRowData="totalsRowData"
        :overlayNoRowsTemplate="noRowsTemplate"
        :overlayLoadingTemplate="loadingTemplate"
        @keydown.native="onKeydown($event)"
        @cellFocused="onCellFocused($event)"
        @cellValueChanged="onGridCellValueChanged($event)"
      >
      </ag-grid-vue>
      <transition name="bounce">
        <v-alert
          type="orange darken-4"
          icon="mdi-exclamation"
          elevation="2"
          dismissible
          class="mt-4"
          transition="scale-transition"
          v-if="displayAlert"
        >
          {{ alertText }}
        </v-alert>
      </transition>
    </p>
    <column-selection-dialog
      ref="columnSelectionDialog"
      @columnSelectionChanged="onColumnSelectionChanged($event)"
      @columnSelectionOK="onColumnSelectionOK($event)"
    >
    </column-selection-dialog>
    <day-or-week-selection-dialog
      ref="dayOrWeekSelectionDialog"
      @onOK="onWeekdaySelectionChanged($event)"
    ></day-or-week-selection-dialog>
  </v-container>
</template>
<style lang="css">
@import "../../../common/dataGridStyles.css";
</style>
<script>
import DayOrWeekSelectionDialog from "@/components/dialogs/DayOrWeekSelectionDialog/_Index.vue";
import ColumnSelectionDialog from "@/components/dialogs/ColumnSelectionDialog/_Index.vue";
import ComboBoxEditor from "@/components/editors/ComboBoxEditor/_Index.vue";
import TreeViewEditor from "@/components/editors/TreeViewEditor/_Index.vue";
import ProjectLookupEditor from "@/components/editors/ProjectLookupEditor/_Index.vue";
import LookupEditor from "@/components/editors/LookupEditor/_Index.vue";
import ActiveTreeViewEditor from "@/components/editors/ActiveTreeViewEditor/_Index.vue";
import MultilineEditor from "@/components/editors/MultilineEditor/_Index.vue";
import WorkOrExpenseEditor from "@/components/editors/WorkOrExpenseEditor/_Index.vue";
import ReadOnlyRenderer from "@/renderers/readOnlyRenderer.js";
import BoldRenderer from "@/renderers/boldRenderer.js";
import ValueRenderer from "@/renderers/valueRenderer.vue";
import FormattedBoldRenderer from "@/renderers/formattedBoldRenderer.js";
import BookingTextRenderer from '@/renderers/bookingTextRenderer.vue';
import actions from "./actions.js";
import dataGridInit from "./dataGridInit.js";
import dataGridUpdate from "./dataGridUpdate.js";
import eventHandlers from "./eventHandlers.js";
import helpers from "@/common/helpers.js";
import loadData from "./loadData.js";
import { AgGridVue } from "ag-grid-vue";
import utils from "@/common/utils.js";
import viewHelper from "../../../common/viewHelpers";

export default {
  name: "EmployeeReport",

  props: {
    title: String,
    userSettingsKey: String,
    showOnlyOneDay: Boolean,
    user: Object,
  },

  data: () => ({
    paramUserUID: null,
    showDividers: true,
    gridThemeName: "ag-theme-alpine-dark",
    displayAlert: false,
    defaultColumnDefs: null,
    columnDefs: null,
    columnSettings: {
      firstField: "", //will be updated in updateColumns
      headerColumnCount: 2, //will be updated in updateColumns
      columnInfos: [],
    },
    data: { Items: [], WeekState: { Status: null, StatusToDate: null } },
    workTypes: [],
    workTypeLookup: {},
    workCategories: [],
    workCategoryLookup: {},
    costCenters: [],
    costCenterLookup: {},
    totalsRowData: [],
    totalWorkhoursCaption: null,
    totalExpensesCaption: null,
    themeName: "",
    rowClassRules: null,
    noRowsTemplate: null,
    loadingTemplate: null,
    weekStartDateFormatted: null,
    weekEndDateFormatted: null,
    undoStack: [],
    settings: {},
    periodText: "",
    users: [],
    selectedUserUID: null,
    statusText: "",
    showRelease: false,
    statusTextCode: "",
    workTypeUIDs: [],
  }),

  async created() {},

  beforeMount() {
    this.initialize();
  },

  mounted() {
    this.setGridTheme(this.$gus.data.darkMode);
  },

  methods: {
    ...helpers,
    ...eventHandlers,
    ...loadData,
    ...dataGridInit,
    ...actions,
    ...dataGridUpdate,
    ...utils,
    ...viewHelper,
  },

  computed: {
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 265 : 195;
      return (
        "width:100%;margin-top:10px;height:" +
        (parseInt(this.$vssHeight) - subtractHeight) +
        "px"
      );
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    readOnlyRenderer: ReadOnlyRenderer,
    // eslint-disable-next-line vue/no-unused-components
    valueRenderer: ValueRenderer,
    // eslint-disable-next-line vue/no-unused-components
    boldRenderer: BoldRenderer,
    // eslint-disable-next-line vue/no-unused-components
    formattedBoldRenderer: FormattedBoldRenderer,
    // eslint-disable-next-line vue/no-unused-components
    bookingTextRenderer: BookingTextRenderer,    
    // eslint-disable-next-line vue/no-unused-components
    ComboBoxEditor,
    // eslint-disable-next-line vue/no-unused-components
    TreeViewEditor,
    // eslint-disable-next-line vue/no-unused-components
    ProjectLookupEditor,
    // eslint-disable-next-line vue/no-unused-components
    LookupEditor,
    // eslint-disable-next-line vue/no-unused-components
    ActiveTreeViewEditor,
    // eslint-disable-next-line vue/no-unused-components
    WorkOrExpenseEditor,
    // eslint-disable-next-line vue/no-unused-components
    MultilineEditor,
    ColumnSelectionDialog,
    // eslint-disable-next-line vue/no-unused-components
    DayOrWeekSelectionDialog,
  },
};
</script>
