export default {

    onRowClicked(e) {
        this.$router.push(`editEmployee/${e.data.UserUID}`);
    },

    async onColumnSelectionChanged(columnSettings) {
        this.columnSettings = columnSettings;
        this.updateColumnsBySettings();
    },

}