<template>
  <v-container fluid>
    <v-data-table
      dense
      v-if="loaded"
      :headers="headers"
      :items="workTypes"
      item-key="WorkTypeUID"
      display-text="WorkType"
      class="elevation-1"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:item.IsUserSetting="{ item }">
        <div class="ma-0 px-0 pb-0 pt-0">
          <v-checkbox
            class="px-0 py-0 pt-0 ma-0"
            v-if="item.IsClientSetting"
            dense
            :disabled="item.IsClientSetting"
            v-model="item.IsClientSetting"
          >
          </v-checkbox>
          <v-checkbox
            v-else
            class="px-0 py-0 pt-0 ma-0"
            dense
            :disabled="item.IsClientSetting"
            @change="onCheckChange()"
            v-model="item.IsUserSetting"
          >
          </v-checkbox>
        </div>
      </template>
    </v-data-table>
    <v-sheet class="pa-3" v-else>
      <v-skeleton-loader class="mx-auto"> </v-skeleton-loader>
    </v-sheet>
    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("CopyWorkTypesFromOtherUser") }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 130px">
          <v-container class="my-2 px-0">
            <table>
              <tr>
                <td>{{ $t("CopyFromEmployee") }}:</td>
                <td>
                  <v-select
                    ref="selectUser"
                    style="max-width: 400px; font-size: 12px"
                    class="pa-0 mt-4 ml-6 mr-0"
                    dense
                    item-text="UserName"
                    item-value="UserUID"
                    :items="users"
                    clearable
                    v-model="selectedUserUID"
                  ></v-select>
                </td>
              </tr>
            </table>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
</style>
<script>
import api from "@/api.js";

export default {
  name: "employee-workTypes",

  props: {
    userUID: String,
  },

  data: () => ({
    loaded: true,
    itemsPerPage: -1,
    workTypes: [],
    headers: [],
    dialog: false,
    users: [],
    selectedUserUID: null,
  }),

  async created() {
    this.headers = this.createHeaders();
    this.loadUsers();
    this.refresh();
  },

  methods: {
    addNewRecord_isInvisible() {
      return true;
    },

    async refresh() {
      const me = this;
      me.loaded = false;
      const res = await api.get(`WorkTypes/ByUser?UserUId=${me.userUID}`);

      let data = res.data;
      
      data.forEach((row) => {
        const workCategory = 'WorkCategory_' + row.WorkCategory.replace(" ", "_");
        row.WorkCategory = this.$t(workCategory);
      }); 
      
      this.workTypes = data;
      me.loaded = true;
      this.$emit("IsDirtyChanged", false);
    },

    undo() {
      const me = this;
      me.refresh();
    },

    async save() {
      const me = this;

      const workTypeUIDs = me.workTypes
        .filter((x) => x.IsUserSetting == true)
        .map((x) => x.WorkTypeUID);

      let userWorkTypesUserSetting = {
        UserUID: me.userUID,
        SettingName: "MyWorkTypes",
        SettingValue: workTypeUIDs.join(","),
      };
      await api.post("UserSetting", userWorkTypesUserSetting);
      me.originalWorkTypeUIDs = workTypeUIDs;

      this.$emit("IsDirtyChanged", false);
    },

    onCheckChange() {
      this.$emit("IsDirtyChanged", true);
    },

    copyWorkTypesFromOtherUser() {
      this.dialog = true;
    },

    async onOK() {
      if (this.selectedUserUID != null) {
        const userName = this.users.find(
          (e) => e.UserUID == this.selectedUserUID
        ).UserName;
        try {
          await api.post("userSetting/copy", {
            SourceUserUID: this.selectedUserUID,
            TargetUserUID: this.userUID,
            SettingName: "MyWorkTypes",
          });
        } catch (err) {
          if (err.response.status == 400) {
            this.$error(
              this.$t("NoWorkTypesAssignedToEmployee") + " " + userName
            );
            return;
          }
        }
        this.refresh();
        this.$inform(this.$t("WorkTypesHaveBeenCopiedFrom") + " " + userName);
        this.dialog = false;
      }
    },

    onCancel() {
      this.dialog = false;
    },

    createHeaders() {
      const me = this;
      const headers = [
        { text: "", value: "IsUserSetting" },
        {
          name: "Worktype_ID",
          text: me.$t("Column_WorkTypeID"),
          value: "WorkTypeID",
        },
        {
          name: "Worktype",
          text: me.$t("Worktype"),
          align: "start",
          sortable: false,
          value: "WorkType",
        },
        {
          name: "Worktype_Category",
          text: me.$t("Worktype_Category"),
          value: "WorkCategory",
        },
        {
          name: "Worktype_CostFactor",
          text: me.$t("Worktype_CostFactor"),
          value: "CostFactor",
        },
      ];
      return headers;
    },

    setGridLanguage() {
      this.headers.forEach((header) => {
        header.text = this.$t(header.name);
      });
    },

    onSelectedUserChanged(e) {
      console.log(e);
    },

    async loadUsers() {
      const res = await api.get(`Users`);
      this.users = res.data.filter((e) => e.UserUID != this.userUID);
    },
  },
};
</script>
