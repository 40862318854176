<template>  
  <EmployeeReport 
    ref="employeeReport"
    userSettingsKey="EmployeeWeeklyReport"
    :title="$t('EmployeeWeeklyReport')"
    :mondayDate="$dates.mondayDate"
    :showOnlyOneDay="false"
    :user="user" 
    @IsDirtyChanged="onIsDirtyChanged($event)"
    >
  </EmployeeReport>
</template>
<script>
  import EmployeeReport from '@/components/forms/EmployeeReport/_Index.vue';
  import viewHelpers from '../common/viewHelpers.js';

  
  export default {
    
    name: 'EmployeeWeeklyReport',

    data: () => ({
      isDirty: false,
      //mondayDate: moment('20170619') //TODO: Replace by current date or date stored in user settings
    }),

    beforeRouteLeave (to, from, next) {
      this.confirmChangesBeforeNext(this.isDirty, next);
    },  
    
    methods: {
      ...viewHelpers,
      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },      

      onIsDirtyChanged(isDirty){
        this.isDirty = isDirty;
        this.$emit("IsDirtyChanged", isDirty);
      },

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevWeek");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextWeek");
      },

      getNavigateToText() {
        return this.$t("NavigateToWeek");
      },

      async navigateDateBackward() {
        this.$refs.employeeReport.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.employeeReport.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.employeeReport.navigateToDate();
      },
      async refresh() {
        this.$refs.employeeReport.refresh();
      },
      async insertNewRow() {
        this.$refs.employeeReport.insertNewRow();
      },
      async delete() {
        this.$refs.employeeReport.delete();
      },
      async undo() {
        this.$refs.employeeReport.undo();
      },
      async save(nextPageCallBack) {
        this.$refs.employeeReport.save(nextPageCallBack);
      },
      canExportToSelectedOrAll() {
        return this.$refs.employeeReport.hasRoles(['Teamleader', 'Controller']);
      },

      insertNewRow_isInvisible() {
        return this.$refs.employeeReport.hasRoles(['Teamleader', 'Typist']) == false;
      },      

      delete_isInvisible() {
        return this.$refs.employeeReport.hasRoles(['Teamleader', 'Typist']) == false;
      },      

      exportToExcel_isInvisible() {
        return this.canExportToSelectedOrAll() == true;
      },

      async exportToExcel() {
        this.exportExcelForSelectedUser();
      },

      async exportExcelForSelectedUser() {
        this.$refs.employeeReport.exportExcelForSelectedUser("week");
      },
      async exportExcelForAllUsers() {
        this.$refs.employeeReport.exportExcelForAllUsers("week");
      },

      async openColumnSelectionDialog() {
        this.$refs.employeeReport.openColumnSelectionDialog();
      },
      async loadUserSettings() {
        this.$refs.employeeReport.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.employeeReport.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.employeeReport.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.employeeReport.setGridLanguage();
      },
      async sizeColumnsToFit() {
        this.$refs.employeeReport.sizeColumnsToFit();
      },
      async copyAsTemplateForNextWeek() {
        this.$refs.employeeReport.copyAsTemplateForNextWeek();
      },
      
    },

    props: {
      user: Object,
    },    
    
    components: {
      EmployeeReport
    },
  }
</script>
