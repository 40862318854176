<template>
  <v-row justify="center" class="pa-0 ma-0">
        <v-text-field
          ref="textField"
          v-model="searchText"
          class="ma-0 pa-1 mt-0"
          style="width: 110px; font-size: 13px;"
          append-icon="mdi-card-search"
          @keypress="onKeyPress($event)"
          @keydown="onKeyDown($event)"
          @dblclick="openSearchDialog()"
          @click:append="openSearchDialog()"          
        ></v-text-field>
    <v-dialog ref="dlg"
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="600px"
      style="height:600px"
    >
      <v-card ref="card" height="100%">
        <v-card-title>
          <v-icon>mdi-card-search</v-icon>
          <span class="ml-4">{{ caption }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-subtitle class="pb-0 mb-0" ref="subtitle">
          <v-text-field
            class="mt-8"
            ref="searchTextField"
            v-model="dialogSearchText"
            outlined
            :label="$t('Search')"
            clearable
            clear-icon="mdi-close-circle-outline"
            @click:clear="onClear()"
            @keyup="onSearchDialogKeyDown($event)"
          ></v-text-field>
        </v-card-subtitle>
        <v-card-text style="height: 500px;" class="px-3 py-0 ma-0">
          <v-container>
            <ag-grid-vue
              id="grid"
              style="width: 100%;height:460px" 
              :class="$gridTheme"              
              ref="dataGrid"
              rowSelection="single"
              @gridReady="onGridReady"
              @rowDataChanged="onRowDataChanged"
              @cellFocused="onCellFocused($event)"
              @cellDoubleClicked="onCellDoubleClicked($event)"
              @cellKeyDown="onCellKeyDown($event)"
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :rowData="items"
              :overlayNoRowsTemplate="noRowsTemplate"
              :overlayLoadingTemplate="loadingTemplate"
            ></ag-grid-vue>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t('OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<style lang="scss">  
#grid .ag-row-selected {
    background-color: var(--v-primary-base) !important;
    color: #fff;
}
</style>



<script>
import { AgGridVue } from 'ag-grid-vue'
import methods from '../LookupEditor/methods.js'

export default {
  name: 'lookup-editor',
  data() {
    return {
      caption: null,
      apiUrl: null,
      columnDefs: [],
      preFilterDelegate: null,
      writeBackDelegate: null,
      displayFormatDelegate: null,
      searchText: '',
      dialogSearchText: '',
      dialog: false,
      items: [],
      itemsPerPage: 10000,
      selectedDialogItem: null,
      selectedItem: null,
      keyField: '',
      displayField: '',
      searchExactField: '',
      workType: '',
      setSelectedValue: false,
      gridData: [],
      defaultColDef: {
        sortable: true
      }    
      }
  },
  methods: {
    ...methods,
  },
  created() {
    this.noRowsTemplate = this.$t('NoRowsFound');    
    this.loadingTemplate = this.$t('LoadingData');
  },
  mounted() {
    this.onMounted()
  },
  components: {
    AgGridVue,
  }  
}
</script>
