<template>
  <v-container class="fill-height" fluid >  
    <v-text-field
        ref="txtNewPassword"
        class="mt-4"
        :label="$t('NewPassword')"
        v-model="value"
        :rules="passwordRules"
        autocomplete="off"
        v-on:keydown.enter.prevent
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="getFieldType"
        name="newPassword"
        :hint="$t('PasswordHint')"
        :dense="false"
        :outlined="true"
        @keydown="onKeyDown($event)"
        @input="checkPasswordStrength($event)"
        @focus="passwordRules=[];"
        @blur="validatePassword()"
        @click:append="showPassword = !showPassword"
    ></v-text-field>
    <v-progress-linear
        :hidden="hidePasswordStrength"
        height="30"
        :color="strengthColor"
        :rounded="true"
        :value="passwordStrengthPercent"
    >
        <template>
        <span style="color:#fff" class="mr-2">{{$t("PasswordStrength")}}:</span>
        <b style="color:#fff">{{passwordStrength}}</b>
        </template>
    </v-progress-linear>
  </v-container>
</template>

<script>
import zxcvbn from 'zxcvbn';

export default {
  name: 'set-password-field',

  props: {
    password: String
  },

  watch: { 
    password: function(newVal) { // watch it
      this.value = newVal;
    }
  },  

  data() {
    return {
      value: '',
      showPassword: false,
      hidePasswordStrength: true,
      passwordStrength: '',
      passwordStrengthPercent: 0,
      strengthColor: "error",
      passwordRules: [],
      passwordRulesToArm: [
        (v) => !!v || this.$t("PleaseEnterPassword"),
        (v) => v.length >= 8 || this.$t("PasswordRule8Chars"),
        (v) => this.containsUppercase(v)
          || this.$t("PasswordRuleUpperChars"),
        (v) => this.containsNumber(v)
          || this.$t("PasswordRuleNumber"),
        (v) => this.containsSpecialChar(v)
          || this.$t("PasswordRuleSpecialChar"),
      ],
    };
  },
  components: {},

  computed: {
    getFieldType() {
      if (this.showPassword == true) return "text";
      return "password";
    }
  },

  methods: {

    focus() {
      setTimeout(() => {
        this.$refs.txtNewPassword.focus(),
        300
      });      
    },

    containsUppercase(val) {
      return val !== val.toLowerCase();
    },
    containsNumber(val) {
      return /\d/.test(val);
    },
    containsSpecialChar(val) {
      return /[^A-Za-z0-9]+/.test(val);
    },
    validatePassword() {
      this.passwordRules = this.passwordRulesToArm;
      //this.$refs.form.validate();
    },
    checkPasswordStrength(e) {
      this.hidePasswordStrength = false;
      const maxScore = 4;
      // eslint-disable-next-line radix
      let score = parseInt(zxcvbn(e).score);
      if (score === 4 && !this.containsSpecialChar(e)) {
        score = 3; // only give full rating if special char is included
      }
      // eslint-disable-next-line no-mixed-operators
      this.passwordStrengthPercent = (score * 100) / maxScore;
      this.passwordStrength = this.getPasswordStrengthText();
      this.strengthColor = this.getPasswordStrengthColor();      
      this.$emit('updatePassword', this.value)
    },
    getPasswordStrengthText() {
      const value = this.passwordStrengthPercent;
      if (value < 25) return this.$t("VeryBad");
      if (value < 50) return this.$t("Bad");
      if (value < 75) return this.$t("OhWell");
      if (value < 100) return this.$t("Good");
      if (value === 100) return this.$t("VeryGood");
      return 'sehr gut';
    },
    getPasswordStrengthColor() {
      const value = this.passwordStrengthPercent;
      if (value < 25) return "black";
      if (value < 50) return "black";
      if (value < 75) return "black";
      if (value < 100) return "green"
      if (value === 100) return "green"
      return 'sehr gut';
    },

    onKeyDown(e) {
      console.log(e);    
    }

  },
};
</script>
