<template>
<v-form
    ref="form" v-model="valid"
    v-if="loaded"
  >
  <v-row>
    <v-col>
      <v-card elevation="2" class="pa-8">
        <v-text-field ref="txtUserName" :rules="requiredRules" required @focus="$event.target.select()" :label="$t('UserName')" v-model="user.UserName" @keydown="onKeydown($event)" style="max-width:400px" ></v-text-field>
        <v-text-field :label="$t('WebLogin')" :rules="requiredRules" v-model="user.LoginInternal" required @keydown="onKeydown($event)" style="max-width:400px" ></v-text-field>
        <v-text-field :label="$t('MobileLogin')" disabled v-model="user.Login" required @keydown="onKeydown($event)" style="max-width:400px" ></v-text-field>
        <v-text-field :label="$t('Email')" :rules="emailRules" v-model="user.EmailAddress" required @keydown="onKeydown($event)" style="max-width:400px" ></v-text-field>
        <v-checkbox :label="$t('MobileUser')"  v-model="user.HasIphoneLicense" required @change="onChange($event)"  style="max-width:400px" ></v-checkbox>
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="2" class="pa-8">
        <v-select :items="employeeTypes" v-model="user.EmployeeType" item-text="LocalizedName" :rules="requiredRules" @change="onChange($event)"  item-value="ID" style="max-width:400px" :label="$t('EmployeeType')" required  @keydown="onKeydown($event)" ></v-select>
        <v-text-field style="max-width:400px" disabled :value="user.UserID" :label="$t('EmployeeNo')" required @keydown="onKeydown($event)" ></v-text-field>
        <v-text-field style="max-width:400px" disabled :value="user.UserUID"  :label="$t('EmployeeUID')" required @keydown="onKeydown($event)" ></v-text-field>
      </v-card>
    </v-col>
  </v-row>  

  <v-alert
      type="error"
      icon="mdi-exclamation"
      elevation="2"
      dismissible
      class="mt-4"
      transition="scale-transition"
      v-if="displayAlert"
    >
      {{ alertText }}
    </v-alert>
  </v-form>
  <v-sheet class="pa-3" v-else>
    <v-skeleton-loader
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
</template>
<style lang="scss">
</style>
<script>
import api from '@/api.js';
import utils from '@/common/utils.js';

  export default {
    
    name: 'employee-general-data',

    props: {
      userUID: String,
    },    

    emits: ["IsDirtyChanged"],
    
    data: () => ({
      employeeTypes: [],
      user: {},
      originalUser: null,
      loaded: false,
      valid: false,
      requiredRules: [],
      emailRules: [],
      displayAlert: false,
      alertText: null,
    }),
    
    async created() {
      const me = this;
      this.createValidationRules();
      const res1 = await api.get('employeeType');
      me.employeeTypes = res1.data;
      me.employeeTypes.forEach(element => {
        element.LocalizedName = me.$t(element.InternalName)
      });
      me.refresh();
    },

    updated() {
      this.$emit("SubComponentChanged", this);
    },

    methods: {

      onChange() {
        this.$emit("IsDirtyChanged", true);
      },

      onKeydown(ea) {
        const me = this;
        if (ea.ctrlKey == true && ea.code == 'KeyS') {
          ea.stopPropagation();
          ea.preventDefault();
          ea.cancelBubble = true;
          me.save();
          return false;    
        }
        if (ea.ctrlKey == true && ea.key == 'z') {
          ea.stopPropagation();
          ea.preventDefault();
          ea.cancelBubble = true;
          me.undo();
          return false;    
        }
        this.$emit("IsDirtyChanged", true);
      },

      addNewRecord_isInvisible() {
        return true;
      },


      addNewRecord() {
      },      

      async refresh() {
        const me = this;
        me.loaded = false;
        if (me.userUID == "new") {
          me.user = me.createNewRecord();
        }
        else {
          const res2 = await api.get(`user?userUID=${me.userUID}`);      
          me.user = res2.data;      
        }
        me.originalUser = utils.deepClone(me.user);
        me.loaded = true;
        setTimeout(() => {
          me.$refs.txtUserName.focus();
        }, 100);
        this.$emit("IsDirtyChanged", false);
      },

      createNewRecord() {
        let record = {
          Culture: 'de-CH', //TODO
          HasIphoneLicense: false,
          ShowWorkHourUnits: true,
          ShowExpenseUnits: true,
          AutoInsertMyWorkTypes: true,
          UseOneReportSheet: true,
        };
        return record;
      },

      undo() {
        const me = this;
        me.user = this.originalUser;
        me.originalUser = utils.deepClone(me.user);

        me.$emit("IsDirtyChanged", false);
      },

      createValidationRules() {
        this.requiredRules.push(v => !!v || this.$t("FieldIsRequired"));
        //this.emailRules.push(v => !!v || this.$t("FieldIsRequired"));
        this.emailRules.push(v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("InvalidEmail"));
      },

      showAlert(text, delay) {
        this.alertText = text;
        setTimeout(() => { this.displayAlert = false; this.refresh(); }, delay);
        this.displayAlert = true;
      },        

      async save() {
        const me = this;
        this.$refs.form.validate();
        if (this.valid == false) {
          return;
        }

        if (!me.user) return;

        await api.post('user', me.user)
        .then(function () {
          me.originalUser = utils.deepClone(me.user);

          me.$emit("IsDirtyChanged", false);
        })
        .catch(function (error) {
          console.log(error);
          me.showAlert(error.response.data.Message, 3000);
        });
      },
    },
  }
</script>
