<template>
    <v-container fluid>
      <v-data-table dense
        :headers="headers"
        :items="devices"
        item-key="DeviceID"
        display-text="Description"
        class="elevation-1"
        @click:row="onClickRow($event)"
        :items-per-page="itemsPerPage"
        hide-default-footer        
        v-if="loaded"
      >
        <template slot="no-data">
          {{$t("NoRowsFound")}}
        </template>      
      </v-data-table>
      <v-sheet class="pa-3" v-else>
        <v-skeleton-loader
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </v-sheet>


    <v-dialog overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card>
          <v-card-title>
            <span>{{$t('Device')}}</span>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="dialog = false"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>          
        <v-card-text style="height: 230px;">
          <v-text-field :label="$t('Device_UID')" disabled v-if="device.DeviceID !== null" v-model="device.DeviceID" required style="max-width:400px" ></v-text-field>
          <v-text-field :label="$t('Device_UID')" disabled v-if="device.DeviceID === null" value="<Neu>" required style="max-width:400px" ></v-text-field>
          <v-text-field ref="txtDescription" :label="$t('Device_Description')" v-model="device.Description" required style="max-width:400px" ></v-text-field>
          <v-checkbox :label="$t('Device_IsWebDevice')"  v-model="device.WebDefault" required style="max-width:400px" ></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="warning"
            @click="onDelete()"
          >
            {{$t('Delete')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="standard"
            @click="onCancel()"
          >
            {{$t('Cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="onOK()"
            width="100"
          >
            {{$t('OK')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-container>
    
</template>
<style lang="scss">
</style>
<script>
import api from '@/api.js';
import utils from '@/common/utils.js';

  export default {
    
    name: 'employee-team',

    props: {
      userUID: String,
    },    
    
    data: () => ({
      loaded: false,
      dialog: false,
      itemsPerPage: -1,
      devices: [],
      originalDevice: {        
      },
      device: {
      },
      headers: []
    }),

    methods: {

      async refresh() {
        const me = this;
        me.loaded = false;
        const res = await api.get(`device?UserUID=${me.userUID}`);
        let  data = res.data.filter(x => x.IsDeleted === false);
        (data).forEach(element => {
          element.WebDefaultLocalized = me.$t(element.WebDefault);
        });
        me.devices = data;
        me.loaded = true;
      },

      addNewRecord_isInvisible() {
        return false;
      },

      addNewRecord() {
        const me = this;
        me.device = {
          DeviceID: null,
          UserUID: me.userUID,
          Description: "",
          WebDefault: false,
          IsDeleted: false
        };
        this.originalDevice = {};
        this.dialog = true;
        setTimeout(() => {
          this.$refs.txtDescription.focus();
        }, 100);
      },

      async onOK() {
        const me = this;
        await api.post('device', me.device);
        me.refresh();
        this.dialog = false;        
      },

      onCancel() {
        this.dialog = false;        
      },

      save() {

      },

      undo() {

      },

      async onDelete() {
        const me = this;
        me.$confirm(
          me.$t('Msg_Confirm_DeleteRow_Text'), {
              title: this.$t('Msg_Confirm_DeleteRow_Title'), 
                buttonTrueText: me.$t("Yes"),
                buttonFalseText: me.$t("No"),
          }).then(res => {
            if (res == true)
            {
              api.delete("device", { data: {DeviceId: me.device.DeviceID, UserUID: this.userUID } }).then(() =>
                {
                  me.refresh();
                  me.dialog = false;
                }
              );
            }
        });
      },

      onClickRow(e)  {
        this.originalDevice = e;
        this.device = utils.deepClone(e);
        setTimeout(() => {
          this.$refs.txtDescription.focus();
        }, 50);
        this.dialog = true;
      },

    setGridLanguage() {
      this.headers.forEach(header => {
        header.text = this.$t(header.name);
      });
    },    


      createHeaders() {
        const me = this;
        const headers = [
          {
            name: 'Device_UID',
            text: me.$t('Device_UID'),
            align: 'start',
            sortable: true,
            value: 'DeviceID',
          },
          {
            name: 'Device_Description',
            text: me.$t('Device_Description'),
            align: 'start',
            sortable: true,
            value: 'Description',
          },
          {
            name: 'Device_IsWebDevice',
            text:  me.$t('Device_IsWebDevice'),
            align: 'start',
            sortable: true,
            value: 'WebDefaultLocalized',
          },
        ];
        return headers;
      },
      
    },
    async created() {
      const me = this;
      me.headers = me.createHeaders();
      me.refresh();
    },



    updated() {
      this.$emit("SubComponentChanged", this);
    }

  }
</script>
