import api from "@/api.js";
export default {

    async getUserSetting(userUID, settingName) {
        return api.get(`UserSetting?SettingName=${settingName}&userUID=${userUID}`);
    },

    async setUserSetting(userUID, settingName, settingValue) {
        const me = this;
        //First check if there is already one 
        //TODO: Move the already-existing-check to the REST-API to save an unnecessary round-trip
        let res = await me.getUserSetting(userUID, settingName);
        let setting = res.data;
        setting.SettingValue = settingValue;
        api.post("UserSetting", setting);
    },
}