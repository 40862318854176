<template>
    <table>
        <tr>
            <td><b>{{value}}</b></td>
            <td v-if="hasRoles(['Teamleader', 'Controller']) && statusId == 1">
            <v-tooltip bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2 mr-0" 
                    :style="getButtonStyle()"
                    elevation="2"
                    @click="onReturnToProcessingClick"
                    small icon color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                <v-icon small dark>mdi-arrow-left-circle</v-icon>              
                </v-btn>
                </template>
                <span>{{$t("ReturnWeekForProcessing")}}</span>
            </v-tooltip>
            </td>
            <td v-if="hasRoles(['Teamleader', 'Controller']) && statusId == 1">
            <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2 mr-4" 
                    :style="getButtonStyle()"
                    elevation="2"
                    @click="onReleaseForBookingClick"
                    small icon color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                <v-icon small dark>mdi-check-underline-circle</v-icon>              
                </v-btn>
                </template>
                <span>{{$t("ReleaseWeekForBooking")}}</span>
            </v-tooltip>
            </td>
        </tr>
    </table>    
</template> 
<style lang="scss">
</style>
<script>

export default {
    data: () => ({
        value: null,
        statusId: 0
    }),
    methods: {
        hasRoles(roles) {
            return this.params.context.componentParent.hasRoles(roles);
        },
        onReturnToProcessingClick() {
            this.params.context.componentParent.returnForProcessing(this.params.data.UserUID);
        },
        onReleaseForBookingClick() {
            this.params.context.componentParent.releaseForBooking(this.params.data.UserUID);
        },
        getButtonStyle() {
            if (this.$gus.data.darkMode) return "background:#444";
            return "background:#F5F5F5";
        },
    },
    beforeMount() {
        this.value = this.$t("Status" + this.params.value);
        this.statusId = this.params.data.StatusID;
    },
 };
 </script>