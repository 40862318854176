import moment from "moment";
import api from "@/api.js";
import Vue from "vue";

export default {
  isWeekdayField(fieldName) {
    return (
      fieldName == "MondayValue" ||
      fieldName == "TuesdayValue" ||
      fieldName == "WednesdayValue" ||
      fieldName == "ThursdayValue" ||
      fieldName == "FridayValue" ||
      fieldName == "SaturdayValue" ||
      fieldName == "SundayValue"
    );
  },

  getWeekday(fieldName) {
    if (!this.isWeekdayField(fieldName)) return null;
    return fieldName.replace("Value", "");
  },

  getExistingWorkValue(data, fieldName) {
    let value = data[fieldName];
    if (!value || !data.Values) return null;
    let weekDay = this.getWeekday(fieldName);
    const x = data.Values.filter((x) => x.DayOfWeek == weekDay);
    if (x == null || x.length == 0) return null;
    let weekDayValue = x[0];
    weekDayValue.Value = parseFloat(value);
    return weekDayValue;
  },

  async onKeydown(ea) {
    const me = this;
    if (ea.ctrlKey == true && ea.code == "KeyS") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      await me.save();
      return false;
    }
    if (ea.ctrlKey == true && ea.key == "z") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      me.undo();
      return false;
    }
  },

  async setCategoryByWorkType(event, workTypeUID) {
    const res = await api.get(
      `workCategories/byWorkType?WorkTypeUID=${workTypeUID}`
    );
    if (res.data) {
      event.data.WorkCategoryID = res.data.WorkCategoryID;
      event.data.WorkCategory = res.data.WorkCategory;
    }
  },

  onGridCellValueChanged(event) {
    const me = this;
    event.data.HasChanges = true;
    event.api.redrawRows();
    const fieldName = event.column.colDef.field;
    //Special treatment for Project-Lookup workaround (combined field was bound)
    if (fieldName == "ProjectUIDAndNameAndKey") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.ProjectUID = obj.ProjectUID;
        event.data.ProjectName = obj.ProjectName;
        event.data.ProjectKey = obj.ProjectKey;
      }
    } else if (fieldName == "WorkTypeUIDAndNameAndKey") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.WorkTypeUID = obj.WorkTypeUID;
        event.data.WorkTypeID = obj.WorkTypeID;
        event.data.WorkType = obj.WorkType;
        event.data.WorkTypeAndId = obj.WorkTypeAndId;
        this.setCategoryByWorkType(event, event.data.WorkTypeUID);
      }
    } else if (fieldName == "StructureUIDAndName") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.StructureUID = obj.keyValue;
        event.data.StructureName = obj.displayValue;
      }
    } else if (fieldName == "CostCenterJson") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.CostCenterID = obj.CostCenterID;
        event.data.CostCenterDescription = obj.CostCenterDescription;
        event.data.CostCenterDisplayField = obj.CostCenterDisplayField;
      }
    }
    //Special treatment for Phase-TreeView workaround
    else if (fieldName == "PhaseUIDAndName") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.PhaseUID = obj.keyValue;
        event.data.PhaseName = obj.displayValue;
      }
    }

    const dayOfWeek = me.getWeekday(fieldName);

    let weekDayValue = null;
    if (dayOfWeek) {
      weekDayValue = me.getExistingWorkValue(event.data, fieldName);

      if (weekDayValue == null) {
        weekDayValue = {
          //new entry
          Value: parseFloat(event.data[fieldName]),
          DayOfWeek: dayOfWeek,
          WorkUID: null,
          oldValue: null,
        };
      } else {
        if (this.data.WeekState.Status == "Finalized") {
          weekDayValue.oldValue = event.oldValue;
          event.data[fieldName + "_Old"] = event.oldValue;
        }
      }
    }
    this.undoStack.push({
      data: event.data,
      fieldName: fieldName,
      weekDayValue: weekDayValue,
      oldValue: event.oldValue,
    });
    if (!event.data.changeCount) event.data.changeCount = 1;
    else event.data.changeCount++;
    const hasChangesFieldName = event.column.colDef.field + "_HasChanges";
    event.data[hasChangesFieldName] = true;
    this.updateTotals(event);
    me.$emit("IsDirtyChanged", me.isDirty());
  },

  onCellFocused(event) {
    const nodes = event.api.getRenderedNodes();
    if (nodes && event && event.rowIndex && nodes[event.rowIndex])
      nodes[event.rowIndex].setSelected(true);
  },

  async onColumnSelectionChanged(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateTotalHeaders();
    this.updateColumnsBySettings();
  },

  async onColumnSelectionOK(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateColumnsBySettings();
    this.updateTotalHeaders();
    await this.saveUserSettings();
  },

  onWeekdaySelectionChanged(dateValue) {
    this.$dates.currentDate = moment(dateValue);
    this.$dates.mondayDate = this.$dates.currentDate.clone().startOf("isoWeek");
    this.refresh();
  },

  onReleaseForControlClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.releaseForControl);
  },

  onReturnToProcessingClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.returnForProcessing);
  },

  onReleaseForBookingClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.releaseForBooking);
  },

  onSelectedUserChanged(e) {
    this.$currentUserUID = e;
    Vue.prototype.$currentUserUID = e;
    this.refresh();
  },
};
