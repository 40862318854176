<template>
<v-app>
  <p>A page for this Url does not exist.</p>
</v-app>
</template>
<style lang="scss">
</style>
<script>

export default {
  name: 'PageNotFound',

  components: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods:
  {
  }
}

</script>