import { render, staticRenderFns } from "./_Index.vue?vue&type=template&id=973e317a&"
import script from "./_Index.vue?vue&type=script&lang=js&"
export * from "./_Index.vue?vue&type=script&lang=js&"
import style0 from "./_Index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VContainer,VRow,VSelect})
