import axios from 'axios'
import router from './router'

const apiExcel = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 100000000,
    responseType: 'blob',
    params: {}, // do not remove this, its added to add params later in the config
    headers: {
        common: {
            'Content-Type': 'application/vnd.openxmlformats',
        },
    },
})

apiExcel.interceptors.request.use((config) => {
    let token = null
    try {
        // token = store.getters['auth/token'];
        // token = `Bearer ${token}`;
        token = localStorage.getItem('token') // store.getters['auth/token'];
        if (token !== null) {
            config.headers.authorization = `Bearer ${token}`
        }
        console.log('apiExcel.interceptors.request.')
    } catch (err) {
        console.log('Error getting auth/token')
        console.log(err)
    }

    return config
})

apiExcel.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log('axios.interceptors error:')
        console.log(error)
        console.log(error.data)
        console.log(error.status)
        console.log(error.response)
        console.log(error.response.data.data)
        if (error.response.status == 401) {
            //store.auth.dispatch('logout');
            router.push('/login')
        }
        return Promise.reject(error)
    },
)

export default apiExcel