import moment from 'moment';
import apiExcel from '@/apiExcel.js';
import fileSaver from 'file-saver';

export default {


    async initialize() {
        const me = this;

        await me.loadUserSettings();
        await me.initDataGrid();

        const dataGrid = this.$refs.dataGrid;
        if (dataGrid) {
            dataGrid.gridOptions.suppressPropertyNamesCheck = true;
            dataGrid.gridOptions.undoRedoCellEditing = true;
        }
        this.noRowsTemplate = this.$t('NoRowsFound');
        this.loadingTemplate = this.$t('ProcessingData');
        this.refresh();
    },

    async refresh() {
        const me = this;
        const dataGrid = this.$refs.dataGrid;
        if (!dataGrid) return;
        const cell = dataGrid.gridOptions.api.getFocusedCell();
        await me.loadData();
        dataGrid.gridOptions.api.setRowData(this.data);
        if (cell) {
            dataGrid.gridOptions.api.setFocusedCell(cell.rowIndex, cell.column);
        }

    },


    openColumnSelectionDialog() {
        const dataGrid = this.$refs.dataGrid;
        this.$refs.columnSelectionDialog.show(dataGrid, false);
    },

    async loadUserSettings() {},

    async saveUserSettings() {},


    setGridTheme(darkMode) {
        this.gridThemeName = darkMode == true ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
    },

    setGridLanguage() {
        this.updateColumns();
        this.updatePeriodText();
    },

    async sizeColumnsToFit() {
        const me = this;
        const dataGrid = this.$refs.dataGrid;
        dataGrid.gridOptions.api.sizeColumnsToFit();
        me.saveUserSettings();
    },

    navigateDateBackward() {
        this.currentDate = this.currentDate.subtract(1, 'M');
        this.refresh();
    },

    navigateDateForward() {
        this.currentDate = this.currentDate.add(1, 'M');
        this.refresh();
    },


    navigateToDate() {
        this.$refs.dayOrWeekSelectionDialog.show(moment(this.currentDate).format("YYYY-MM-DD"), this.showOnlyOneDay);
    },

    onWeekdaySelectionChanged(dateValue) {
        this.currentDate = moment(dateValue);
        this.refresh();
    },
    async exportToNormalExcel() {
        const me = this;
        let language = this.$gus.data.languageCode;
        //if (!language || language === "en") language = 'de'; //Excel not available in English
        const response = await apiExcel.get(`excel/month?dayInMonth=${moment(this.currentDate).format("YYYY-MM-DD")}&Language=${language}&sheetPerUser=true`);
        const fileName = me.getFilenameFromHeader(response.headers, `${me.title}.xlsx`);
        fileSaver.saveAs(response.data, fileName);
    },
    async exportToDetailedExcel() {
        const me = this;
        let language = this.$gus.data.languageCode;
        const response = await apiExcel.get(`excel/monthdetailed?dayInMonth=${moment(this.currentDate).format("YYYY-MM-DD")}&Language=${language}&sheetPerUser=true`);
        const fileName = me.getFilenameFromHeader(response.headers, `${me.title}.xlsx`);
        fileSaver.saveAs(response.data, fileName);
    },

    getFilenameFromHeader(responseHeaders, defaultName) {
        let fileName = defaultName;
        let content_disposition = responseHeaders["content-disposition"];
        if (content_disposition && content_disposition.indexOf(';') > 0) {
            let tokens = content_disposition.split(';');
            if (tokens && tokens.length > 1) {
                let temp = tokens[1].trim();
                tokens = temp.split('=');
                if (tokens && tokens.length > 1) {
                    fileName = tokens[1].trim();
                }
            }
        }
        return fileName;
    },


}