<template>
  <employee-form
    ref="employeeForm"
    :userUID="userUID" 
    @IsDirtyChanged="onIsDirtyChanged($event)"
    @SubComponentChanged="onSubComponentChanged($event)"
    >
  </employee-form>


</template>
<style scoped>
  .v-data-table__selected {
    background-color: transparent;
  }
</style>
<script>
  import EmployeeForm from '@/components/forms/EmployeeForm/_Index.vue';

  export default {
    name: 'EditEmployee',

    async created() {
      this.userUID = this.$route.params.userUID;
    },
        
    data: () => ({
      isDirty: false,
      userUID: null,

    }),

    methods: {

      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },      

      addNewRecord_isInvisible() {
        //return false;
        if (! this.$refs.employeeForm) return true;
        return this.$refs.employeeForm.addNewRecord_isInvisible();
      },

      async addNewRecord() {
        this.$refs.employeeForm.addNewRecord();
      },

      async refresh() {
        this.$refs.employeeForm.refresh();
      },
      
      async save() {
        this.$refs.employeeForm.save();
      },

      async undo() {
        this.$refs.employeeForm.undo();
      },

      resetPassword() {
        this.$refs.employeeForm.resetPassword();
      },

      setGridLanguage() {
        this.$refs.employeeForm.setGridLanguage();
      },       

      copyWorkTypesFromOtherUser_isInvisible() {
        return this.$refs.employeeForm.copyWorkTypesFromOtherUser_isInvisible();
      },

      copyWorkTypesFromOtherUser() {
        this.$refs.employeeForm.copyWorkTypesFromOtherUser();
      },

      onIsDirtyChanged(isDirty){
        this.isDirty = isDirty;
        this.$emit("IsDirtyChanged", isDirty);
      },

      onSubComponentChanged() {
        this.$emit("SubComponentChanged", true);
      }
      
    },
    
    components: {
      EmployeeForm
    },

  }
  
</script>
