import Vue from "vue";
export default {
  onMounted() {
    const me = this;
    Vue.nextTick(() => {
      me.caption = `${this.$t(this.params.column.colDef.columnName)} ${this.$t(
        "Selection"
      )}`;
      me.keyField = this.params.keyField;
      me.parentKeyField = this.params.parentKeyField;
      me.displayField = this.params.displayField;
      me.items = this.params.items;
      me.displayText = this.params.data[this.displayField];
      me.value = this.params.data[this.keyField];
      me.treeValues = [];
      me.treeValues.push(this.value);
      //me.dialog = true;
      setTimeout(() => {
        if (me.$refs.searchTextField) me.$refs.searchTextField.focus();
        const treeView = me.$refs.vtreeView;
        if (treeView) {
          const activeElList = treeView.$el.querySelectorAll(
            "div.v-treeview-node--active"
          );
          if (activeElList != null && activeElList.length > 0) {
            const el = activeElList[0];
            el.scrollIntoView();
          }
        }
        //v-treeview-node--active
      }, 300);
    });
  },

  onAppend() {
    const me = this;
    // this.valTextarea = this.val;
    me.dialog = true;
    Vue.nextTick(() => {
      setTimeout(() => {
        //me.$refs.vtreeView.focus();
        me.$refs.vtreeView.$el.scrollIntoView();
      }, 100);
    });
  },

  onClear() {
    this.displayText = "";
    this.value = null;
    this.params.stopEditing();
  },

  onTextfieldKeyDown(ea) {
    if (ea.ctrlKey == true && ea.key == "Enter") {
      ea.stopPropagation();
      this.append();
    }
  },

  onClick() {},

  onOK() {
    const activeVal =
      this.treeValues != null && this.treeValues.length > 0
        ? this.treeValues[0]
        : null;
    this.dialog = false;
    this.value = activeVal;
    //this.displayText = this.params.data[this.displayField];
    this.isValid = true;
    this.params.stopEditing();
  },
  onCancel() {
    this.dialog = false;
    this.isValid = false;
    setTimeout(() => {
      this.params.stopEditing(true);
    }, 100);
  },

  // setSelected(item) {
  //     this.selectedItem = item;
  //     this.searchText = `${this.selectedItem.ProjectKey} - ${this.selectedItem.ProjectName}`;
  //     this.projectName = this.selectedItem.ProjectName;
  //     this.cancelEdit = false;
  //     this.isValid = true;
  //     this.value = JSON.stringify({
  //         ProjectUID: this.selectedItem["ProjectUID"],
  //         ProjectKey: this.selectedItem["ProjectKey"],
  //         ProjectName: this.selectedItem["ProjectName"]
  //     });
  //     //this.value = this.selectedItem[this.keyField] + "," + this.selectedItem["ProjectName"] + "," + this.selectedItem["ProjectKey"];
  //     this.params.stopEditing();
  // },

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  },

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  },

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
    // our editor will reject any value greater than 1000
  },
};
