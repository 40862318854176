//import api from '@/api.js';

export default {

    async initDataGrid() {
        const me = this;

        const dataGrid = this.$refs.dataGrid;
        if (dataGrid) {
            dataGrid.gridOptions.suppressPropertyNamesCheck = true;
            dataGrid.gridOptions.undoRedoCellEditing = true;
        }

        let columnInfoMap = {};
        this.columnSettings.columnInfos.forEach(element => {
            columnInfoMap[element.ColumnName] = element;
        });

        me.defaultColumnDefs = {
            editable: true,
            resizable: true,
        };
        const columnDefs = [];
        columnDefs.push(me.createColumnDef(columnInfoMap, "UserID", "UserID", 150));
        columnDefs.push(me.createColumnDef(columnInfoMap, "UserName", "UserName", 250));
        columnDefs.push(me.createColumnDef(columnInfoMap, "EmployeeType", "EmployeeType", 250));
        columnDefs.push(me.createColumnDef(columnInfoMap, "Roles", "Roles", 250));
        columnDefs.push(me.createColumnDef(columnInfoMap, "TeamMembers", "TeamMembers", 600));
        // columnDefs.push(me.createColumnDef(columnInfoMap, "EmployeeType", "Column_EmployeeType", 250));
        me.columnDefs = columnDefs;
    },

    createColumnDef(columnInfoMap, columnName, field, defaultWidth, defaultVisibility) {
        if (!defaultVisibility) defaultVisibility = true;
        const me = this;
        let columnDef = {
            field: field,
            columnName: columnName,
            headerName: me.$t(columnName),
            editable: false,
            sortable: true,
            filterable: true,
            hide: !me.getColumnInfoValue(columnInfoMap, columnName, 'visible', defaultVisibility),
            resizable: true
        }
        columnDef.width = me.getColumnInfoValue(columnInfoMap, columnName, 'width', defaultWidth);
        return columnDef;
    },


    getColumnInfoValue(map, columnName, propertyName, defaultValue) {
        let info = map[columnName];
        if (info == null || info[propertyName == null]) {
            return defaultValue;
        } else {
            return map[columnName][propertyName];
        }
    },

}