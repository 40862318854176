<template>
  <v-app>
  <v-container class="fill-height" fluid>  
    <v-row align="center" justify="center">
      <v-col cols="12" lg="5" sm="10" md="8">
        <v-btn-toggle class="pb-4"
            v-model="languageIndex"
            mandatory
            >
            <v-btn @click="switchLanguage('de')">
                Deutsch
            </v-btn>
            <v-btn @click="switchLanguage('fr')">
                Français
            </v-btn>
            <v-btn @click="switchLanguage('en')">
                English
            </v-btn>
        </v-btn-toggle>

        <v-form ref="form" style="max-width:600px">
          <v-card class="elevation-12">
            <v-card-title>            
                <v-layout justify-space-between>
                    <span>{{$t("GoZeit")}}</span>
                    <span style="font-size:14px">{{$t("SetNewPassword")}}</span>
                    <v-img src="./logo.png" max-width="140"></v-img>            
                </v-layout>                
            </v-card-title>
            <v-card-text>
              <v-container dense="true">
                <v-row>
                  <v-col cols="12">
                  <span>{{$t("PasswordHint")}}</span>
                    <v-text-field
                      ref="txtNewPassword"
                      class="mt-8"
                      :label="$t('NewPassword')"
                      v-model="newPassword"
                      :rules="passwordRules"
                      autocomplete="off"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      name="newPassword"
                      :dense="false"
                      :outlined="true"
                      @input="checkPasswordStrength($event)"
                      @focus="passwordRules=[];"
                      @blur="validatePassword()"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-text-field
                      ref="txtNewPasswordRepeat"
                      class="mt-0 mb-0"
                      :label="$t('NewPasswordRepeat')"
                      v-model="newPasswordRepeat"
                      :type="showPassword ? 'text' : 'password'"
                      name="newPasswordRepeat"
                      :dense="false"
                      :outlined="true"
                    ></v-text-field>
                    <v-progress-linear
                      :hidden="hidePasswordStrength"
                      height="30"
                      :color="strengthColor"
                      :rounded="true"
                      :value="passwordStrengthPercent"
                    >
                      <template>
                        <span style="color:#fff" class="mr-2">{{$t("PasswordStrength")}}:</span>
                        <b style="color:#fff">{{passwordStrength}}</b>
                      </template>
                    </v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="mr-4 mb-4"
                @click="setNewPassword()"
              >{{$t("SetNewPassword")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>
import zxcvbn from 'zxcvbn';
import api from '@/api.js';

export default {
  name: 'SetNewPassword',
  data() {
    return {
      hash: "",
      language: "",
      languageIndex: 0,
      showPassword: false,
      showUnexpectedError: false,
      newPassword: "",
      hidePasswordStrength: true,
      passwordStrength: '',
      passwordStrengthPercent: 0,
      serversideErrors: {
        password: '',
      },
      strengthColor: "error",
      passwordRules: [],
      passwordRulesToArm: [
        (v) => !!v || this.$t("PleaseEnterPassword"),
        (v) => v.length >= 8 || this.$t("PasswordRule8Chars"),
        (v) => this.containsUppercase(v)
          || this.$t("PasswordRuleUpperChars"),
        (v) => this.containsNumber(v)
          || this.$t("PasswordRuleNumber"),
        (v) => this.containsSpecialChar(v)
          || this.$t("PasswordRuleSpecialChar"),
      ],
    };
  },
  components: {},
  created() {
    this.hash = this.$route.query.hash;
    this.language = this.$route.query.language;
    this.languageIndex = this.language == "de" ? 0 : this.language == "fr" ? 1: 2;
    this.switchLanguage(this.language);
  },
  mounted() {
    this.$nextTick(this.init());
  },
  methods: {

    async init() {
      const me = this;      
      await this.checkPermission();
      setTimeout(() => {
        me.$refs.txtNewPassword.focus();
      }, 300);
    },


    async checkPermission() {
      try
      {
        const res = await api.get(`Password/Request?hash=${this.hash}`);
        if (res.data == null) {
          this.$router.push("/SetnewPasswordExpired");
          return;
        }
        if (res.data.ValidUntil < new Date()) {
          this.$router.push("/SetnewPasswordExpired");
          return;
        }
      }
      catch(err) {
        console.log(err);
        throw(err);
      }

      
    },

    switchLanguage(languageCode) {
        this.languageCode = languageCode;
        this.$i18n.locale = languageCode;
        localStorage.setItem('loginLanguage', this.languageCode);
    },

    async setNewPassword() {
      const me = this;
      this.showUnexpectedError = false;
      try {
        const res = await api.post("Password/SetNew", {
          Hash: this.hash,
          Password: this.newPassword
        });
        this.$router.push("/NewPasswordWasSet");
        console.log(res);
        //const r = axios.post('http://localhost:3001/v1/users/setNewPassword', p);
        //console.log(r);
        //me.$router.push('/login');
      } catch (err) {
        if (err.response.status === 500) {
          this.showUnexpectedError = true;
          console.log(err.response);
        } else {
          me.serversideErrors = err.response.data;
        }
      }
    },

    containsUppercase(val) {
      return val !== val.toLowerCase();
    },
    containsNumber(val) {
      return /\d/.test(val);
    },
    containsSpecialChar(val) {
      return /[^A-Za-z0-9]+/.test(val);
    },
    validatePassword() {
      this.passwordRules = this.passwordRulesToArm;
      this.$refs.form.validate();
    },
    checkPasswordStrength(e) {
      this.hidePasswordStrength = false;
      const maxScore = 4;
      // eslint-disable-next-line radix
      let score = parseInt(zxcvbn(e).score);
      if (score === 4 && !this.containsSpecialChar(e)) {
        score = 3; // only give full rating if special char is included
      }
      // eslint-disable-next-line no-mixed-operators
      this.passwordStrengthPercent = (score * 100) / maxScore;
      this.passwordStrength = this.getPasswordStrengthText();
      this.strengthColor = this.getPasswordStrengthColor();
    },
    getPasswordStrengthText() {
      const value = this.passwordStrengthPercent;
      if (value < 25) return this.$t("VeryBad");
      if (value < 50) return this.$t("Bad");
      if (value < 75) return this.$t("OhWell");
      if (value < 100) return this.$t("Good");
      if (value === 100) return this.$t("VeryGood");
      return 'sehr gut';
    },
    getPasswordStrengthColor() {
      const value = this.passwordStrengthPercent;
      if (value < 25) return "black";
      if (value < 50) return "black";
      if (value < 75) return "black";
      if (value < 100) return "green"
      if (value === 100) return "green"
      return 'sehr gut';
    },

  },
};
</script>
