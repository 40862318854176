import api from '@/api.js';
import utils from '@/common/utils.js';
export default {

    async loadData() {
        const me = this;

        me.loadingTemplate = this.$t('ProcessingData');
        me.$refs.dataGrid.gridOptions.api.showLoadingOverlay();

        await me.loadUserWorkTypes();

        let res;
        if (me.showOnlyOneDay === true) {
            res = await api.get(`workweek/day?userUID=${me.selectedUserUID}&dayDate=${me.$dates.currentDate.format('YYYY-MM-DD')}`);
        } else {
            res = await api.get(`workweek?userUID=${me.selectedUserUID}&mondayDate=${me.$dates.mondayDate.format('YYYY-MM-DD')}`);
        }
        
        me.statusTextCode = this.showOnlyOneDay == true ? `Status${res.data.DayStatus}` : `Status${res.data.WeekState.Status}`;
        me.updateHeaderTexts();

        //me.$refs.dataGrid.gridOptions.api.hideOverlay();
        me.data = res.data;

        me.data.WeekState = res.data.WeekState;
        if (me.data == null) {
            me.data = {
                MondayDate: me.$dates.mondayDate.format('YYYY-MM-DD'),
                UserUID: me.selectedUserUID,
                Rows: [],
                WeekState: {},
                WorkHourSuffix: '',
                ExpenseSuffix: '',
            }
        }
        if (me.data.Rows == null) {
            me.data.Rows = [];
        }
        me.data.Rows.forEach(row => {
            me.addAdditionalColumnsForLookups(row);
        });
        
        me.totalsRowData.forEach(totalRow => {
            totalRow.WorkHourSuffix = me.data.WorkHourSuffix;
            totalRow.ExpenseSuffix = me.data.ExpenseSuffix;
        });

        me.addEmptyRows(this.data.Rows);

        let rowID = 1;
        me.data.Rows.forEach(row => {
            row.RowID = rowID++;
            row.MondayValue = this.getWeekdayValue(row, 'Monday');
            row.TuesdayValue = this.getWeekdayValue(row, 'Tuesday');
            row.WednesdayValue = this.getWeekdayValue(row, 'Wednesday');
            row.ThursdayValue = this.getWeekdayValue(row, 'Thursday');
            row.FridayValue = this.getWeekdayValue(row, 'Friday');
            row.SaturdayValue = this.getWeekdayValue(row, 'Saturday');
            row.SundayValue = this.getWeekdayValue(row, 'Sunday');

            row.MondayValue_Old = this.getWeekdayOldValue(row, 'Monday');
            row.TuesdayValue_Old = this.getWeekdayOldValue(row, 'Tuesday');
            row.WednesdayValue_Old = this.getWeekdayOldValue(row, 'Wednesday');
            row.ThursdayValue_Old = this.getWeekdayOldValue(row, 'Thursday');
            row.FridayValue_Old = this.getWeekdayOldValue(row, 'Friday');
            row.SaturdayValue_Old = this.getWeekdayOldValue(row, 'Saturday');
            row.SundayValue_Old = this.getWeekdayOldValue(row, 'Sunday');

            row.OldKeyValues = {};
            row.OldKeyValues.WorkTypeUID = row.WorkTypeUID;
            row.OldKeyValues.ProjectUID = row.ProjectUID;
            row.OldKeyValues.PhaseUID = row.PhaseUID;
            row.OldKeyValues.StructureUID = row.StructureUID;
            row.OldKeyValues.CostCenterID = row.CostCenterID;
            row.OldKeyValues.BookingText = row.BookingText;
            row.OldKeyValues.WorkAttributes = row.WorkAttributes;

            row.WorkHourSuffix = me.data.WorkHourSuffix;
            row.ExpenseSuffix = me.data.ExpenseSuffix;
        });

    },

    async loadUserWorkTypes() {
        const me = this;
        const res = await api.get(`WorkTypes/ByUser?UserUId=${me.selectedUserUID}`);
        let workTypes = res.data;
        me.workTypeUIDs = workTypes
            .filter(w => w.IsClientSetting == true || w.IsUserSetting == true)
            .map(x => x.WorkTypeUID);
    },

    addAdditionalColumnsForLookups(row) {
        //Special treatment for Project
        if (row.ProjectUID) {
            row["ProjectUIDAndNameAndKey"] = JSON.stringify({
                ProjectUID: row.ProjectUID,
                ProjectKey: row.ProjectKey,
                ProjectName: row.ProjectName
            });
        }
        if (row.WorkTypeUID) {
            row["WorkTypeUIDAndNameAndKey"] = JSON.stringify({
                WorkTypeUID: row.WorkTypeUID,
                WorkTypeID: row.WorkTypeID,
                WorkType: row.WorkType,
                WorkTypeAndId: `${row.WorkTypeID} - ${row.WorkType}`
            });
            row["WorkTypeAndId"] = `${row.WorkTypeID} - ${row.WorkType}`;
        }
        if (row.CostCenterID) {
            row["CostCenterJson"] = JSON.stringify({
                CostCenterID: row.CostCenterID,
                CostCenterDescription: row.CostCenterDescription,
                CostCenterDisplayField: `${row.CostCenterID} - ${row.CostCenterDescription}`
            });
            row["CostCenterDisplayField"] = `${row.CostCenterID} - ${row.CostCenterDescription}`;
        }
        if (row.PhaseUID)
            row["PhaseUIDAndName"] = JSON.stringify({
                keyValue: row.PhaseUID,
                displayValue: row.PhaseName
            })
        if (row.StructureUID)
            row["StructureUIDAndName"] = JSON.stringify({
                keyValue: row.StructureUID,
                displayValue: row.StructureName
            })
    },

    addEmptyRows(rows) {
        const me = this;
        const targetRowCount = 14;

        for (let i = rows.length; i < targetRowCount; i++) {
            me.data.Rows.splice(i, 0, me.createNewRow());
        }

    },

    getWeekdayValue(row, weekday) {
        if (!row) return null;
        if (!row.Values) return null;
        const x = row.Values.find(o => o.DayOfWeek === weekday);
        if (!x) return null;
        return x.Value;
    },

    getWeekdayOldValue(row, weekday) {
        if (!row) return null;
        if (!row.Values) return null;
        const x = row.Values.find(o => o.DayOfWeek === weekday);
        if (!x) return null;
        return x.oldValue;
    },

    async loadReferenceData() {
        const me = this;

        me.totalsRowData = [{
                ProjectUID: null,
                WorkTypeUID: '',
                WorkCategoryID: null,
                WorkCategory: '',
                PhaseUID: null,
                StructureUID: null,
                CostCenterID: null,
                BookingText: '',
                MondayValue: 0,
                TuesdayValue: 0,
                WednesdayValue: 0,
                ThursdayValue: 0,
                FridayValue: 0,
                SaturdayValue: 0,
                SundayValue: 0,
                TotalsValue: 0,
                WorkHourSuffix: "",
                ExpenseSuffix: "",
            },
            {
                ProjectUID: null,
                WorkTypeUID: '',
                WorkCategoryID: null,
                WorkCategory: '',
                PhaseUID: null,
                StructureUID: null,
                CostCenterID: null,
                BookingText: '',
                MondayValue: 0,
                TuesdayValue: 0,
                WednesdayValue: 0,
                ThursdayValue: 0,
                FridayValue: 0,
                SaturdayValue: 0,
                SundayValue: 0,
                TotalsValue: 0,
                WorkHourSuffix: "",
                ExpenseSuffix: "",                
            }
        ];

        let res = await api.get('workCategories');
        me.workCategories = res.data;
        me.workCategories.forEach(workCategory => {
            me.workCategoryLookup[workCategory.WorkCategoryID] = workCategory;
        });

        if (utils.hasUserRole(me.$user, "Controller")) {
            me.users = this.$allUsers;
        } else if (utils.hasUserRole(me.$user, "Teamleader")) {
            me.users = this.$teamUsers;
        }
        me.users.push({ UserUID: me.$user.UserUID, UserName: me.$user.UserName });
        
        res = await api.get(`workTypes/bysettingsandweek?userUID=${me.selectedUserUID}&mondayDate=${me.$dates.mondayDate.format('YYYY-MM-DD')}`);
        //let res = await api.get('workTypes');
        me.workTypes = res.data;
        me.workTypes.forEach(workType => {
            me.workTypeLookup[workType.WorkTypeUID] = workType;
        });

        res = await api.get("costCenters");
        me.costCenters = res.data;
        me.costCenters.forEach(costCenter => {
            me.costCenterLookup[costCenter.CostCenterID] = costCenter;
        });


    },

}