import moment from "moment";
import api from "@/api.js";

export default {
  async onProjectChanged(e) {
    const me = this;
    me.project = e;
    me.projectSearchText = me.project == null ? "" : e.ProjectName;
    if (me.project == null) {
      const res = await api.get(`structures?projectUID=${e.ProjectUID}`);
      me.structuresFlat = res.data;
    } else {
      me.structuresFlat = [];
    }
    if (me.$refs.structureLookupBox) me.$refs.structureLookupBox.reset();
    me.refresh();

    //me.setUserSetting(me.selectedUserUID, me.projectUserSettingsKey, e.ProjectUID);
    me.setUserSetting(
      me.$user.UserUID.toLowerCase(),
      me.projectUserSettingsKey,
      e.ProjectUID
    );
  },

  onStructureChanged(e) {
    const me = this;
    me.structure = e;
    me.refresh();
  },

  isWeekdayField(fieldName) {
    return (
      fieldName == "MondayValue" ||
      fieldName == "TuesdayValue" ||
      fieldName == "WednesdayValue" ||
      fieldName == "ThursdayValue" ||
      fieldName == "FridayValue" ||
      fieldName == "SaturdayValue" ||
      fieldName == "SundayValue"
    );
  },

  getWeekday(fieldName) {
    if (!this.isWeekdayField(fieldName)) return null;
    return fieldName.replace("Value", "");
  },

  getExistingWorkValue(data, fieldName) {
    let value = data[fieldName];
    if (!value || !data.Values) return null;
    let weekDay = this.getWeekday(fieldName);
    const x = data.Values.filter((x) => x.DayOfWeek == weekDay);
    if (x == null || x.length == 0) return null;
    let weekDayValue = x[0];
    weekDayValue.Value = parseFloat(value);
    return weekDayValue;
  },

  async onKeydown(ea) {
    const me = this;
    if (ea.ctrlKey == true && ea.code == "KeyS") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      await me.save();
      return false;
    }
    if (ea.ctrlKey == true && ea.key == "z") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      me.undo();
      return false;
    }
  },

  async setCategoryByWorkType(event, workTypeUID) {
    const res = await api.get(
      `workCategories/byWorkType?WorkTypeUID=${workTypeUID}`
    );
    if (res.data) {
      event.data.WorkCategoryID = res.data.WorkCategoryID;
      event.data.WorkCategory = res.data.WorkCategory;
    }
  },

  onGridCellValueChanged(event) {
    const me = this;

    event.data.HasChanges = true;
    event.api.redrawRows();
    const fieldName = event.column.colDef.field;

    //Special treatment for Structure-TreeView workaround (combined field was bound)
    if (fieldName == "StructureUIDAndName") {
      const val = event.newValue;
      if (val != null && val.indexOf(",") > 0) {
        const tokens = val.split(",");
        event.data.StructureUID = tokens[0];
        event.data.StructureName = tokens[1];
      }
    }
    //Special treatment for Structure-TreeView workaround (combined field was bound)
    else if (fieldName == "ProjectUIDAndName") {
      const val = event.newValue;
      if (val != null && val.indexOf(",") > 0) {
        const tokens = val.split(",");
        event.data.ProjectUID = tokens[0];
        event.data.ProjectName = tokens[1];
      }
    } else if (fieldName == "ProjectUIDAndKey") {
      const val = event.newValue;
      if (val != null && val.indexOf(",") > 0) {
        const tokens = val.split(",");
        event.data.ProjectUID = tokens[0];
        event.data.ProjectKey = tokens[1];
      }
    } else if (fieldName == "WorkTypeUIDAndNameAndKey") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.WorkTypeUID = obj.WorkTypeUID;
        event.data.WorkTypeID = obj.WorkTypeID;
        event.data.WorkType = obj.WorkType;
        event.data.WorkTypeAndId = obj.WorkTypeAndId;
        this.setCategoryByWorkType(event, event.data.WorkTypeUID);
      }
    } else if (fieldName == "CostCenterJson") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.CostCenterID = obj.CostCenterID;
        event.data.CostCenterDescription = obj.CostCenterDescription;
        event.data.CostCenterDisplayField = obj.CostCenterDisplayField;
      }
    }

    this.setWeekDayValue(fieldName, event.data, event.oldValue);
    this.updateTotals(event);
    me.$emit("IsDirtyChanged", me.isDirty());
  },

  setWeekDayValue(fieldName, data, oldValue) {
    const me = this;
    const dayOfWeek = me.getWeekday(fieldName);
    let weekDayValue = null;
    if (dayOfWeek) {
      weekDayValue = me.getExistingWorkValue(data, fieldName);

      if (weekDayValue == null) {
        weekDayValue = {
          //new entry
          Value: parseFloat(data[fieldName]),
          DayOfWeek: dayOfWeek,
          WorkUID: null,
          oldValue: null,
        };
      } else {
        if (data.WeekState.Status == "Finalized") {
          weekDayValue.oldValue = oldValue;
          data[fieldName + "_Old"] = oldValue;
        }
      }
    }
    this.undoStack.push({
      data: data,
      fieldName: fieldName,
      weekDayValue: weekDayValue,
      oldValue: oldValue,
    });
    if (!data.changeCount) data.changeCount = 1;
    else data.changeCount++;
    const hasChangesFieldName = fieldName + "_HasChanges";
    data[hasChangesFieldName] = true;
  },

  onCellFocused(event) {
    const nodes = event.api.getRenderedNodes();
    if (nodes && event && event.rowIndex && nodes[event.rowIndex])
      nodes[event.rowIndex].setSelected(true);
  },

  async onColumnSelectionChanged(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateTotalHeaders();
    this.updateColumnsBySettings();
  },

  async onColumnSelectionOK(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateColumnsBySettings();
    this.updateTotalHeaders();
    await this.saveUserSettings();
  },

  onWeekdaySelectionChanged(dateValue) {
    this.$dates.currentDate = moment(dateValue);
    this.$dates.mondayDate = this.$dates.currentDate.clone().startOf("isoWeek");
    this.refresh();
  },

  onReleaseForControlClick() {
    const me = this;
    me.$confirm(this.$t("ReleaseWeekText"), {
      title: this.$t("ReleaseTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.releaseForControl();
      }
    });
  },

  onReturnToProcessingClick() {
    const me = this;
    me.$confirm(this.$t("ReturnWeekText"), {
      title: this.$t("ReturnTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.returnForProcessing();
      }
    });
  },

  onReleaseForBookingClick() {
    const me = this;
    me.$confirm(this.$t("ReleaseWeekForBookingText"), {
      title: this.$t("ReleaseWeekForBookingTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.releaseForBooking();
      }
    });
  },

  onSelectedUserChanged() {
    console.log("onSelectedUserChanged");
    console.log(this.selecteUserUID);
    this.refresh();
  },
};
