<template>
  <div class="main-component">
    <router-view></router-view>
  </div>
</template>
<script>
import router from './router';

export default {
  name: "App",

  created() {    
    const token = localStorage.getItem('token');
    if (token && this.$user == null)
    {
      router.push(`/?redirectUrl=${this.$route.path}`);
    }
  }

};
</script>
