<template>
<a @click="gotoUrl"
    >
      {{$t("ConstructionSiteReport")}}
    </a>    
</template> 
<style lang="scss">
</style>
<script>

export default {
    data: () => ({
        url: null,
    }),    
    methods: {
        gotoUrl() {
            this.$router.push(this.url);
        }
    },
    beforeMount() {
        this.url = this.params.value;
    },
 };
 </script>