<template>
  <month-overview-list 
    ref="monthOverviewList"
    userSettingsKey="MonthOverview"
    :title="$t('MonthOverview')"
    :currentDate="mondayDate"
    >
  </month-overview-list>
</template>
<style lang="scss">
</style>
<script>
  import MonthOverviewList from '@/components/forms/MonthOverviewList/_Index.vue';
  import moment from 'moment';

  export default {
    name: 'MonthOverview',

    data: () => ({
      mondayDate: moment('20170619') //TODO: Replace by current date or date stored in user settings
    }),


    methods: {

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevMonth");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextMonth");
      },

      getNavigateToText() {
        return this.$t("NavigateToMonth");
      },

      async navigateDateBackward() {
        this.$refs.monthOverviewList.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.monthOverviewList.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.monthOverviewList.navigateToDate();
      },
      async refresh() {
        this.$refs.monthOverviewList.refresh();
      },
      async loadUserSettings() {
        this.$refs.monthOverviewList.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.monthOverviewList.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.monthOverviewList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.monthOverviewList.setGridLanguage();
      },
      async exportToNormalExcel() {
        this.$refs.monthOverviewList.exportToNormalExcel();
      },
      async exportToDetailedExcel() {
        this.$refs.monthOverviewList.exportToDetailedExcel();
      }
      
    },
    
    components: {
     MonthOverviewList
    },

  }
  
</script>
