import Vue from "vue";
export default {
  onMounted() {
    const me = this;
    me.val = this.params.value;
    if (me.params.charPress == null && me.params.keyPress == 46) {
      me.params.stopEditing(false);
      return;
    }
    Vue.nextTick(() => {
      const input = this.$refs.input;
      //Take over value of keypress on datagrid to the editor value (Excel behaviour)
      if (me.params.charPress != null) {
        let inputText = this.params.charPress;
        if (inputText != null && inputText.length > 0) {
          if (me.isValidNumber(inputText.charCodeAt(0))) {
            me.val = inputText;
          } else {
            this.params.stopEditing(false);
          }
        }
      }
      input.focus();
    });
  },

  onChange(ea) {
    this.val = ea; //TODO: parse
    this.params.stopEditing();
  },
  onKeyDown(ea) {
    const me = this;
    if (ea.key == "Escape") {
      me.isValid = false;
      me.params.stopEditing(true);
    } else if (ea.key == "Tab") {
      ea.stopPropagation();
      me.val = ea.srcElement._value;
      me.params.stopEditing();
      const api = me.params.api;
      setTimeout(() => {
        api.tabToNextCell();
        // const cell = api.getFocusedCell();
        // api.startEditingCell(
        //   {
        //     rowIndex: cell.rowIndex,
        //     colKey: cell.column.colId,
        //     rowPinned: null
        // });
      }, 50);
    } else if (ea.key == "Enter") {
      me.params.stopEditing();
      // setTimeout(() => {
      //   me.params.api.tabToNextCell();
      // }, 50);
    }
  },

  onInput(ea) {
    this.val = ea; //TODO: parse
  },
  onKeyPress(e) {
    e = e ? e : window.event;
    var charCode = e.which ? e.which : e.keyCode;
    if (!this.isValidNumber(charCode)) {
      e.preventDefault();
    } else {
      return true;
    }
  },

  isValidNumber(charCode) {
    return !(
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      !(charCode === 46 || charCode === 45)
    );
  },

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    if (this.val === "") this.val = 0;
    return this.val;
  },

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  },

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return !this.isValid;
    //return false; //TODO: numeric validation
    // our editor will reject any value greater than 1000
  },
};
