import moment from 'moment';

export default {
    template: `<span>{{ displayValue }}</span>`,
    data: () => ({
        displayValue: null
    }),
    beforeMount() {
        if (!this.params.value) {
            this.displayValue = "";
            return;
        }
        const f = moment(this.params.value).format('HH:mm');
        this.displayValue = f == "00:00" ? "" : f;

    },
};