import moment from 'moment';
export default {
    show(dateValue, selectDay) {
        this.selectDay = selectDay;
        this.title = this.$t("SelectDate"); //selectDay === true ? this.$t("SelectDate") : this.$t('SelectDateForWeek');
        this.dateValue = dateValue;
        this.dialog = true;
    },
    onOK() {
        this.dialog = false;
        if (this.selectDay === true) {
            this.$emit('onOK', this.dateValue);
        } else {
            const mondayDate = moment(this.dateValue).startOf('isoweek');
            this.$emit('onOK', mondayDate);
        }
    },
}