<template>
  <v-row justify="center" class="pa-0 ma-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          ref="textField"
          v-model="searchText"
          class="ma-0 pa-0 mt-4"
          style="font-size: 13px;"
          append-icon="mdi-card-search"
          dense
          v-bind="attrs"
          v-on="on"
          @keypress="onKeyPress($event)"
          @keydown="onKeyDown($event)"
          @click:append="openSearchDialog()"
        ></v-text-field>
      </template>
      <span>{{searchText}}</span>
    </v-tooltip>
    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-card-search</v-icon>
          <span class="ml-4">{{ $t('SelectProject') }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-subtitle class="pb-0 mb-0">
          <v-text-field
            class="mt-8"
            ref="searchTextField"
            v-model="dialogSearchText"
            outlined
            :label="$t('Search')"
            clearable
            clear-icon="mdi-close-circle-outline"
            @keyup="onSearchDialogKeyDown($event)"
          ></v-text-field>
        </v-card-subtitle>
        <v-card-text style="height: 500px;" class="px-3 py-0 ma-0">
          <v-container>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              item-key="ProjectKey"
              single-select
              @click:row="onRowClick"
              class="elevation-1"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template slot="no-data">
                {{ $t('NoRowsFound') }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t('OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import methods from '../ProjectLookupBox/methods.js'

export default {
  name: 'project-lookup-box',
  data() {
    return {
      projectName: this.$t('NoProjectSelected'),
      dialogSearchText: '',
      dialog: false,
      headers: [],
      items: [],
      itemsPerPage: 10000,
      selectedDialogItem: null,
      selectedItem: null,
    }
  },
  methods: {
    ...methods,
  },
  props: {
    searchText: String,
  },
  mounted() {
    this.onMounted()
  },
}
</script>
