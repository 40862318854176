<template>
  <v-app>
    <user-account-dialog
      ref="userAccountDialog"
      @changeTheme="onChangeTheme($event)"
      @changeGridTheme="onChangeGridTheme($event)"
      @changeGridLanguage="onChangeLanguage"
      @onOK="onUserAccountOK"
    >
    </user-account-dialog>
    <v-navigation-drawer
      app
      :color="sideMenuColor"
      v-bind:width="310"
      v-model="showSideBar"
    >
      <v-row>
        <v-col
          ><router-link to="/"
            ><v-img
              src="./logo.png"
              max-width="140"
              style="margin: 10px"
            ></v-img></router-link
        ></v-col>
        <v-col
          ><p style="margin-top: 20px">{{ $t("GoZeit") }}</p></v-col
        >
      </v-row>
      <v-row>
        <sideMenu></sideMenu>
      </v-row>
    </v-navigation-drawer>

    <v-app-bar app ref="appBar">
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            @click="showSideBar = !showSideBar"
          ></v-app-bar-nav-icon>
        </template>
        <span>{{ $t("ShowOrHideNavigationMenu") }}</span>
      </v-tooltip>

      <menu-command
        CommandName="navigateDateBackward"
        IconName="mdi-calendar-arrow-left"
        :TooltipText="navigateBackwardMenuText"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="navigateDateForward"
        IconName="mdi-calendar-arrow-right"
        :TooltipText="navigateForwardMenuText"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="navigateToDate"
        IconName="mdi-calendar"
        :TooltipText="navigateToMenuText"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="refresh"
        IconName="mdi-refresh"
        :TooltipText="$t('RefreshData')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="recordTime"
        IconName="mdi-record"
        :TooltipText="$t('RecordTimeTooltip')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="stopTime"
        IconName="mdi-stop"
        :TooltipText="$t('StopTimeTooltip')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="undo"
        IconName="mdi-undo"
        :TooltipText="$t('Undo')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="save"
        IconName="mdi-content-save"
        :TooltipText="$t('Save')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="insertNewRow"
        IconName="mdi-plus"
        :TooltipText="$t('InsertNewRow')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="addNewRecord"
        IconName="mdi-plus"
        :TooltipText="$t('AddNewRecord')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="delete"
        IconName="mdi-minus"
        :TooltipText="$t('DeleteCurrentRow')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="exportToExcel"
        IconName="mdi-file-excel"
        :TooltipText="$t('ExportToExcel')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <v-menu
        :offset-y="true"
        open-on-hover
        v-if="canExportoExcel_NormalOrDetail === true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, action) in excelMenuItems_NormalOrDetail"
            :key="action"
            link
            @click="onExcelMenuClick(item.action)"
          >
            <v-list-item-icon class="me-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        :offset-y="true"
        open-on-hover
        v-if="canExportoExcel_SelectedOrAll === true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, action) in excelMenuItems_SelectedOrAll"
            :key="action"
            link
            @click="onExcelMenuClick(item.action)"
          >
            <v-list-item-icon class="me-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <menu-command
        CommandName="copyAsTemplateForNextWeek"
        IconName="mdi-table-arrow-right"
        :TooltipText="$t('CopyAsTemplateForNextWeek')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="resetPassword"
        IconName="mdi-lock-reset"
        :TooltipText="$t('ResetPassword')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="copyWorkTypesFromOtherUser"
        IconName="mdi-content-copy"
        :TooltipText="$t('CopyWorkTypesFromOtherUser')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="sizeColumnsToFit"
        IconName="mdi-table-column-width"
        :TooltipText="$t('SizeColumnsToFit')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <menu-command
        CommandName="openColumnSelectionDialog"
        IconName="mdi-table-cog"
        :TooltipText="$t('ShowOrHideColumns')"
        @Execute="onMenuCommandExecute($event)"
      >
      </menu-command>

      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="showHelpFile()"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("ShowHelp") }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-menu :offset-y="true" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            dark
            v-bind="attrs"
            v-on="on"
            @click="onUserAccountClick"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, action) in accountMenuItems"
            :key="action"
            link
            @click="onUserAccountMenuClick(item.action)"
          >
            <v-list-item-icon class="me-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view
          ref="routerview"
          @IsDirtyChanged="onIsDirtyChanged($event)"
          @ModeChanged="onModeChanged($event)"
          @SubComponentChanged="onSubComponentChanged($event)"
        >
        </router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <span class="px-4 footer">Version {{ $versionNo }}</span>
      <span class="px-4 footer"
        >{{ $t("LastSyncedOn") }}: {{ lastSyncedText }}</span
      >
      <span class="px-4 footer">{{ apiUrl }}</span>
      <v-spacer></v-spacer>
      <span class="px-4 footer">{{ $t("Client") }}: {{ $user.ClientName }}</span
      ><v-divider vertical></v-divider>
      <span class="px-4 footer"
        >{{ $t("Customer") }}: {{ $user.CustomerName }}</span
      ><v-divider vertical></v-divider>
      <span class="px-4 footer">{{ $t("User") }}: {{ $user.UserName }}</span
      ><v-divider vertical></v-divider>
      <span class="px-4 footer">{{ $t("Role") }}: {{ roleName }}</span>
    </v-footer>
  </v-app>
</template>
<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
html {
  overflow-y: auto;
} /* to avoid unnecessary scrollbar on the right, see https://stackoverflow.com/questions/56973002/vuetify-adds-scrollbar-when-its-not-needed" */
.footer {
  font-size: 14px !important;
  color: #2196f3;
}
</style>
<script>
import menuCommand from "./components/other/MenuCommand.vue";
import sideMenu from "./components/other/SideMenu.vue";
import UserAccountDialog from "./components/dialogs/UserAccountDialog.vue";

export default {
  name: "AppFrame",

  components: {
    sideMenu,
    menuCommand,
    UserAccountDialog,
  },

  data: () => ({
    navigateBackwardMenuText: "",
    navigateForwardMenuText: "",
    navigateToMenuText: "",
    sideMenuColor: null,
    accountMenuItems: [],
    excelMenuItems_NormalOrDetail: [],
    excelMenuItems_SelectedOrAll: [],
    clientName: "",
    customerName: "",
    roleName: "",
    showSideBar: true,
    event: "IsDirtyChanged",
    isDirty: false,
    apiUrl: "",
    canExportoExcel_NormalOrDetail: false,
    canExportoExcel_SelectedOrAll: false,
    lastSyncedText: "",
  }),

  created() {
    document.title = this.$t("GoZeit");
    this.setAppTheme(this.$gus.data.darkMode);
    this.$vuetify.lang.current = this.$gus.data.languageCode;
    this.setLocalizedRoleNames();
    if (this.$lastSyncTime.format) {
      this.lastSyncedText = this.$lastSyncTime.format("DD.MM.YYYY HH:mm");
    }
  },

  mounted() {
    this.accountMenuItems = this.getAccountMenuItems();
    this.excelMenuItems_NormalOrDetail =
      this.getExcelMenuItems_NormalOrDetail();
    this.excelMenuItems_SelectedOrAll = this.getExcelMenuItems_SelectedOrAll();
  },
  updated() {
    //updated after the route has changed
    const v = this.$refs.routerview;
    if (v != null) {
      if (v.initialize) {
        v.initialize();
      }
      if (v.getNavigateBackwardText)
        this.navigateBackwardMenuText = v.getNavigateBackwardText();
      if (v.getNavigateForwardText)
        this.navigateForwardMenuText = v.getNavigateForwardText();
      if (v.getNavigateToText) this.navigateToMenuText = v.getNavigateToText();
      this.updateCurrentComponentState();
    }
  },

  methods: {
    setAppTheme(darkMode) {
      this.$vuetify.theme.dark = darkMode;
      this.sideMenuColor = darkMode == true ? "#333" : "#e5e5e5";
    },

    setGridLanguage() {
      if (this.$refs.routerview && this.$refs.routerview.setGridLanguage) {
        this.$refs.routerview.setGridLanguage();
      }
    },

    setLocalizedRoleNames() {
      let localizedRoleNames = [];
      if (this.$user.Roles) {
        this.$user.Roles.forEach((role) => {
          localizedRoleNames.push(this.$t(role.RoleName));
        });
      }
      this.roleName =
        localizedRoleNames.length > 0 ? localizedRoleNames.join(", ") : "";
    },

    onMenuCommandExecute(e) {
      if (!this.$refs.routerview) return;
      this.$refs.routerview[e]();
    },

    onUserAccountClick() {
      this.openUserAccountDialog();
    },

    onUserAccountMenuClick(action) {
      if (action == "openUserAccount") this.openUserAccountDialog();
      else if (action == "logout") this.logout();
    },

    onExcelMenuClick(action) {
      if (action == "exportToNormalExcel")
        this.$refs.routerview.exportToNormalExcel();
      else if (action == "exportToDetailedExcel")
        this.$refs.routerview.exportToDetailedExcel();
      else if (action == "exportExcelForSelectedUser")
        this.$refs.routerview.exportExcelForSelectedUser();
      else if (action == "exportExcelForAllUsers")
        this.$refs.routerview.exportExcelForAllUsers();
    },

    updateCurrentComponentState() {
      if (!this.$refs.routerview) return;
      const appBar = this.$refs.appBar;
      appBar.$children.forEach((child) => {
        if (child.updateCanExecute != null)
          child.updateCanExecute(this.$refs.routerview);
      });
      this.canExportoExcel_NormalOrDetail =
        this.$refs.routerview.exportToNormalExcel != null ||
        this.$refs.routerview.exportToDetailedExcel != null;
      this.canExportoExcel_SelectedOrAll =
        this.$refs.routerview.canExportToSelectedOrAll &&
        this.$refs.routerview.canExportToSelectedOrAll() == true; //  utils.hasRoles(['Teamleader', 'Controller']) &&  this.$refs.routerview.exportExcelForSelectedUser != null || this.$refs.routerview.exportExcelForAllUsers != null;
    },

    onLoginSucceeded() {
      this.authenticated = true;
    },
    async logout() {
      if (this.$refs.routerview && this.$refs.routerview.saveUserSettings) {
        await this.$refs.routerview.saveUserSettings();
      }
      this.authenticated = false;
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
    showNotImplemented() {
      alert(process.env.VUE_APP_BASE_URL);
    },
    openUserAccountDialog() {
      if (!this.$refs.routerview) return;
      this.$refs.userAccountDialog.show();
    },
    onUserAccountOK() {
      if (!this.$refs.routerview) return;
      if (this.$refs.routerview.updateHeaders != null) {
        this.$refs.routerview.updateHeaders();
      }
      this.accountMenuItems = this.getAccountMenuItems(); //To force re-translation
    },
    getAccountMenuItems() {
      return [
        {
          action: "openUserAccount",
          title: this.$t("OpenUserAccount"),
          icon: "mdi-account",
        },
        { action: "logout", title: this.$t("Logout"), icon: "mdi-logout" },
      ];
    },
    getExcelMenuItems_NormalOrDetail() {
      return [
        {
          action: "exportToNormalExcel",
          title: this.$t("ExportToNormalExcel"),
          icon: "mdi-file-excel",
        },
        {
          action: "exportToDetailedExcel",
          title: this.$t("ExportToDetailedExcel"),
          icon: "mdi-file-excel",
        },
      ];
    },
    getExcelMenuItems_SelectedOrAll() {
      return [
        {
          action: "exportExcelForSelectedUser",
          title: this.$t("ExportToExcel_SelectedUser"),
          icon: "mdi-file-excel",
        },
        {
          action: "exportExcelForAllUsers",
          title: this.$t("ExportToExcel_AllUsers"),
          icon: "mdi-file-excel",
        },
      ];
    },

    onChangeGridTheme(darkMode) {
      this.setGridTheme(darkMode);
    },

    onChangeTheme(darkMode) {
      this.setAppTheme(darkMode);
    },

    setGridTheme(darkMode) {
      if (this.$refs.routerview && this.$refs.routerview.setGridTheme) {
        this.$refs.routerview.setGridTheme(darkMode);
      }
    },

    onChangeLanguage(languageCode) {
      this.setGridLanguage(languageCode);
      this.setLocalizedRoleNames(languageCode);
      this.$vuetify.lang.current = languageCode;
      this.excelMenuItems_NormalOrDetail =
        this.getExcelMenuItems_NormalOrDetail();
      this.excelMenuItems_SelectedOrAll =
        this.getExcelMenuItems_SelectedOrAll();
    },

    onSubComponentChanged() {
      this.updateCurrentComponentState();
    },
    onIsDirtyChanged(e) {
      this.isDirty = e;
      this.updateCurrentComponentState();
    },

    onModeChanged(e) {
      this.isDirty = e;
      this.updateCurrentComponentState();
    },

    showHelpFile() {
      window.open(
        `${process.env.VUE_APP_HELP_URL}/Help-${this.$i18n.locale}.pdf`,
        "_blank"
      );
    },
  },
  watch: {
    async $route() {
      if (this.$refs.routerview && this.$refs.routerview.saveUserSettings) {
        await this.$refs.routerview.saveUserSettings();
      }
      this.isDirty = false;
    },
  },
};
</script>
