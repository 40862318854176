<template>
  <v-dialog
    overlay-color="blue darken-3"
    v-model="dialog"
    scrollable
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("UserAccount") }}</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text style="height: 300px">
        <v-tabs>
          <v-tab>{{ $t("Settings") }}</v-tab>
        </v-tabs>
        <v-container class="my-2 px-0">
          <v-btn-toggle class="pb-4" dense v-model="languageIndex" mandatory>
            <v-btn @click="switchLanguage('de')">Deutsch</v-btn>
            <v-btn @click="switchLanguage('fr')">Français</v-btn>
            <v-btn @click="switchLanguage('en')">English</v-btn>
          </v-btn-toggle>
          <v-checkbox
            dense
            v-model="settings.data.darkMode"
            @change="onChangeMode($event)"
            :label="$t('DarkMode')"
          ></v-checkbox>
          <!-- <v-checkbox dense :value="gridDarkMode" @change="onChangeGridMode($event)" :label="$t('DataGridDarkMode')"></v-checkbox> -->
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="standard" @click="onCancel()">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn color="primary" @click="onOK()" width="100">
          {{ $t("OK") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
tr:hover {
  background-color: transparent !important;
}
</style>

<script>
import api from "@/api.js";
import Vue from "vue";

export default {
  name: "user-account-dialog",

  data: () => ({
    settings: {
      data: {},
    },
    dialog: false,
    languageIndex: 0,
    //languages: [],
  }),

  created() {
    this.languages = [
      { code: "de", text: "Deutsch" },
      { code: "fr", text: "FranÃ§ais" },
      { code: "en", text: "English" },
    ];
  },

  async mounted() {
    //this.user = await this.axios.get('user');
    this.dialog = false;
  },

  emits: {},

  computed: {},

  methods: {
    show() {
      this.settings = JSON.parse(JSON.stringify(this.$gus));
      const languageCode = this.settings.data.languageCode;
      this.languageIndex =
        languageCode == "de" ? 0 : languageCode == "fr" ? 1 : 2;
      this.switchLanguage(languageCode);
      this.changeMode(this.settings.data.darkMode);
      this.dialog = true;
    },

    async onOK() {
      const me = this;
      me.$emit("onOK", this.settings);
      me.settings.SettingValue = JSON.stringify(me.settings.data);
      const result = await api.post("UserSetting", me.settings);
      me.settings.UserSettingID = result.data.UserSettingID;
      me.$gus = me.settings;
      Vue.prototype.$gus = me.settings;
      Vue.prototype.$gridTheme = this.gridThemeName =
        this.$gus.data.darkMode == true
          ? "ag-theme-alpine-dark"
          : "ag-theme-alpine";
      me.dialog = false;
    },

    onCancel() {
      this.changeMode(this.$gus.data.darkMode);
      this.switchLanguage(this.$gus.data.languageCode);
      this.dialog = false;
    },

    changeMode(darkMode) {
      this.settings.data.darkMode = darkMode;
      this.$emit("changeTheme", darkMode);
      this.$emit("changeGridTheme", darkMode);
    },

    onChangeMode(e) {
      this.changeMode(e);
    },

    switchLanguage(languageCode) {
      this.settings.data.languageCode = languageCode;
      this.$i18n.locale = languageCode;
      this.$emit("changeGridLanguage", languageCode);
    },
  },
};
</script>