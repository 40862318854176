import moment from 'moment';

export default {
    template: `<span>{{ displayValue }}</span>`,
    data: () => ({
        displayValue: null
    }),
    beforeMount() {
        if (!this.params.value) {
            this.displayValue = "";
            return;
        }
        if (!Date.parse(this.params.value)) {
            this.displayValue = ""; //Hack for total caption
            return;
        }
        const f = moment(this.params.value).locale(this.params.colDef.locale).format('dddd');
        this.displayValue = f;

    },
};