import Vue from 'vue';
import VueRouter from 'vue-router';
import AppFrame from '@/AppFrame.vue';
import AppStart from '@/AppStart.vue';
import Login from '@/Login.vue';
import Home from '../views/Home.vue';
import EmployeeWeeklyReport from '../views/EmployeeWeeklyReport.vue';
import EmployeeDailyReport from '../views/EmployeeDailyReport.vue';
import ProjectDailyReport from '../views/ProjectDailyReport.vue';
import ProjectWeeklyReport from '../views/ProjectWeeklyReport.vue';
import ConstructionSiteReportView from '../views/ConstructionSiteReportView.vue';
import ManageEmployees from '../views/ManageEmployees.vue';
import WeekOverview from '../views/WeekOverview.vue';
import MonthOverview from '../views/MonthOverview.vue';
import EditEmployee from '../views/EditEmployee.vue';
import Holidays from '../views/Holidays.vue';
import GlobalWorkTypes from '../views/GlobalWorkTypes.vue';
import TimeRecording from '../views/TimeRecording.vue';
import TimeRecordingCorrection from '../views/TimeRecordingCorrection.vue';
import TimeRecordingMonthView from '../views/TimeRecordingMonthView.vue';
import ClientSettingsView from '../views/ClientSettingsView.vue';
import SetNewPassword from '../SetNewPassword.vue';
import SetNewPasswordExpired from '../SetNewPasswordExpired.vue';
import NewPasswordWasSet from '../NewPasswordWasSet.vue';
import PasswordForgotten from '../PasswordForgotten.vue';
import PageNotFound from '../PageNotFound.vue';
//import store from '../store';

Vue.use(VueRouter);

const roles = {
    admin: 1,
    controller: 2,
    teamleader: 3,
    recorder: 4,
};

const routes = [{
        path: '/',
        name: 'AppStart',
        component: AppStart,
    },
    {
        path: '/app',
        component: AppFrame,
        children: [{
                path: '',
                name: 'Home',
                component: Home,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'employeeWeeklyReport',
                name: 'EmployeeWeeklyReport',
                component: EmployeeWeeklyReport,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'employeeWeeklyReport/:userUID',
                name: 'EmployeeWeeklyReportByUser',
                component: EmployeeWeeklyReport,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'employeeDailyReport',
                name: 'EmployeeDailyReport',
                component: EmployeeDailyReport,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'projectDailyReport',
                name: 'ProjectDailyReport',
                component: ProjectDailyReport,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'projectWeeklyReport',
                name: 'ProjectWeeklyReport',
                component: ProjectWeeklyReport,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'constructionSiteReport',
                name: 'ConstructionSiteReport',
                component: ConstructionSiteReportView,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'constructionSiteReport/:userUID',
                name: 'ConstructionSiteReportByUser',
                component: ConstructionSiteReportView,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'weekOverview',
                name: 'WeekOverview',
                component: WeekOverview,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'monthOverview',
                name: 'MonthOverview',
                component: MonthOverview,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'manageEmployees',
                name: 'ManageEmployees',
                component: ManageEmployees,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'editEmployee/:userUID',
                name: 'EditEmployee',
                component: EditEmployee,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'holidays',
                name: 'Holidays',
                component: Holidays,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'globalWorkTypes',
                name: 'GlobalWorkTypes',
                component: GlobalWorkTypes,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'timeRecording',
                name: 'TimeRecording',
                component: TimeRecording,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'timeRecordingCorrection',
                name: 'TimeRecordingCorrection',
                component: TimeRecordingCorrection,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'timeRecordingMonthView',
                name: 'TimeRecordingMonthView',
                component: TimeRecordingMonthView,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin, roles.controller, roles.teamleader, roles.recorder],
                },
            },
            {
                path: 'clientSettings',
                name: 'ClientSettingsView',
                component: ClientSettingsView,
                meta: {
                    requiresAuth: true,
                    roles: [roles.admin],
                },
            },
        ]
    },
    {
        path: '/setNewPassword',
        name: 'SetNewPassword',
        component: SetNewPassword,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/setNewPasswordExpired',
        name: 'setNewPasswordExpired',
        component: SetNewPasswordExpired,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/newPasswordWasSet',
        name: 'newPasswordWasSet',
        component: NewPasswordWasSet,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/passwordForgotten',
        name: 'PasswordForgotten',
        component: PasswordForgotten,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        },
    },
    { path: "*", component: PageNotFound }
];

const router = new VueRouter({
    mode: 'history',
    hash: false,
    routes: routes,
});

function isAuthenticated() {
    const token = localStorage.getItem('token');
    return token != null;
    //const auth = store.state.auth.token && store.state.auth.user;
    //return auth;
}

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth === true &&
        !isAuthenticated()) next({
        name: 'Login'
    });
    else next();
});

export default router;