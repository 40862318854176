<template>
  <v-app>
    <login-form @loginSucceeded="onLoginSucceeded($event)"></login-form>

  </v-app>
</template>

<script>
import LoginForm from './components/forms/LoginForm/_Index.vue';

export default {
  name: 'Login',
    components: {
      LoginForm
    },
  props: {
  },
  mounted() {
  },
  methods: {
    onLoginSucceeded(tokenObject) {
      this.loadUserAndSettings(tokenObject);
    },

    async loadUserAndSettings(tokenObject) {
      localStorage.setItem('token', tokenObject.access_token);      
      this.$router.push('/');
    },


  },
};

</script>