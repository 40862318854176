//import api from '@/api.js';
export default {

    async initDataGrid() {
        const me = this;

        const dataGrid = this.$refs.dataGrid;
        if (dataGrid) {
            dataGrid.gridOptions.suppressPropertyNamesCheck = true;
            dataGrid.gridOptions.undoRedoCellEditing = true;
        }

        let columnInfoMap = {};

        me.defaultColumnDefs = {
            editable: true,
            resizable: true,
        };
        const columnDefs = [];
        columnDefs.push(me.createColumnDef(columnInfoMap, "UserName", "UserName", 250));

        let internalWorkColumnDef = me.createColumnDef(columnInfoMap, "InternalHours", "InternalHours", 140);
        internalWorkColumnDef.cellRendererFramework = "workRenderer";
        internalWorkColumnDef.type = "rightAligned";
        columnDefs.push(internalWorkColumnDef);

        let externalWorkColumnDef = me.createColumnDef(columnInfoMap, "ExternalHours", "ExternalHours", 140);
        externalWorkColumnDef.cellRendererFramework = "workRenderer";
        externalWorkColumnDef.type = "rightAligned";
        columnDefs.push(externalWorkColumnDef);

        let absenceColumnDef = me.createColumnDef(columnInfoMap, "AbsenceHours", "AbsenceHours", 140);
        absenceColumnDef.cellRendererFramework = "workRenderer";
        absenceColumnDef.type = "rightAligned";
        columnDefs.push(absenceColumnDef);

        let totalWorkColumnDef = me.createColumnDef(columnInfoMap, "Workhours", "TotalHours", 140);
        totalWorkColumnDef.cellRendererFramework = "workRenderer";
        totalWorkColumnDef.type = "rightAligned";
        columnDefs.push(totalWorkColumnDef);

        let targetHoursColumnDef = me.createColumnDef(columnInfoMap, "TargetHours", "TargetHours", 140);
        targetHoursColumnDef.cellRendererFramework = "workRenderer";
        targetHoursColumnDef.type = "rightAligned";
        columnDefs.push(targetHoursColumnDef);

        let expensesColumnDef = me.createColumnDef(columnInfoMap, "Expenses", "Expenses", 120)
        expensesColumnDef.cellRendererFramework = "expensesRenderer";
        expensesColumnDef.type = "rightAligned";
        columnDefs.push(expensesColumnDef);
        me.columnDefs = columnDefs;
    },

    createColumnDef(columnInfoMap, columnName, field, defaultWidth, defaultVisibility) {
        if (!defaultVisibility) defaultVisibility = true;
        const me = this;
        let columnDef = {
            field: field,
            columnName: columnName,
            headerName: me.$t(columnName),
            editable: false,
            sortable: true,
            filterable: true,
            hide: !me.getColumnInfoValue(columnInfoMap, columnName, 'visible', defaultVisibility),
            resizable: true
        }
        columnDef.width = me.getColumnInfoValue(columnInfoMap, columnName, 'width', defaultWidth);
        return columnDef;
    },


    getColumnInfoValue(map, columnName, propertyName, defaultValue) {
        let info = map[columnName];
        if (info == null || info[propertyName == null]) {
            return defaultValue;
        } else {
            return map[columnName][propertyName];
        }
    },

}