export default {

    formatWorkOrExpenses(params) {
        const me = this;
        let suffix = '';
        if ((params.node.rowPinned)) {
            params.editable = false;
            suffix = me.getUnitSuffix(params.data, true);
        } else {
            suffix = me.getUnitSuffix(params.data, false);
        }
        if (params.value == null || params.value == "") return "";
        if (params.value) return parseFloat(params.value).toFixed(2) + suffix;
        return "";
    },

    getUnitSuffix(data, isTotalLine) {
        const me = this;
        if (data.WorkCategoryID != 4) return " " + data.WorkHourSuffix;
        const costFactor = me.getCostfactor(data.WorkTypeUID);
        if (isTotalLine == true || costFactor == 1) return " " + data.ExpenseSuffix;
        return "";
    },

    getCostfactor(workTypeUID) {
        if (!this.workTypes) return 1;
        const x = this.workTypes.find(x => x.WorkTypeUID == workTypeUID);
        if (!x) return 1;
        return x.CostFactor;
    },

    toDecimal(x) {
        return (!x || x == null) ? 0 : parseFloat(x);
    }

}