<template>
  <global-workTypes-list
    ref="globalWorkTypesList"
    @IsDirtyChanged="onIsDirtyChanged($event)"
    >
  </global-workTypes-list>


</template>
<style scoped>
  .v-data-table__selected {
    background-color: transparent;
  }
</style>
<script>
  import GlobalWorkTypesList from '@/components/forms/GlobalWorkTypesList/_Index.vue';
  import viewHelpers from '../common/viewHelpers.js';

  export default {
    name: 'global-workTypes',
        
    data: () => ({
      isDirty: false,
    }),

    beforeRouteLeave (to, from, next) {
      this.confirmChangesBeforeNext(this.isDirty, next);
    },  

    methods: {
      ...viewHelpers,

      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },      

      async refresh() {
        this.$refs.globalWorkTypesList.refresh();
      },
      
      async save(nextPageCallBack) {
        this.$refs.globalWorkTypesList.save(nextPageCallBack);
      },

      async undo() {
        this.$refs.globalWorkTypesList.undo();
      },

      onIsDirtyChanged(isDirty){
        this.isDirty = isDirty;
        this.$emit("IsDirtyChanged", isDirty);
      },

      async setGridLanguage() {
        this.$refs.globalWorkTypesList.setGridLanguage();
      },
      
    },
    
    components: {
      GlobalWorkTypesList
    },

  }
  
</script>
