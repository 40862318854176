<template>
  <holiday-list 
    ref="holidayList"
    userSettingsKey="HolidayList"
    >
  </holiday-list>
</template>
<style lang="scss">
</style>
<script>
  import HolidayList from '@/components/forms/HolidayList/_Index.vue';

  export default {
    name: 'ManageHolidays',

    methods: {

      async refresh() {
        this.$refs.holidayList.refresh();
      },
      
      async addNewRecord() {
        this.$refs.holidayList.addNewRecord();
      },

      async setGridLanguage() {
        this.$refs.holidayList.setGridLanguage();
      },

    },

    components: {
      HolidayList
    },

  }
  
</script>
