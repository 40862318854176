<template>
  <v-app>
  <v-container class="fill-height" fluid>  
    <v-row align="center" justify="center">
      <v-col cols="12" lg="5" sm="10" md="8">
        <v-btn-toggle class="pb-4" v-if="step==1"
            v-model="languageIndex"
            mandatory
            >
            <v-btn @click="switchLanguage('de')">
                Deutsch
            </v-btn>
            <v-btn @click="switchLanguage('fr')">
                Français
            </v-btn>
            <v-btn @click="switchLanguage('en')">
                English
            </v-btn>
        </v-btn-toggle>

        <v-form ref="form" style="max-width:600px" v-model="valid" v-if="step==1">
          <v-card class="elevation-12">
            <v-card-title>            
                <v-layout justify-space-between>
                    <span>{{$t("GoZeit")}}</span>
                    <span style="font-size:14px">{{$t("PasswordForgotten_Title")}}</span>
                    <v-img src="./logo.png" max-width="140"></v-img>            
                </v-layout>                                
            </v-card-title>
            <v-card-text>
            <p>{{$t("PasswordForgotten_Subtitle")}}</p>
              <v-container dense="true">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="txtEmail"
                      v-model="email"
                      class="mt-4"
                      :rules="emailRules"
                      required
                      @change="onChange"
                      :label="$t('YourEmail')"
                      :dense="false"
                      :error-messages="serverError"
                      :outlined="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="!inProgress"
                :disabled="!valid || email.length == 0"
                color="primary"
                class="mr-4 mb-4"
                @click="setNewPassword()"
              >{{$t("RequestPasswordReset")}}</v-btn>
            <span v-if="inProgress">{{$t("CheckingEmailAddress")}}</span>
            <v-progress-circular v-if="inProgress"
              indeterminate
              color="primary"
            ></v-progress-circular>            
            </v-card-actions>
          </v-card>
        </v-form>
        <div v-if="step==2" class="ma-2">
          <span>Thank you.</span>
          <p style="margin-top:10px">            
            <span>An Email with a link to change your password has been sent to:</span>
            <br/>
            <b>{{email}}</b>
          </p>          
        </div>
      </v-col>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>
import api from '@/api.js';

export default {
  name: 'SetNewPassword',
  props: {
    changePwdToken: String,
  },
  data() {
    return {
      step: 1,
      email: '',
      languageIndex: 0,
      valid: false,
      emailRules: [],      
      emailExists: true,
      serverError: '',
      inProgress: false
    };
  },
  components: {},
  mounted() {
    this.createValidationRules();
    this.$nextTick(this.clearFields());
  },
  methods: {


    onChange() {
      this.serverError = "";
    },
    createValidationRules() {
      //this.emailRules.push(v => !!v || this.$t("FieldIsRequired"));
      this.emailRules.push(v => !!v || this.$t("FieldIsRequired"));
      this.emailRules.push(v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("InvalidEmail"));
      //this.emailRules.push(v => !this.doesEmailExist(v) || this.$t("EmailDoesNotExist"));
    },

    clearFields() {
      const me = this;      
      setTimeout(() => {
        me.$refs.txtEmail.focus();
      }, 200);
    },

    switchLanguage(languageCode) {
        this.languageCode = languageCode;
        this.$i18n.locale = languageCode;
        localStorage.setItem('loginLanguage', this.languageCode);
    },


    async setNewPassword() {

      this.$refs.form.validate();
      if (this.valid == false) {
        return;
      }
      let failed = false;
      try
      {
        this.inProgress = true;
        await api.post("Password/Reset/SelfService", {
          EMail: this.email,
          ChangePasswordUrl: "http://localhost:8080/SetnewPassword/" //TODO
        });
      }
      catch(e) {
        failed = true;
        this.serverError = this.$t("EmailDoesNotExistInDb");
        this.valid = false;
        console.log(e);
      }
      this.inProgress = false;
      if (! failed) this.step = 2;
    },


  },
};
</script>
