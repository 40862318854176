<template>
  <client-settings-dialog
    ref="clientSettingsDialog"
    >
  </client-settings-dialog>
</template>
<style lang="scss">
</style>
<script>
import ClientSettingsDialog from '../components/dialogs/ClientSettingsDialog.vue';

  export default {
    name: 'ClientSettingsView',
    data: () => ({
    }),

    mounted() {     
      this.$refs.clientSettingsDialog.show();
    },

    methods: {

    },
    
    components: {
      ClientSettingsDialog
    },

  }
  
</script>
