<template>
  <employee-list 
    ref="employeeList"
    userSettingsKey="EmployeeList"
    :title="$t('ManageEmployees')"
    >
  </employee-list>
</template>
<style lang="scss">
</style>
<script>
  import EmployeeList from '@/components/forms/EmployeeList/_Index.vue';

  export default {
    name: 'ManageEmployees',

    methods: {

      async refresh() {
        this.$refs.employeeList.refresh();
      },
      async openColumnSelectionDialog() {
        this.$refs.employeeList.openColumnSelectionDialog();
      },
      async loadUserSettings() {
        this.$refs.employeeList.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.employeeList.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.employeeList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.employeeList.setGridLanguage();
      },
      async sizeColumnsToFit() {
        this.$refs.employeeList.sizeColumnsToFit();
      },
      
    },
    
    components: {
      EmployeeList
    },

  }
  
</script>
