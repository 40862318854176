<template>
    <v-container fluid fill-height>
        <v-layout flex align-center justify-center>
        <v-flex xs12 sm4 elevation-12>
            <v-card class="pa-10">
            
            <v-card-title>            
                <v-layout justify-space-between>
                    <span>{{$t("GoZeit")}}</span>
                    <v-img src="../../../logo.png" max-width="140"></v-img>            
                </v-layout>                
            </v-card-title>
            <v-card-subtitle>Version {{$versionNo}}</v-card-subtitle>
            <v-card-text class="pt-4">
            <v-btn-toggle class="pb-4" v-if="step1" 
                v-model="languageIndex"
                mandatory
                >
                <v-btn @click="switchLanguage('de')">
                    Deutsch
                </v-btn>
                <v-btn @click="switchLanguage('fr')">
                    Français
                </v-btn>
                <v-btn @click="switchLanguage('en')">
                    English
                </v-btn>
            </v-btn-toggle>
            <v-checkbox v-model="darkMode" @change="onChangeMode($event)" :label="$t('DarkMode')"  v-if="step1" ></v-checkbox>
                <table class="table" style="width:100%" v-if="step1">
                <tr>
                    <td>
                        <span class="pr-4">
                            {{$t('CustomerName')}}:
                        </span>
                    </td>
                    <td>
                        <v-text-field ref="customerField" class="pa-0"
                            v-model="customerName"
                            :clearable="true"
                            :error-messages="customerError"
                            @change="onCustomerChange"
                            @keypress="onCustomerKeyPress($event)"
                            :hint="$t('Hint_PleaseEnterTheCustomerName')"                             
                        ></v-text-field>
                    </td>
                </tr>
                </table>
                <table style="width:100%" v-if="step2" >
                    <tr>
                        <td><span class='label'>{{$t('CustomerName')}}:</span></td>
                        <td>
                            <h3>{{customerName}}</h3>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height:20px"></td>
                    </tr>
                    <tr>
                        <td class="label"> 
                            <span>{{$t('Client')}}:</span>
                        </td>
                        <td>
                            <v-select ref="clientField" class="pa-0 ma-0"
                                item-text="ClientName"
                                item-value="ClientName"
                                :items="clients"
                                v-model="client"
                                return-object
                                >
                            </v-select>
                            <span style="color:red" v-if="showClientNotSelectedError">{{clientNotSelectedError}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">
                            <span>{{$t('User')}}:</span>                                
                        </td>
                        <td>
                            <v-text-field ref="userField" 
                                v-model="userName" class="pa-0 ma-0"
                                @keydown="onUserKeyDown($event)"
                                :error-messages="loginError"
                                >
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">
                            <span>{{$t('Password')}}:</span>
                        </td>
                        <td>
                            <v-text-field class="pa-0 ma-0"
                                min="8"                        
                                type="password"
                                @keydown="onPasswordKeyDown($event)"
                                :error-messages="loginError"
                                v-model="password"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>
                <v-btn class="mt-8" color="primary" v-if="step1" @click="onNext">
                    <v-icon>mdi-arrow-right</v-icon>&nbsp;
                {{$t('Continue')}}
                </v-btn>
                <v-btn class="mt-8 mr-4" color="standard" v-if="step2" @click="onBack">
                    <v-icon>mdi-arrow-left</v-icon>&nbsp;
                    {{$t('Back')}}
                </v-btn>
                <v-btn class="mt-8" color="primary" v-if="step2" @click="onLogin">
                    <v-icon>mdi-login</v-icon>&nbsp;
                    {{$t('Login')}}
                </v-btn>
            </v-card-text>
            <a style="margin-top:12px;margin-left:16px" @click="onForgotPasswordClick"  v-if="step2">        
                {{$t('PasswordForgotten_Title')}}
            </a>            
            </v-card>

        </v-flex>
        </v-layout>
    </v-container>
 </template>
 <style lang="css">
    .label {
        padding-bottom: 15px;
    }
 </style> 
<script>
import Vue from 'vue';
import methods from './methods.js';

export default {    
  name: 'login-form',
    data() {
        return {
            customerName: '',
            client: null,
            userName: '',
            password: '',
            languageIndex:0,
            languageCode: '',
            step1: true,
            step2: false,
            clients: [],
            customerError: '',
            loginError: '',
            clientNotSelectedError: '',
            showClientNotSelectedError: false,
            darkMode: true,
        };
    },
    created() {        
    },
    mounted() {
        const me = this;
        me.clientNotSelectedError = me.$t("ClientNeedsToBeSelected");
        me.darkMode = localStorage.getItem('loginDarkMode') == null ? true : localStorage.getItem('loginDarkMode') == 'true';
        me.languageCode = localStorage.getItem('loginLanguage') == null ? 'de' : localStorage.getItem('loginLanguage');    
        me.languageIndex = this.languageCode == 'de' ? 0 : this.languageCode == 'fr' ? 1: 2;
        me.switchLanguage(this.languageCode);
        me.$vuetify.theme.dark = this.darkMode;
        me.customerName = localStorage.getItem("loginCustomer");
        me.step1 = true;
         Vue.nextTick(() => {
             me.$refs.customerField.focus();
         });
    },
    methods: {
        ...methods
    },
};
</script>
