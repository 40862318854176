<template>  
  <project-report 
    ref="projectReport"
    userSettingsKey="ProjectDailyReport"
    :title="$t('ProjectDailyReport')"
    :showOnlyOneDay="true"
    @IsDirtyChanged="onIsDirtyChanged($event)"
    >
  </project-report>
</template>
<script>
  import ProjectReport from '@/components/forms/ProjectReport/_Index.vue';
  import moment from 'moment';
  import viewHelpers from '../common/viewHelpers.js';

  export default {
    
    name: 'ProjectWeeklyReportView',

    data: () => ({
      isDirty: false,
      mondayDate: moment('20170619') //TODO: Replace by current date or date stored in user settings
    }),


    beforeRouteLeave (to, from, next) {
      this.confirmChangesBeforeNext(this.isDirty, next);
    },  
    
    methods: {
      ...viewHelpers,

      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },      

      onIsDirtyChanged(isDirty){
        this.isDirty = isDirty;
        this.$emit("IsDirtyChanged", isDirty);
      },

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevDay");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextDay");
      },

      getNavigateToText() {
        return this.$t("NavigateToDay");
      },

      async navigateDateBackward() {
        this.$refs.projectReport.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.projectReport.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.projectReport.navigateToDate();
      },
      async refresh() {
        this.$refs.projectReport.refresh();
      },
      async insertNewRow() {
        this.$refs.projectReport.insertNewRow();
      },
      async delete() {
        this.$refs.projectReport.delete();
      },
      async undo() {
        this.$refs.projectReport.undo();
      },
      async save(nextPageCallBack) {
        this.$refs.projectReport.save(nextPageCallBack);
      },
      async openColumnSelectionDialog() {
        this.$refs.projectReport.openColumnSelectionDialog();
      },
      async loadUserSettings() {
        this.$refs.projectReport.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.projectReport.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.projectReport.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.projectReport.setGridLanguage();
      },
      async sizeColumnsToFit() {
        this.$refs.projectReport.sizeColumnsToFit();
      },
      
    },

    components: {
      ProjectReport
    },
  }
</script>
