<template>
  <v-dialog
    overlay-color="blue darken-3"
    v-model="dialog"
    scrollable
    max-width="420px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("GlobalSettings") }}</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text style="height: 220px">
        <v-container class="my-2 px-0">
          <table class="pa-0 ma-0">
            <tr>
              <td class="pr-4">{{ $t("MaxBookingTextLength") }}:</td>
              <td>
                <v-text-field
                  ref="textField"
                  v-model="settings.data.maxBookingText"
                  class="ma-0 pa-0 mt-4 right-align"
                  style="font-size: 13px; max-width: 40px"
                  dense
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                {{ $t("MaxBookingTextLength_Description") }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                {{ $t("NoMaxBookingTextValidationIfZero") }}
              </td>
            </tr>
          </table>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="standard" @click="onCancel()">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn color="primary" @click="onOK()" width="100">
          {{ $t("OK") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
tr:hover {
  background-color: transparent !important;
}
.right-align * input {
  text-align: right;
}
</style>

<script>
import api from "@/api.js";
import Vue from "vue";

export default {
  name: "client-settings-dialog",

  data: () => ({
    settings: {
      data: {
        maxBookingText: 30,
      },
    },
    dialog: false,
    //languages: [],
  }),

  mounted() {
    const me = this;
    me.dialog = false;
    setTimeout(() => {
      me.$refs.textField.focus();
    }, 100);
  },

  emits: {},

  computed: {},

  methods: {
    show() {
      this.settings = JSON.parse(JSON.stringify(this.$gcs));
      this.dialog = true;
    },

    async onOK() {
      const me = this;
      me.settings.SettingValue = JSON.stringify(me.settings.data);
      const result = await api.post("ClientSetting", me.settings);
      me.settings.ClientSettingID = result.data.ClientSettingID;
      me.$gcs = me.settings;
      Vue.prototype.$gcs = me.settings;
      me.dialog = false;
    },

    onCancel() {
      this.dialog = false;
    },
  },
};
</script>