<template>
  <v-container fluid>
    <span class="ml-2">{{ $t("PublicHolidays") }}</span>
    <v-data-table
      class="elevation-1 mt-4"
      dense
      :headers="headers"
      :items="records"
      item-key="HolidayDate"
      display-text="Description"
      @click:row="onClickRow($event)"
      :items-per-page="itemsPerPage"
      hide-default-footer
      v-if="loaded"
    >
      <template slot="no-data">
        {{ $t("NoRowsFound") }}
      </template>
      <template v-slot:[`item.HolidayDate`]="{ item }">
        <span>{{ formatDate(item.HolidayDate, item.IsFixType) }}</span>
      </template>
      <template v-slot:[`item.IsFixType`]="{ item }">
        <v-icon small v-if="item.IsFixType == true">mdi-check</v-icon>
      </template>
    </v-data-table>
    <v-sheet class="pa-3" v-else>
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </v-sheet>

    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 230px">
          <v-checkbox
            :label="$t('IsFixType')"
            v-model="record.IsFixType"
            required
            class="mt-0"
            style="max-width: 200px"
            @change="onFixTypeChange($event)"
          ></v-checkbox>
          <table>
            <tr>
              <td>
                <v-text-field
                  v-if="record.IsFixType == true"
                  ref="txtDay"
                  :min="minDay"
                  :max="maxDay"
                  type="number"
                  v-model="day"
                  :label="$t('Day')"
                  style="max-width: 40px"
                  @change="onDayChanged($event)"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-if="record.IsFixType == true"
                  ref="txtMonth"
                  type="number"
                  v-model="month"
                  :min="1"
                  :max="12"
                  :label="$t('Month')"
                  style="max-width: 40px"
                ></v-text-field>
              </td>
            </tr>
          </table>
          <v-text-field
            v-if="record.IsFixType == false"
            ref="txtDate"
            type="date"
            format="DD.MM.YYYY"
            v-model="record.HolidayDate"
            :label="$t('Date')"
            @change="onDateChange($event)"
          ></v-text-field>
          <v-text-field
            :label="$t('Description')"
            v-model="record.Description"
            required
            style="max-width: 400px"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="warning" @click="onDelete()">
            {{ $t("Delete") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
</style>
<script>
import api from "@/api.js";
import utils from "@/common/utils.js";
import moment from "moment";

export default {
  name: "holiday-list",

  props: {
    userUID: String,
  },

  components: {},

  data: () => ({
    loaded: false,
    dialog: false,
    itemsPerPage: -1,
    records: [],
    originalRecord: {},
    record: {},
    headers: [],
    title: null,
    day: null,
    minDay: 1,
    maxDay: 31,
  }),

  async created() {
    const me = this;
    me.headers = me.createHeaders();
    me.refresh();
  },

  methods: {
    async refresh() {
      const me = this;
      me.loaded = false;
      const res = await api.get(`Holiday/All`);
      res.data.forEach((item) => {
        item.HDate = new Date();
      });
      me.records = res.data;
      me.loaded = true;
    },

    onDayChanged(e) {
      if (e > 31) this.day = 31;
      if (e <= 0) this.day = 1;
    },

    formatDate(value, isFixType) {
      if (isFixType == true) return moment(value).format("DD.MM");
      return moment(value).format("DD.MM.YYYY");
    },

    formatYesNo(value) {
      return this.$t(value);
    },

    addNewRecord() {
      const me = this;
      me.record = {
        HolidayDate: null,
        Day: null,
        Month: null,
        IsFixType: false,
        ClientUID: null, //TODO: Set via prop
        Description: "",
      };
      this.originalRecord = {};
      this.title = this.$t("AddNewPublicHoliday");
      this.dialog = true;
      setTimeout(() => {
        this.$refs.txtDate.focus();
      }, 100);
    },

    async onOK() {
      const me = this;
      if (me.record.IsFixType == true) {
        me.record.HolidayDate = moment({
          year: moment().year(),
          month: this.month - 1,
          day: this.day,
        }).format("YYYY-MM-DD");
      }
      await api.post("holiday", me.record);
      me.refresh();
      this.dialog = false;
    },

    onCancel() {
      this.dialog = false;
    },

    async onDelete() {
      const me = this;
      me.$confirm(me.$t("Msg_Confirm_DeleteRow_Text"), {
        title: this.$t("Msg_Confirm_DeleteRow_Title"),
        buttonTrueText: me.$t("Yes"),
        buttonFalseText: me.$t("No"),
      }).then((res) => {
        if (res == true) {
          api.delete("holiday", { data: me.record }).then(() => {
            me.refresh();
            me.dialog = false;
          });
        }
      });
    },

    onFixTypeChange(e) {
      if (e == false) {
        var tempDate = moment(this.record.HolidayDate).year(
          new Date().getFullYear()
        );
        this.record.HolidayDate = tempDate.format("YYYY-MM-DD");
      }
    },

    onDateChange() {
      this.day = parseInt(moment(this.record.HolidayDate).format("DD"));
      this.month = parseInt(moment(this.record.HolidayDate).format("MM"));
    },

    onClickRow(e) {
      this.originalRecord = e;
      this.record = utils.deepClone(e);
      this.day = parseInt(moment(this.record.HolidayDate).format("DD"));
      this.month = parseInt(moment(this.record.HolidayDate).format("MM"));
      setTimeout(() => {
        this.$refs.txtDate.focus();
      }, 50);
      this.title = this.$t("EditPublicHoliday");
      this.dialog = true;
    },

    setGridLanguage() {
      this.updateHeaders();
    },

    updateHeaders() {
      this.headers.forEach((header) => {
        header.text = this.$t(header.name);
      });
    },

    createHeaders() {
      const me = this;
      const headers = [
        {
          name: "Date",
          text: me.$t("Date"),
          align: "start",
          sortable: true,
          value: "HolidayDate",
          width: 120,
        },
        {
          name: "IsFixType",
          text: me.$t("IsFixType"),
          align: "start",
          sortable: true,
          value: "IsFixType",
          width: 170,
        },
        {
          name: "Description",
          text: me.$t("Description"),
          align: "start",
          sortable: true,
          value: "Description",
        },
      ];
      return headers;
    },
  },
};
</script>
