<template>  
  <construction-site-report 
    ref="constructionSiteReport"
    userSettingsKey="ConstructionSiteReport"
    :title="$t('ConstructionSiteReport')"
    :showOnlyOneDay="false"
    @IsDirtyChanged="onIsDirtyChanged($event)"
    >
  </construction-site-report >
</template>
<script>
  import ConstructionSiteReport from '@/components/forms/ConstructionSiteReport/_Index.vue';
  import viewHelpers from '../common/viewHelpers.js';

  export default {
    
    name: 'ConstructionSiteReportView',

    data: () => ({
      isDirty: false,
    }),

    beforeRouteLeave (to, from, next) {
      this.confirmChangesBeforeNext(this.isDirty, next);
    },  
    
    methods: {

      ...viewHelpers,

      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },      

      onIsDirtyChanged(isDirty){
        this.isDirty = isDirty;
        this.$emit("IsDirtyChanged", isDirty);
      },

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevWeek");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextWeek");
      },

      getNavigateToText() {
        return this.$t("NavigateToPrevWeek");
      },

      async navigateDateBackward() {
        this.$refs.constructionSiteReport.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.constructionSiteReport.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.constructionSiteReport.navigateToDate();
      },
      async refresh() {
        this.$refs.constructionSiteReport.refresh();
      },
      async undo() {
        this.$refs.constructionSiteReport.undo();
      },
      async save(nextPageCallBack) {
        this.$refs.constructionSiteReport.save(nextPageCallBack);
      },
      async openColumnSelectionDialog() {
        this.$refs.constructionSiteReport.openColumnSelectionDialog();
      },
      async loadUserSettings() {
        this.$refs.constructionSiteReport.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.constructionSiteReport.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.constructionSiteReport.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.constructionSiteReport.setGridLanguage();
      },
      async sizeColumnsToFit() {
        this.$refs.constructionSiteReport.sizeColumnsToFit();
      },
      
    },
    
    components: {
      ConstructionSiteReport
    },
  }
</script>
