<template>
  <time-recording-month-list ref="timeRecordingMonthList" 
    :currentDate="mondayDate"    
    >
  </time-recording-month-list>
</template>
<style lang="scss">
</style>
<script>
import TimeRecordingMonthList from '@/components/forms/TimeRecordingMonthList/_Index.vue'
import moment from 'moment';

export default {
    name: 'timerecording',

    data: () => ({
      mondayDate: moment() //TODO: Replace by current date or date stored in user settings
    }),

    mounted() {
      this.$refs.timeRecordingMonthList.refresh();
    },

    methods: {

      initialize() {
        this.$refs.timeRecordingMonthList.initialize();
      },

      refresh() {
        this.$refs.timeRecordingMonthList.refresh();
      },

      navigateDateBackward()
      {
        this.$refs.timeRecordingMonthList.navigateDateBackward();
      },

      navigateDateForward()
      {
        this.$refs.timeRecordingMonthList.navigateDateForward();
      },

      navigateToDate()
      {
        this.$refs.timeRecordingMonthList.navigateToDate();
      },

      async exportToExcel()
      {
        this.$refs.timeRecordingMonthList.exportToExcel();
      },

      async setGridTheme(darkMode) {
        this.$refs.timeRecordingMonthList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.timeRecordingMonthList.setGridLanguage();
      },


    },
    components: {
      TimeRecordingMonthList
    }

}

</script>
