import moment from 'moment';

export default {
    template: `<span>{{ displayValue }}</span>`,
    data: () => ({
        displayValue: null
    }),
    beforeMount() {

        if (!this.params.value) {
            this.displayValue = "";
            return;
        }
        if (!Date.parse(this.params.value)) {
            this.displayValue = this.params.value; //Hack for Total caption
            return;
        }

        const f = moment(this.params.value).format('DD.MM.YYYY HH:mm');
        this.displayValue = f;

    },
};