<template>
  <v-row justify="center" class="pa-0 ma-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="searchText"
          class="ma-0 pa-0 mt-4"
          style="width: 100px; font-size: 13px;"
          append-icon="mdi-card-search"
          clearable
          clear-icon="mdi-close-circle-outline"
          dense
          v-bind="attrs"
          v-on="on"
          @click:append="openSearchDialog()"
          @click:clear="onClearClick()"
        ></v-text-field>
      </template>
      <span>{{ structureName }}</span>
    </v-tooltip>
    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-card-search</v-icon>
          <span class="ml-4">{{ $t('SelectStructure') }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-subtitle class="pb-0 mb-0">
          <v-text-field
            class="mt-8"
            ref="searchTextField"
            v-model="dialogSearchText"
            outlined
            :label="$t('Search')"
            clearable
            clear-icon="mdi-close-circle-outline"
            @keyup="onSearchDialogKeyDown($event)"
            @click:append="openSearchDialog()"
          ></v-text-field>
        </v-card-subtitle>
        <v-card-text style="height: 500px;" class="px-3 py-0 ma-0">
          <v-container>
            <v-treeview
              ref="vtreeView"
              open-all
              class="mt-0"
              item-key="StructureUID"
              item-text="StructureName"
              dense
              activatable
              :search="searchText"
              :filter="filter"
              :active.sync="treeValues"
              :items="items"
            ></v-treeview>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t('OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style>
.v-input__append-outer {
  margin-left:0px!important
}
</style>
<script>
import methods from './methods.js'

export default {
  name: 'structure-lookup-box',
  data() {
    return {
      structureName: this.$t('NoStructureSelected'),
      searchText: '',
      dialogSearchText: '',
      dialog: false,
      treeValues: [],
      selectedItem: null,
      items: []
    }
  },
  props: {
    projectUID: Object,
    itemsFlat: Array
  },
  methods: {
    ...methods,
  },
  mounted() {
    this.onMounted()
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].includes(search)
        : undefined
    },
  },
}
</script>
