<template>
  <v-app>
  <v-container class="fill-height" fluid >
    <v-row align="center"
        justify="center">
      <span>{{$t("PasswordForgotten_SetPasswordExpired")}}</span>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'set-new-password-expired',

};
</script>