<template>  
  <EmployeeReport 
    ref="employeeReport"
    userSettingsKey="EmployeeDailyReport"
    :title="$t('EmployeeDailyReport')"
    :currentDate="mondayDate"
    :mondayDate="mondayDate"
    :showOnlyOneDay="true"
    @IsDirtyChanged="onIsDirtyChanged($event)"
    >
  </EmployeeReport>
</template>
<script>
  import EmployeeReport from '@/components/forms/EmployeeReport/_Index.vue';
  import moment from 'moment';
  import viewHelpers from '../common/viewHelpers.js';

  export default {
    
    name: 'EmployeeDailyReport',

    data: () => ({
      isDirty: false,
      mondayDate: moment('20170619') //TODO: Replace by current date or date stored in user settings
    }),

    beforeRouteLeave (to, from, next) {
      this.confirmChangesBeforeNext(this.isDirty, next);
    },  

    methods: {
      ...viewHelpers,
      
      save_isDisabled() {
        return this.isDirty == false;
      },

      undo_isDisabled() {
        return this.isDirty == false;
      },

      onIsDirtyChanged(e){
        this.isDirty = e;
        this.$emit("IsDirtyChanged", e);
      },

      getNavigateBackwardText() {
        return this.$t("NavigateToPrevDay");
      },

      getNavigateForwardText() {
        return this.$t("NavigateToNextDay");
      },

      getNavigateToText() {
        return this.$t("NavigateToDay");
      },


      async navigateDateBackward() {
        this.$refs.employeeReport.navigateDateBackward();
      },
      async navigateDateForward() {
        this.$refs.employeeReport.navigateDateForward();
      },
      async navigateToDate() {
        this.$refs.employeeReport.navigateToDate();
      },
      async refresh() {
        this.$refs.employeeReport.refresh();
      },
      async insertNewRow() {
        this.$refs.employeeReport.insertNewRow();
      },
      async delete() {
        this.$refs.employeeReport.delete();
      },
      async undo() {
        this.$refs.employeeReport.undo();
      },
      async save(nextPageCallBack) {
        this.$refs.employeeReport.save(nextPageCallBack);
      },

      insertNewRow_isInvisible() {
        return this.$refs.employeeReport.hasRoles(['Teamleader', 'Typist']) == false;
      },      

      delete_isInvisible() {
        return this.$refs.employeeReport.hasRoles(['Teamleader', 'Typist']) == false;
      },         

      canExportToSelectedOrAll() {
        return false;
      },

      exportToExcel_isInvisible() {
        return true;
      },

      async openColumnSelectionDialog() {
        this.$refs.employeeReport.openColumnSelectionDialog();
      },
      async loadUserSettings() {
        this.$refs.employeeReport.loadUserSettings();
      },
      async saveUserSettings() {
        this.$refs.employeeReport.saveUserSettings();
      },
      async setGridTheme(darkMode) {
        this.$refs.employeeReport.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.employeeReport.setGridLanguage();
      },
      async sizeColumnsToFit() {
        this.$refs.employeeReport.sizeColumnsToFit();
      },
      
    },
    
    components: {
      EmployeeReport
    },
  }
</script>
