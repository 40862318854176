<template>
    <span v-if="isZero === true" style="color:#777">{{value}} CHF</span>
    <span v-else>{{value}} CHF</span>
</template> 
<style lang="scss">
</style>
<script>

export default {
    data: () => ({
        value: null,
        isZero: false
    }),
    beforeMount() {
        const v = this.params.value == null ? 0 : this.params.value;
        this.isZero = v == 0;
        this.value = parseFloat(v).toFixed(2);
    },
 };
 </script>