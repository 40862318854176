<template>
  <time-recording-list ref="timeRecordingList" 
    @ModeChanged="onModeChanged($event)"
    >
  </time-recording-list>
</template>
<style lang="scss">
</style>
<script>
import TimeRecordingList from '@/components/forms/TimeRecordingList/_Index.vue'
// import moment from 'moment';

export default {
    name: 'timerecording',

    mounted() {
      this.$refs.timeRecordingList.refresh();
    },

    methods: {

      onModeChanged(e) {
        this.$emit("ModeChanged", e);
      },

      recordTime_isDisabled() {
        return this.$refs.timeRecordingList.recordTime_isDisabled();
      },

      stopTime_isDisabled() {
        return this.$refs.timeRecordingList.stopTime_isDisabled();
      },

      async setGridTheme(darkMode) {
        this.$refs.timeRecordingList.setGridTheme(darkMode);
      },
      async setGridLanguage() {
        this.$refs.timeRecordingList.setGridLanguage();
      },


      recordTime() {
        this.$refs.timeRecordingList.recordTime();
      },

      stopTime() {
        this.$refs.timeRecordingList.stopTime();
      },

      refresh() {
        this.$refs.timeRecordingList.refresh();
      }
    },
    components: {
      TimeRecordingList
    }

}

</script>
